<template>
  <topo :titulo="'Fichas de Treino'"></topo>
  <div>
    <div class="container-fluid mt-3">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <stats-card>
            <div
              style="display: flex; justify-content: space-between; width: 100%"
            >
              <div>
                <h2>Treinos</h2>
              </div>
              <div>
                <button class="btn_novo" @click="adicionarTreino()">
                  Adicionar Treino
                </button>
              </div>
            </div>
            <div
              v-for="(treino, index) in state.treinos"
              :key="treino"
              style="border-bottom: 2px solid #e7e7e7; padding: 10px"
            >
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                "
              >
                <div>
                  <h3>TREINO {{ index + 1 }}</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-2">
                  <button class="btn btn_trash" @click="removerTreino(index)">
                    <i class="bi bi-trash"></i>
                  </button>
                  <!--  <button class="btn btn_alterar"><i class="bi bi-pencil-fill"></i></button>-->
                </div>
                <div
                  v-for="(musculo, i) in treino.musculos"
                  :key="musculo"
                  class="col-sm-2"
                >
                  <div style="display: flex; justify-content: flex-start">
                    <h4>{{ musculo.nome }}</h4>
                    <button
                      class="btn_transparente"
                      @click="alterarMusculo(index, i, musculo.id)"
                    >
                      <i class="bi bi-pencil-fill icone_azul"></i>
                    </button>
                  </div>

                  <div v-for="exercicio in musculo.exercicios" :key="exercicio">
                    <div>{{ exercicio.nome }}</div>
                  </div>
                </div>
                <div>
                  <button
                    class="btn_novo btn_novo_musc"
                    @click="addMusculo(index)"
                    title="Adicionar Músculo"
                  >
                    <i class="bi bi-plus"></i>
                  </button>
                </div>
              </div>
            </div>
            <button
              class="btn btn-success"
              @click="salvarFicha"
              :disabled="state.isLoading"
              style="margin-top: 10px"
              v-if="state.treinos.length > 0"
            >
              Salvar Ficha
            </button>
          </stats-card>
        </div>
      </div>
    </div>
  </div>

  <Modal
    :open="state.modal_musculo"
    @close="state.modal_musculo = !state.modal_musculo"
    :width="'1000px'"
  >
    <div style="padding: 20px">
      <div class="row">
        <div class="col-sm-12">
          <label>Grupo Muscular</label>
          <select
            class="form-control"
            :disabled="state.disabled_exercicios"
            @change="
              selecionarGrupo(
                $event.target.value,
                $event.target.selectedOptions[0].text
              )
            "
            v-model="state.grupo_selecionado"
          >
            <option value=""></option>
            <option
              v-for="musculo in state.musculos"
              :key="musculo"
              :value="musculo.id"
            >
              {{ musculo.nome }}
            </option>
          </select>
        </div>
      </div>
      <table class="table">
        <thead>
          <th style="width: 20%">Nome</th>
          <th style="width: 12%">Ordem</th>
          <th style="width: 12%">Séries</th>
          <th style="width: 12%">Repetições</th>
          <th style="width: 12%">Peso (kg)</th>
          <th style="width: 12%">Selecionar</th>
          <th style="width: 12%">Opções</th>
        </thead>

        <tbody>
          <tr v-for="(exercicio, ex_i) in state.exercicios" :key="exercicio">
            <td style="margin-top: 5px">{{ exercicio.nome }}</td>

            <td>
              <input
                :readonly="!exercicio.selecionado"
                type="number"
                class="form-control"
                v-model="exercicio.ordem"
              />
            </td>
            <td>
              <input
                :readonly="!exercicio.selecionado"
                type="text"
                class="form-control"
                v-model="exercicio.series"
              />
            </td>
            <td>
              <input
                :readonly="!exercicio.selecionado"
                type="text"
                class="form-control"
                v-model="exercicio.repeticoes"
              />
              {{ exercicio.tipo }}
              {{ exercicio.exe_biset }}
              {{ exercicio.exe_triset }}
            </td>
            <td>
              <input
                :readonly="!exercicio.selecionado"
                type="text"
                class="form-control"
                v-model="exercicio.peso"
              />
            </td>
            <td style="padding-top: 23px">
              <input
                type="checkbox"
                v-model="exercicio.selecionado"
                @click="adicionarExercicio(ex_i)"
              />
            </td>
            <td>
              <div class="dropdown">
                <button
                  class="btn btn_quad btn_green"
                  type="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="bi bi-box"></i>
                </button>
                <div class="dropdown-menu">
                  <a
                    class="dropdown-item"
                    href="#"
                    @click="abreModalOpcoes(ex_i)"
                    >Visualizar</a
                  >
                  <a
                    class="dropdown-item"
                    :href="'/avaliacao/link/'"
                    target="_blank"
                    >Link Avaliação</a
                  >
                  <a class="dropdown-item" href="#">Fazer Comparação</a>
                  <a class="dropdown-item" href="#">Deletar Avaliação</a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col-sm-8">
          <button
            class="btn btn_add_grupo"
            v-if="state.alterar"
            @click="atualizarGrupoMuscular()"
          >
            Alterar Exercícios do Grupo
          </button>
          <button
            class="btn btn_add_grupo"
            v-else
            @click="adicionarGrupoMuscular()"
          >
            Adicionar Exercícios do Grupo
          </button>
          <button class="btn btn-danger" @click="cancelar()">Cancelar</button>
        </div>
      </div>
    </div>
  </Modal>

  <Modal
    :open="state.modal_opcoes"
    @close="state.modal_opcoes = !state.modal_opcoes"
    :width="'600px'"
  >
    <div>
      <div class="row">
        <div class="col-sm-6" style="padding: 20px">
          <label>Opções</label>

          <div>
            <input
              type="checkbox"
              id="dropset"
              @change="
                alterarTipoTreinoExercicio(
                  $event.target.value,
                  $event.target.checked
                )
              "
              :checked="state.tipo_treino_exercicio === 'dropset'"
              value="dropset"
            />
            <label for="dropset">Dropset</label>
          </div>

          <div>
            <input
              type="checkbox"
              id="topset"
              @change="
                alterarTipoTreinoExercicio(
                  $event.target.value,
                  $event.target.checked
                )
              "
              :checked="state.tipo_treino_exercicio === 'topset'"
              value="topset"
            />
            <label for="topset">Topset</label>
          </div>

          <div>
            <input
              type="checkbox"
              id="piramide_crescente"
              @change="
                alterarTipoTreinoExercicio(
                  $event.target.value,
                  $event.target.checked
                )
              "
              :checked="state.tipo_treino_exercicio === 'piramide_crescente'"
              value="piramide_crescente"
            />
            <label for="piramide_crescente">Pirâmide Crescente</label>
          </div>

          <div>
            <input
              type="checkbox"
              id="piramide_decrescente"
              value="piramide_decrescente"
              :checked="state.tipo_treino_exercicio === 'piramide_decrescente'"
              @change="
                alterarTipoTreinoExercicio(
                  $event.target.value,
                  $event.target.checked
                )
              "
            />
            <label for="piramide_decrescente">Pirâmide Decrescente</label>
          </div>

          <div>
            <input
              type="checkbox"
              id="parciais"
              value="parciais"
              :checked="state.tipo_treino_exercicio === 'parciais'"
              @change="
                alterarTipoTreinoExercicio(
                  $event.target.value,
                  $event.target.checked
                )
              "
            />
            <label for="parciais">Parciais</label>
          </div>

          <div>
            <input
              type="checkbox"
              id="biset"
              value="biset"
              :checked="state.tipo_treino_exercicio === 'biset'"
              @change="
                alterarTipoTreinoExercicio(
                  $event.target.value,
                  $event.target.checked
                )
              "
            />
            <label for="biset">Bi-set</label>
          </div>

          <div v-if="state.tipo_treino_exercicio === 'biset'">
            <label>Exercício</label>
            <select class="form-control" v-model="state.exercicio_biset">
              <option
                v-for="exerc in state.todos_exercicios"
                :key="exerc"
                :value="exerc.id"
              >
                {{ exerc.nome }}
              </option>
            </select>
          </div>

          <div>
            <input
              type="checkbox"
              id="triset"
              value="triset"
              v-model="state.tipo_treino_exercicio"
              :checked="state.tipo_treino_exercicio === 'triset'"
              @change="
                alterarTipoTreinoExercicio(
                  $event.target.value,
                  $event.target.checked
                )
              "
            />
            <label for="triset">Tri-set</label>
          </div>

          <div v-if="state.tipo_treino_exercicio === 'triset'">
            <label>Exercício</label>
            <select class="form-control" v-model="state.exercicio_biset">
              <option
                v-for="exerc in state.todos_exercicios"
                :key="exerc"
                :value="exerc.id"
              >
                {{ exerc.nome }}
              </option>
            </select>
          </div>

          <div v-if="state.tipo_treino_exercicio === 'triset'">
            <label>Exercício</label>
            <select class="form-control" v-model="state.exercicio_triset">
              <option
                v-for="exerc in state.todos_exercicios"
                :key="exerc"
                :value="exerc.id"
              >
                {{ exerc.nome }}
              </option>
            </select>
          </div>
        </div>
        <div
          class="col-sm-6"
          v-if="
            state.tipo_treino_exercicio === 'dropset' ||
            state.tipo_treino_exercicio === 'topset'
          "
        >
          <label>Diferença de peso (kg)</label>
          <input
            type="text"
            class="form-control"
            v-model="state.diferenca_peso"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-8">
          <button class="btn btn_add_grupo" @click="colocarOpcao()">
            Salvar Opção
          </button>
          <button class="btn btn-danger" @click="fechaModalOpcoes()">
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </Modal>

  <Modal
    :open="state.modal_vincular_exercicio"
    @close="state.modal_vincular_exercicio = !state.modal_vincular_exercicio"
    :width="'600px'"
  >
    <div style="padding: 20px">
      <div class="row">
        <div class="col-sm-12">
          <label>Opçoes</label>
        </div>
      </div>

      <div>
        <input type="checkbox" id="triset" name="triset" />
        <label for="triset">Tri-set</label>
      </div>

      <div class="row">
        <div class="col-sm-8">
          <button
            class="btn btn_add_grupo"
            v-if="state.alterar"
            @click="atualizarGrupoMuscular()"
          >
            Alterar Exercícios do Grupo
          </button>
          <button
            class="btn btn_add_grupo"
            v-else
            @click="adicionarGrupoMuscular()"
          >
            Adicionar Exercícios do Grupo
          </button>
          <button class="btn btn-danger" @click="cancelar()">Cancelar</button>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import { onMounted, reactive } from "vue";
import services from "../services";
import CorpoBuscaCliente from "../components/CorpoBuscaClientes";
import useModal from "../hooks/useModal";
import { useRouter } from "vue-router";
import Modal from "../components/Modal/Modal.vue";
import { useStorage } from "vue3-storage";
import { pt } from "date-fns/locale";
import Datepicker from "vue3-datepicker";
import topo from "../components/topo";
export default {
  components: { Modal },
  setup(_, { emit }) {
    // var isOpen = ref(false);
    const storage = useStorage();
    var data_atual = new Date();
    var dd = String(data_atual.getDate()).padStart(2, "0");
    var mm = String(data_atual.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = data_atual.getFullYear();

    data_atual = dd + "/" + mm + "/" + yyyy;

    var nome_usuario = storage.getStorageSync("nome_pessoa");
    var id_func_usuario = storage.getStorageSync("colaborador_id");
    var empresa_id = storage.getStorageSync("empresa_id");
    var token = storage.getStorageSync("token");

    const modal = useModal();
    const router = useRouter();

    function cancel() {
      router.push({ name: "fichasTreino" });
    }

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      ficha_id: "",
      isOpenModalMusculo: false,
      data_inicial: new Date(),
      clientes: [],
      avaliadores: [],
      tipo_treino_exercicio: "",
      id_cadastrante: {
        value: id_func_usuario,
      },
      nome_cadastrante: {
        value: nome_usuario,
      },
      id_cliente: "",
      nome_cliente: "",
      //data_inicial: "",
      data_final: "",
      hora_avaliacao: "",
      personal: "",
      data_cadastro: {
        value: data_atual,
      },
      categoria: {
        value: "",
      },
      treinos: [],
      treino_selecionado: "",
      readonly: [],
      disabled_exercicios: false,
      musculo_alterando: 0,
    });

    onMounted(() => {
      state.ficha_id = router.currentRoute._value.params.id;
      state.alterar = router.currentRoute._value.params.alterar;
      fetchCategoria();
      fetchMusculos();
      fetchTodosExercicios();

      if (state.alterar != null) {
        carregarFicha(state.ficha_id);
      }
    });

    async function carregarFicha(ficha_id) {
      try {
        state.isLoading = true;
        const { data } = await services.ficha.getBuscarFicha({
          empresa_id,
          token,
          ficha_id,
        });
        state.treinos = data.treinos;
      } catch (error) {
        console.log(error);
      }
    }

    async function abreModalOpcoes(i_exercicio) {
      state.i_exercicio_modal = i_exercicio;
      state.modal_opcoes = true;
    }

    function adicionarExercicio(n_exercicio) {
      if (state.readonly[n_exercicio] == true) {
        state.readonly[n_exercicio] = false;
        return;
      }
      state.readonly[n_exercicio] = true;
    }

    function handleBuscarCliente() {
      modal.open({
        component: "ModalBuscarAluno",
      });
    }

    function alterarTipoTreinoExercicio(valor, acao) {
      if (acao == true) {
        console.log("aqui");
        console.log(valor);
        state.tipo_treino_exercicio = valor;
      } else {
        state.tipo_treino_exercicio = null;
      }
    }

    function addMusculo(treino_id) {
      state.musculo_alterando = null;
      state.disabled_exercicios = false;
      state.exercicios = [];
      state.treino_selecionado = treino_id;
      state.modal_musculo = true;
    }

    function adicionarTreino() {
      var treino = {};
      treino.numero = state.treinos.length;
      //  state.treino_selecionado = treino.numero;
      state.treinos.push(treino);
    }

    function removerTreino(i) {
      state.treinos.splice(i, 1);
    }

    function atualizarGrupoMuscular() {
      let musculo = {};
      musculo.id = state.grupo_selecionado;
      musculo.nome = state.nome_grupo;
      musculo.exercicios = [];

      for (let i = 0; i < state.exercicios.length; i++) {
        if (state.exercicios[i].selecionado) {
          musculo.exercicios.push(state.exercicios[i]);
        }
      }

      //  console.log(state.treinos[state.treino_selecionado].musculos);
      if (
        state.treinos[state.treino_selecionado] &&
        state.treinos[state.treino_selecionado].musculos &&
        state.treinos[state.treino_selecionado].musculos[
          state.musculo_alterando
        ]
      ) {
        state.treinos[state.treino_selecionado].musculos[
          state.musculo_alterando
        ].exercicios = musculo.exercicios;
        state.modal_musculo = false;
        return;
      }

      if (state.treinos[state.treino_selecionado].musculos == undefined) {
        state.treinos[state.treino_selecionado] = {};
        state.treinos[state.treino_selecionado].musculos = [];
      }

      state.treinos[state.treino_selecionado].musculos.push(musculo);

      state.grupo_selecionado = "";
      state.modal_musculo = false;
    }

    function adicionarGrupoMuscular() {
      //  var exercicios = [];
      let musculo = {};
      musculo.id = state.grupo_selecionado;
      musculo.nome = state.nome_grupo;
      musculo.exercicios = [];

      for (let i = 0; i < state.exercicios.length; i++) {
        if (state.exercicios[i].selecionado) {
          console.log(state.exercicios[i]);
          state.exercicios[i].exercicio_id = state.exercicios[i].id;
          musculo.exercicios.push(state.exercicios[i]);
        }
      }

      //  console.log(state.treinos[state.treino_selecionado].musculos);
      if (
        state.treinos[state.treino_selecionado] &&
        state.treinos[state.treino_selecionado].musculos &&
        state.treinos[state.treino_selecionado].musculos[
          state.musculo_alterando
        ]
      ) {
        console.log(
          state.treinos[state.treino_selecionado].musculos[
            state.musculo_alterando
          ]
        );
        /*
        state.treinos[state.treino_selecionado].musculos[state.musculo_alterando].exercicios = musculo.exercicios;
        console.log("Veio aqui")
        state.modal_musculo = false;*/
        return;
      }

      if (state.treinos[state.treino_selecionado].musculos == undefined) {
        state.treinos[state.treino_selecionado] = {};
        state.treinos[state.treino_selecionado].musculos = [];
      }

      state.treinos[state.treino_selecionado].musculos.push(musculo);

      console.log(state.treinos[state.treino_selecionado].musculos);

      state.grupo_selecionado = "";
      state.modal_musculo = false;
    }

    function cancelar() {
      state.modal_musculo = false;
      state.treino_selecionado = "";
    }

    async function alterarMusculo(treino_i, musculo_i, musculo_id) {
      state.alterar = "SIM";
      state.modal_musculo = true;
      state.musculo_alterando = musculo_i;
      state.disabled_exercicios = true;
      state.treino_selecionado = treino_i;
      var obj_musculo = state.treinos[treino_i].musculos[musculo_i];
      state.grupo_selecionado = musculo_id;

      var ids_exercicios = [];
      for (let i = 0; i < obj_musculo.exercicios.length; i++) {
        obj_musculo.exercicios[i].selecionado = true;
        obj_musculo.exercicios[i].id =
          state.treinos[treino_i].musculos[musculo_i].exercicios[i].id; //obj_musculo.exercicios[i].exercicio_id;

        ids_exercicios.push(obj_musculo.exercicios[i].id);
      }

      const { data } = await services.ficha.getExerciciosPorMusculo({
        empresa_id: empresa_id,
        token: token,
        grupo_muscular_id: obj_musculo.id,
      });
      state.exercicios = data;

      for (let i = 0; i < state.exercicios.length; i++) {
        if (ids_exercicios.includes(state.exercicios[i].id)) {
          for (let i2 = 0; i2 < obj_musculo.exercicios.length; i2++) {
            if (obj_musculo.exercicios[i2].id == state.exercicios[i].id) {
              state.exercicios[i] = obj_musculo.exercicios[i2];
            }
            // const element = obj_musculo.exercicios[i2];
            console.log(obj_musculo.exercicios[i2].id);
          }
        }
      }

      //  juntarExercicios(state.exercicios);
    }

    /*
      if (treino_i != null) {
        console.log("aqui");
        console.log(state.exercicios);
        return;
      }
*/
    //    console.log(state.treinos);
    /*
   //   state.alterar = alterar;
      
      
      
      var obj_musculo = state.treinos[treino_i].musculos[musculo_i];
      console.log(obj_musculo);
      state.grupo_selecionado = musculo_id;

      
      console.log(ids_exercicios);



/*
  //    await selecionarGrupo(obj_musculo.id, obj_musculo.nome);

      const { data } = await services.ficha.getExerciciosPorMusculo({
        empresa_id: empresa_id,
        token: token,
        grupo_muscular_id: obj_musculo.id
      });


      state.exercicios = data;
      console.log(state.exercicios);

      for (let i = 0; i < state.exercicios.length; i++) {
        
        if (ids_exercicios.includes(state.exercicios[i].id)) {
          for (let i2 = 0; i2 < obj_musculo.exercicios.length; i2++) {
            if (obj_musculo.exercicios[i2].id == state.exercicios[i].id) {
              console.log("Oieeee")
              state.exercicios[i] = obj_musculo.exercicios[i2]
            }
           // const element = obj_musculo.exercicios[i2];
            console.log(obj_musculo.exercicios[i2].id);
          }

        }
      }*/

    /*
        async function alterarMusculo(treino_i, musculo_i, musculo_id) {
          state.musculo_alterando = musculo_i;
          state.disabled_exercicios = true;
          state.treino_selecionado = treino_i;
          var obj_musculo = state.treinos[treino_i].musculos[musculo_i];
          state.grupo_selecionado = musculo_id;
    
          var ids_exercicios = [];
          for (let i = 0; i < obj_musculo.exercicios.length; i++) {
            console.log(obj_musculo.exercicios[i]);
            obj_musculo.exercicios[i].selecionado = true;
            ids_exercicios.push(obj_musculo.exercicios[i].id);
          }
    
          await selecionarGrupo(obj_musculo.id, obj_musculo.nome);
    
          console.log(ids_exercicios)
    
          for (let i = 0; i < state.exercicios.length; i++) {
            console.log(state.exercicios[i])
            if (ids_exercicios.includes(state.exercicios[i].id)) {
              
              state.exercicios[i] = obj_musculo.exercicios[i];
            }
          }
          
          state.modal_musculo = true;
    
          juntarExercicios(state.exercicios);
        }
    
*/
    function deletarMusculo(index_treino, index_musculo) {
      state.treinos[index_treino].musculos.splice(index_musculo, 1);
    }

    function buscarCliente(id_cliente, nome_cliente) {
      state.id_cliente = id_cliente;
      state.nome_cliente = nome_cliente;
      state.isOpen = false;
    }
    function fecharModal() {
      state.isOpen = false;
      state.nome = "";
      state.clientes = [];
    }

    async function salvarFicha() {
      state.isLoading = false;
      try {
        const { errors } = await services.ficha.saveTreinosFicha({
          empresa_id: empresa_id,
          token: token,
          status: "ativo",
          treinos: state.treinos,
          ficha_id: state.ficha_id,
        });

        if (!errors) {
          state.isLoading = false;
          router.push({ name: "fichasTreino" });
          return;
        }
        //   router.push({ name: "fichasTreino" });
        state.isLoading = false;
      } catch {
        state.isLoading = false;
      }
    }
    async function colocarOpcao() {
      state.exercicios[state.i_exercicio_modal].tipo =
        state.tipo_treino_exercicio;

      state.exercicios[state.i_exercicio_modal].exe_biset =
        state.exercicio_biset;
      state.exercicios[state.i_exercicio_modal].exe_triset =
        state.exercicio_triset;

      state.exercicios[state.i_exercicio_modal].diferenca_peso =
        state.diferenca_peso;
      state.modal_opcoes = false;
    }
    async function fechaModalOpcoes() {
      state.i_exercicio_modal = null;
      state.modal_opcoes = false;
    }

    async function fetchClientes() {
      try {
        state.isLoading = true;
        const { data } = await services.clientes.clientesPorNome({
          empresa_id: empresa_id,
          token: token,
          nome: state.nome,
        });
        console.log(data);
        //  console.log(data)
        state.clientes = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function fetchMusculos() {
      try {
        state.isLoading = true;
        const { data } = await services.ficha.getGruposMuscular({
          empresa_id: empresa_id,
          token: token,
        });
        console.log(data);
        //  console.log(data)
        state.musculos = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function selecionarGrupo(grupo_id, nome_grupo) {
      try {
        state.isLoading = true;
        const { data } = await services.ficha.getExerciciosPorMusculo({
          empresa_id: empresa_id,
          token: token,
          grupo_muscular_id: grupo_id,
        });
        state.grupo_selecionado;
        state.nome_grupo = nome_grupo;
        state.exercicios = data;

        console.log(data);

        var readonly = [];
        for (let i = 0; i < data.length; i++) {
          readonly[i] = true;
        }
        state.readonly = readonly;

        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function fetchTodosExercicios() {
      try {
        state.isLoading = true;
        const { data } = await services.exercicio.listarExercicios({
          empresa_id: empresa_id,
          token: token,
        });
        state.todos_exercicios = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function fetchCategoria() {
      try {
        state.isLoading = true;
        const { data } = await services.colaboradores.getAvaliadores({
          empresa_id: empresa_id,
          token: token,
        });
        state.categorias = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }

    async function handleSubmit() {}
    return {
      topo,
      state,
      salvarFicha,
      handleBuscarCliente,
      handleSubmit,
      fetchClientes,
      CorpoBuscaCliente,
      emit,
      buscarCliente,
      cancel,
      fecharModal,
      Datepicker,
      pt,
      addMusculo,
      adicionarTreino,
      fetchMusculos,
      selecionarGrupo,
      adicionarGrupoMuscular,
      cancelar,
      alterarMusculo,
      deletarMusculo,
      adicionarExercicio,
      removerTreino,
      atualizarGrupoMuscular,
      alterarTipoTreinoExercicio,
      abreModalOpcoes,
      colocarOpcao,
      fechaModalOpcoes,
    };
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}
input {
  border-color: #b8b8b8;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
}
input:focus {
  border-color: #252525;
}
select {
  border-color: #b8b8b8;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
  margin-bottom: 10px;
}
select:focus {
  border-color: #252525;
}
.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}
.btn_salvar:hover {
  transform: none;
  color: #fff;
}
.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}
.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}
.div_s {
  height: 350px;
  overflow-y: scroll;
}
.btn_fechar {
  color: #fff;
  background-color: #788596;
}
.btn_fechar:hover {
  color: #fff;
  background-color: #788596;
  transform: none;
}
.btn_lupa {
  background-color: #aede00;
  padding: 10px;
  border-color: #aede00 solid 1px !important;
  color: rgb(0, 0, 0) !important;
  border-radius: 10px !important;
}
.btn_lupa:hover {
  transform: none;
}
.btn_transparente {
  border: none;
  background: none;
  color: #fff;
  margin-top: -5px;
  margin-left: 12pt;
}
.icone_azul {
  color: #034aa6;
  font-size: 12pt;
}

.btn_novo_musc {
  width: 40px;
  height: 40px;
  padding: 0px !important;
  border-radius: 50%;
  color: #fff;
}
.btn_novo_musc {
  font-size: 14pt;
}

.btn_novo {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}
.btn_add_grupo {
  color: #ffff;
  background: #034aa6 !important;
  border: #034aa6 solid 1px !important;
}
.btn_add_grupo:hover {
  color: #fff;
}

.btn_trash {
  background: #d93a3a;
  color: #fff;
  padding: 5px 7px;
}
.btn_trash:hover {
  color: #fff;
}

.btn_alterar {
  background: #034aa6;
  color: #fff;
  padding: 5px 7px;
}

.btn_alterar:hover {
  color: #fff;
}
i {
  font-size: 12pt;
}
</style>