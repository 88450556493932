<template>
  <topo :titulo="'Enquetes'"></topo>
  <div>
        <div class="container-fluid mt-3">
      <div class="card shadow p-3" :class="type === 'dark' ? 'bg-default' : ''" s>
        <div class="row align-items-center">
          <div class="col">
            <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
              Enquetes
            </h3>
          </div>
          <div class="col text-right mb-2">
            <base-button type="primary" id="btn_new_product" @click="abrirModal">Adicionar Novo Enquete</base-button>
          </div>
        </div>
        
          <div class="row">
            <div class="col">
              <div class="table-responsive">
                <table
                  class="table tablesorter table align-items-center table-flush"
                >
                  <thead class="thead-light">
                    <th>Código</th>
                    <th>Nome</th>
                    <th></th>
                  </thead>
                  <tbody class="list">
                    <corpo-grupo
                      v-for="grupo in state.grupos"
                      :key="grupo.id"
                      :grupo="grupo"
                      @deletar="deletar"
                      @modalVisualizar="modalVisualizar"
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
    <Modal
      :open="state.status_modal"
      @close="state.status_modal = !state.status_modal"
      :width="'800px'"
    >
      <h1>Enquete</h1>
      <div class="row">
        <div class="col-md-2">
          <div class="form-group">
            <label>Código</label>
            <input
              type="text"
              class="form-control input_modal"
              v-model="state.id"
              readonly
            />
          </div>
        </div>
        <div class="col-lg-8">
          <div class="form-group">
            <label>Nome</label>
            <input
              type="text"
              class="form-control input_modal"
              v-model="state.nome"
            />
          </div>
        </div>
        <div class="col-lg-8">
          <div class="form-group">
            <label>Status</label>
            <select class="form-control" v-model="state.status">
              <option value="ATIVO">Ativo</option>
              <option value="INATIVO">Inativo</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Pergunta</label>
            <input
              type="text"
              class="form-control input_modal"
              v-model="state.pergunta"
            />
          </div>
        </div>
      </div>
      
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <div style="display: flex; justify-content: space-between;">
              <h3>Respostas</h3>
              <button class="btn_add_resposta" @click="addResposta()">
                  <i class="bi bi-plus" style="margin-left: 0px;"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-for="(resposta, index) in state.respostas" :key="index">
        <div class="col-md-12">
          <label>Resposta {{ index + 1 }}</label>
          <input
            type="text"
            class="form-control input_modal"
            v-model="resposta.nome"
          />
        </div>
      </div>



      <button
        class="btn btn_salvar my-4"
        id="submit-button"
        type="submit"
        @click="salvarEnquete()"
      >
        Salvar
      </button>
      <button
        class="btn btn_cancelar my-4"
        id="submit-button"
        type="button"
        @click="fecharModal()"
      >
        Cancelar
      </button>
    </Modal>
</template>
<script>
  import { onMounted, reactive, onErrorCaptured  } from 'vue'
  import CorpoEnquete from '../components/CorpoTabelaEnquete'
  import services from '../services'
  import Modal from "../components/Modal/Modal.vue";
  import topo from "../components/topo";
  import { useStorage } from "vue3-storage";
export default {
  name: "tables",
  components: { topo },
    setup () {
    const storage = useStorage();
    var token =  storage.getStorageSync('token')
    var empresa_id = storage.getStorageSync('empresa_id')
    const state = reactive({
      isLoading: false,
      respostas: [
        {
          nome: ""
        },
        
      ],
      currentClientType: '',
      hasError: false,
      id:"",
      nome: "",
      pergunta: "",
      tipo_caixa: "interno",
      descricao:""
    })
    onErrorCaptured(handleErrors)

    onMounted(() => {
      fetchEnquetes()
    })
    function handleErrors (error) {
      console.log(error)
      state.hasError = !!error
    }
    async function abrirModal() {
      state.status_modal = true;
    }

    function fecharModal() {
      state.id = "";
      state.nome = "";
      state.descricao = "";
      state.tipo_caixa = "interno";
      state.status_modal = false;
    }
      
    async function modalVisualizar(id) {
      try {
        let data = await services.grupos.obterPorId({
          empresa_id,
          id,
          token,
        });
        state.status_modal = true;
        let dados = data.data;
        state.id = dados.id;
        state.nome = dados.nome;
        state.mostrar_home = dados.mostrar_home;
        state.descricao = dados.descricao;
      } catch (error) {
        console.log(error);
      }
    }

    function addResposta() {
      state.respostas.push({});
    }

      
    async function deletar(id) {
      if (confirm("Deseja deletar o Enquete?")) {
        await services.grupos.deletar({
          token,
          empresa_id,
          id
        }).then((data) => {
          if (data.data.verifica_erro) {
            alert("Erro ao deletar Enquete")
          } else {
            fetchEnquetes();
          }
        })
          .catch(() => {
            alert("Erro ao deletar Enquete")
          });
      }
    }

      
    async function salvarEnquete() {
      if (state.id != null && state.id != undefined && state.id != "") {
        let retorno = await services.enquete.update({
          empresa_id,
          token,
          id: state.id,
          nome: state.nome,
          pergunta: state.pergunta,
          status: state.status,
          respostas: state.respostas
        });

        if (retorno.data.verifica_erro == false) {
          fetchEnquetes();
          state.status_modal = false;
        } else {
          alert("Erro ao salvar plano");
        }
      } else {
        let retorno = await services.enquete.save({
          empresa_id,
          token,
          id: state.id,
          nome: state.nome,
          pergunta: state.pergunta,
          status: state.status,
          respostas: state.respostas
        });

        if (retorno.data.verifica_erro == false) {
          fetchEnquetes();
          state.status_modal = false;
        } else {
          alert("Erro ao salvar plano");
        }
      }
    }


    async function fetchEnquetes () {
      try {
        state.isLoading = true
        const { data } = await services.enquete.listar({
          token: token,
          empresa_id: empresa_id
        }
        )
      //  console.log(data)
        state.grupos = data
        state.isLoading = false
        
      } catch (error) {
        handleErrors(error)
      }
    }
    return {
      state,
      CorpoEnquete,
      abrirModal,
      fecharModal,
      salvarEnquete,
      modalVisualizar,
      Modal,
      deletar,
      addResposta
    }
  }
}

</script>

<style scoped>

.btn_add_resposta{
  background-color: #034aa6; 
  width: 30px; 
  height: 30px; 
  border-radius: 50%; 
  color: #fff; 
  padding: 0px; 
  font-size: 16pt; 
  border:none
}
.grupo{
  width: 100%;
  height: 100px;
  border: #034AA6 solid 1px;
  border-radius: 5px;
  margin-bottom: 10px;
  padding:15px;
}
.nome_grupo{
  font-weight:bold;
}
.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}
.btn_salvar:hover {
  transform: none;
  color: #fff;
}
.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}
.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}
textarea{
  resize: none;
}
</style>