<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <stats-card title="Total a pagar" type="gradient-red" sub-title="4.535,20" icon="ni ni-active-40"
            class="mb-4 mb-xl-0">
            <template v-slot:footer>
              <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
              <span class="text-nowrap">Já pago</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card title="Valor restante" type="gradient-orange" sub-title="2.356" icon="ni ni-chart-pie-35"
            class="mb-4 mb-xl-0">
            <template v-slot:footer>
              <span class="text-success mr-2"></span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card title="Valor em atraso" type="gradient-green" sub-title="924" icon="ni ni-money-coins"
            class="mb-4 mb-xl-0">
            <template v-slot:footer>
              <span class="text-danger mr-2"></span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card title="Quantidade contas atrasadas" type="gradient-info" sub-title="8" icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0">
            <template v-slot:footer>
              <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 54.8%</span>
              <span class="text-nowrap">Desde o último mês</span>
            </template>
          </stats-card>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="card shadow p-3" :class="type === 'dark' ? 'bg-default' : ''" s>
        <div class="row align-items-center">
          <div class="col">
            <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
              Bancos
            </h3>
          </div>
          <div class="col text-right mb-2">
            <base-button type="primary" id="btn_new_product" @click="abrirModal">Adicionar Banco</base-button>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="table-responsive">
              <table class="table tablesorter table align-items-center table-flush">
                <thead class="thead-light">
                  <th>Código</th>
                  <th>Nome</th>
                  <th></th>
                </thead>
                <tbody class="list">
                  <corpo-banco v-for="banco in state.bancos" :key="banco.id" :banco="banco" @deletar="deletar"
                    @modalVisualizar="modalVisualizar" />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal :open="state.status_modal" @close="state.status_modal = !state.status_modal">
    <h1>Banco</h1>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label>Código</label>
          <input type="text" class="form-control input_modal" v-model="state.id" readonly />
        </div>
      </div>
      <div class="col-lg-10">
        <div class="form-group">
          <label>Nome</label>
          <input type="text" class="form-control input_modal" v-model="state.nome" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <div class="form-group">
          <label>Código do Banco</label>
          <input type="text" class="form-control input_modal" v-model="state.codigo" />
        </div>
      </div>
    </div>
    <button class="btn btn_salvar my-4" id="submit-button" type="submit" @click="salvar()">
      Salvar
    </button>
    <button class="btn btn_cancelar my-4" id="submit-button" type="button" @click="fecharModal()">
      Cancelar
    </button>
  </Modal>
</template>
<script>
import { onMounted, reactive, onErrorCaptured } from 'vue'
import CorpoBanco from "../components/CorpoTabelaBanco"
import services from '../services'
import Modal from "../components/Modal/Modal.vue";

import { useStorage } from "vue3-storage";
export default {
  name: "tables",
  components: {
    CorpoBanco
  },
  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync('token')
    var empresa_id = storage.getStorageSync('empresa_id')
    const state = reactive({
      isLoading: false,
      bancos: [],
      currentClientType: '',
      hasError: false,
      nome: "",
      tipo: "debito",
      status:"ativo",
      via_gateway:"nao",
      escolher_bandeira:"nao",
      mostrar_home: "nao", 
    })
    onErrorCaptured(handleErrors)

    onMounted(() => {
      fetchBancos()
    })
    function handleErrors(error) {
      console.log(error)
      state.hasError = !!error
    }
    async function abrirModal() {
      state.status_modal = true;
    }

    function fecharModal() {
      state.status_modal = false;
      state.id = "";
      state.nome = "";
      state.codigo = "";

    }

    async function modalVisualizar(id) {
      try {
        state.isLoading = true;
        let data = await services.bancos.obterPorId({
          empresa_id,
          id,
          token,
        });
        console.log(data);
        state.status_modal = true;
        let dados = data.data;
        state.id = dados.id;
        state.nome = dados.nome;
        state.codigo = dados.codigo;
      } catch (error) {
        console.log(error);
      }
    }

    async function salvar() {
      if (state.id != null && state.id != undefined && state.id != "") {
        let retorno = await services.bancos.update ({
          empresa_id,
          token,
          id: state.id,
          nome: state.nome,
          codigo: state.codigo
        });

        if (retorno.data.verifica_erro == false) {
          fetchBancos();
          state.status_modal = false;
        } else {
          alert("Erro ao salvar plano");
        }
      } else {
        let retorno = await services.bancos.save({
          empresa_id,
          token,
          nome: state.nome,
          codigo: state.codigo
        });

        if (retorno.data.verifica_erro == false) {
          fetchBancos();
          state.status_modal = false;
        } else {
          alert("Erro ao salvar plano");
        }
      }
    }

    async function deletar(id) {
      if (confirm("Deseja deletar o Tipo de Pagamento?")){
        await services.tipoPagamento.deletar({
          token,
          empresa_id,
          id
        }).then((data) => {
          if (data.data.verifica_erro) {
            alert("Erro ao deletar tipo de pagamento")
          } else {
            fetchBancos()
          }
        })
        .catch(() => {
            alert("Erro ao deletar tipo de pagamento")
        });
      }
    }

    async function fetchBancos() {
      try {
        state.isLoading = true
        const { data } = await services.bancos.listar({
          token: token,
          empresa_id: empresa_id
        }
        )
        state.bancos = data
        state.isLoading = false

      } catch (error) {
        handleErrors(error)
      }
    }
    return {
      state,
      abrirModal,
      fecharModal,
      salvar,
      modalVisualizar,
      Modal,
      deletar
    }
  }
}

</script>

<style scoped>
.grupo {
  width: 100%;
  height: 100px;
  border: #034AA6 solid 1px;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
}

.nome_grupo {
  font-weight: bold;
}

.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}

.btn_salvar:hover {
  transform: none;
  color: #fff;
}

.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}

.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}
</style>