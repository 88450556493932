<template>
  <topo :titulo="'Tipos de Pagamento'"></topo>
  <div>
    <div class="container-fluid mt-5">
      <div class="card shadow p-3" :class="type === 'dark' ? 'bg-default' : ''" s>
        <div class="row align-items-center">
          <div class="col text-right mb-2">
            <base-button type="primary" id="btn_new_product" @click="abrirModal">Adicionar Tipo Pagamento</base-button>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="table-responsive">
              <table class="table tablesorter table align-items-center table-flush">
                <thead class="thead-light">
                  <th>Código</th>
                  <th>Nome</th>
                  <th></th>
                </thead>
                <tbody class="list">
                  <corpo-tipo-pagamento v-for="tipo_pagamento in state.tipos_pagamento" :key="tipo_pagamento.id" :tipo_pagamento="tipo_pagamento" @deletar="deletar"
                    @modalVisualizar="modalVisualizar" />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal :open="state.status_modal" :width="'800px'" @close="state.status_modal = !state.status_modal" >
    <h1>Tipo de Pagamento</h1>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label>Código</label>
          <input type="text" class="form-control input_modal" v-model="state.id" readonly />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group">
          <label>Nome</label>
          <input type="text" class="form-control input_modal" v-model="state.nome" />
        </div>
      </div>
      <div class="col-lg-3">
        <div class="form-group">
          <label>Status</label>
          <select class="form-control" v-model="state.status">
            <option value="ativo">Ativo</option>
            <option value="inativo">Inativo</option>
          </select>
        </div>
      </div>
      <div class="col-lg-3">
          <div class="form-group">
            <label>Escolher Bandeira</label>
            <select class="form-control" v-model="state.escolher_bandeira">
              <option value="sim">Sim</option>
              <option value="nao">Não</option>
            </select>
          </div>
        </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <div class="form-group">
          <label>Tipo</label>
          <select class="form-control" v-model="state.tipo">
            <option value="debito">Débito</option>
            <option value="credito">Crédito</option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>Pagamento Pelo Sistema</label>
          <select class="form-control" v-model="state.via_gateway">
            <option value="sim">Sim</option>
            <option value="nao">Não</option>
          </select>
        </div>
      </div>
    </div>
    <button class="btn btn_salvar my-4" id="submit-button" type="submit" @click="salvar()">
      Salvar
    </button>
    <button class="btn btn_cancelar my-4" id="submit-button" type="button" @click="fecharModal()">
      Cancelar
    </button>
  </Modal>
</template>
<script>
import { onMounted, reactive, onErrorCaptured } from 'vue'
import CorpoTipoPagamento from "../components/CorpoTabelaTipoPagamento"
import services from '../services'
import Modal from "../components/Modal/Modal.vue";
import topo from "../components/topo";
import { useStorage } from "vue3-storage";
export default {
  name: "tables",
  components: {
    CorpoTipoPagamento, topo
  },
  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync('token')
    var empresa_id = storage.getStorageSync('empresa_id')
    const state = reactive({
      isLoading: false,
      tipos_pagamento: [],
      currentClientType: '',
      hasError: false,
      nome: "",
      tipo: "debito",
      status:"ativo",
      via_gateway:"nao",
      escolher_bandeira:"nao",
      mostrar_home: "nao", 
    })
    onErrorCaptured(handleErrors)

    onMounted(() => {
      fetchTiposPagamento()
    })
    function handleErrors(error) {
      console.log(error)
      state.hasError = !!error
    }
    async function abrirModal() {
      state.status_modal = true;
    }

    function fecharModal() {
      state.status_modal = false;
      state.id = "";
      state.nome = "";
      state.tipo = "";
      state.via_gateway = "nao";
      state.escolher_bandeira = "nao";

    }

    async function modalVisualizar(id) {
      try {
        state.isLoading = true;
        let data = await services.tipoPagamento.obterPorId({
          empresa_id,
          id,
          token,
        });
        console.log(data);
        state.status_modal = true;
        let dados = data.data;
        state.id = dados.id;
        state.nome = dados.nome;
        state.mostrar_home = dados.mostrar_home;
        state.tipo = dados.tipo;
      } catch (error) {
        console.log(error);
      }
    }

    async function salvar() {
      if (state.id != null && state.id != undefined && state.id != "") {
        let retorno = await services.tipoPagamento.update ({
          empresa_id,
          token,
          id: state.id,
          nome: state.nome,
          tipo: state.tipo,
          via_gateway: state.via_gateway,
          escolher_bandeira: state.escolher_bandeira
        });

        if (retorno.data.verifica_erro == false) {
          fetchTiposPagamento();
          state.status_modal = false;
        } else {
          alert("Erro ao salvar plano");
        }
      } else {
        let retorno = await services.tipoPagamento.save({
          empresa_id,
          token,
          nome: state.nome,
          tipo: state.tipo,
          via_gateway: state.via_gateway,
          escolher_bandeira: state.escolher_bandeira
        });

        if (retorno.data.verifica_erro == false) {
          fetchTiposPagamento();
          state.status_modal = false;
        } else {
          alert("Erro ao salvar plano");
        }
      }
    }

    async function deletar(id) {
      if (confirm("Deseja deletar o Tipo de Pagamento?")){
        await services.tipoPagamento.deletar({
          token,
          empresa_id,
          id
        }).then((data) => {
          if (data.data.verifica_erro) {
            alert("Erro ao deletar tipo de pagamento")
          } else {
            fetchTiposPagamento()
          }
        })
        .catch(() => {
            alert("Erro ao deletar tipo de pagamento")
        });
      }
    }

    async function fetchTiposPagamento() {
      try {
        state.isLoading = true
        const { data } = await services.tipoPagamento.listar({
          token: token,
          empresa_id: empresa_id
        }
        )
        state.tipos_pagamento = data
        state.isLoading = false

      } catch (error) {
        handleErrors(error)
      }
    }
    return {
      state,
      abrirModal,
      fecharModal,
      salvar,
      modalVisualizar,
      Modal,
      deletar
    }
  }
}

</script>

<style scoped>
.grupo {
  width: 100%;
  height: 100px;
  border: #034AA6 solid 1px;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
}

.nome_grupo {
  font-weight: bold;
}

.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}

.btn_salvar:hover {
  transform: none;
  color: #fff;
}

.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}

.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}
</style>