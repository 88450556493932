<template>
  <topo :titulo="'Contas a Pagar'"></topo>
  <div>
    <div class="container-fluid mt-3">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <stats-card>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/dashboard">Home</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/contas-pagar" class="primary"
                    >Contas a Pagar</router-link
                  >
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Adicionar
                </li>
              </ol>
            </nav>
            <form @submit.prevent="handleSubmit">
              <div class="row">
                <div class="col-sm-8 col-md-12 col-lg-6">
                  <label>Funcionario Cadastrante</label>
                  <div class="row">
                    <div class="col-3 col-sm-4 col-md-3">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          v-model="state.id_cadastrante.value"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-9 col-sm-8 col-md-9">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          v-model="state.nome_cadastrante.value"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 col-md-6 col-lg-3">
                  <div class="form-group">
                    <label>Data Lançamento</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.data_cadastro.value"
                    />
                  </div>
                </div>
                <div class="col-sm-5 col-md-6 col-lg-3">
                  <div class="form-group">
                    <label>Tipo</label>
                    <select class="form-control" v-model="state.tipo_conta">
                      <option value="DESPESA_FORNECEDOR">
                        Contas e Despesas
                      </option>
                      <option value="PAG_COLABORADOR">
                        Pagamentos e Colaborador
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-3 col-md-6 col-lg-3">
                  <div class="form-group">
                    <label>Tipo Despesa</label>
                    <select class="form-control" v-model="state.tipo_despesa">
                      <option value="variavel">Variável</option>
                      <option value="fixa">Fixa</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4 col-md-6 col-lg-3">
                  <div class="form-group">
                    <label>Status</label>
                    <select class="form-control" v-model="state.status">
                      <option value="pendente">Pendente</option>
                      <option value="cancelado">Cancelado</option>
                      <option value="pago">Pago</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-12">
                  <label>Título</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.nome"
                  />
                </div>
                <div
                  class="col-sm-11 col-md-3"
                  v-if="state.tipo_despesa == 'fixa'"
                >
                  <label>Despesas pré Cadastradas</label>
                  <select class="form-control" v-model="state.despesa_fixa_id">
                    <option
                      v-for="despesa in state.despesas_fixas"
                      :key="despesa.id"
                      :value="despesa.id"
                    >
                      {{ despesa.nome }}
                    </option>
                  </select>
                </div>
                <div
                  class="col-sm-1"
                  style="padding-left: 0"
                  v-if="state.tipo_despesa == 'fixa'"
                >
                  <button
                    class="btn btn_mais"
                    type="button"
                    style="margin-top: 32px"
                    @click="modalAddDespesa()"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-plus"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                      />
                    </svg>
                  </button>
                </div>
                <div class="col-md-2" v-if="state.tipo_despesa == 'fixa'">
                  <label>Recorrencia</label>
                  <select
                    class="form-control"
                    v-model="state.tempo_recorrencia"
                  >
                    <option value="">1 Dia</option>
                    <option value="">5 Dias</option>
                    <option value="">10 Dias</option>
                    <option value="">15 Dias</option>
                    <option value="">25 Dias</option>
                    <option value="">30 Dias</option>
                    <option value="">60 Dias</option>
                    <option value="">90 Dias</option>
                  </select>
                </div>
              </div>
              <div
                class="fornecedor"
                v-if="state.tipo_conta == 'DESPESA_FORNECEDOR'"
              >
                <div class="row">
                  <div class="col">
                    <h3>Dados do Fornecedor</h3>
                  </div>
                </div>

                <div class="row">
                  <div class="col-3 col-sm-3 col-lg-2">
                    <div class="form-group">
                      <label>Código</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="state.fornecedor_id"
                      />
                    </div>
                  </div>
                  <div class="col-7 col-sm-8 col-lg-6">
                    <div class="form-group">
                      <label>Nome</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="state.fornecedor_nome"
                      />
                    </div>
                  </div>
                  <div class="col-2 col-sm-1" style="padding-left: 0">
                    <button
                      class="btn btn_lupa"
                      type="button"
                      style="margin-top: 32px"
                      @click="modalBuscarFornecedor()"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-search"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="colaborador" v-else>
                <div class="row mt-2">
                  <div class="col">
                    <h3>Dados do Colaborador</h3>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-3 col-lg-3">
                    <div class="form-group">
                      <label>Código</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="state.colaborador_recebedor_id"
                      />
                    </div>
                  </div>
                  <div class="col-sm-8 col-lg-6">
                    <div class="form-group">
                      <label>Nome</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="state.colaborador_nome"
                      />
                    </div>
                  </div>
                  <div class="col-sm-1" style="padding-left: 0">
                    <button
                      class="btn btn_lupa"
                      type="button"
                      style="margin-top: 32px"
                      @click="modalBuscarColaborador()"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-search"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <h3>Documento</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-lg-2">
                  <div class="form-group">
                    <label>Tipo</label>
                    <select
                      class="form-control"
                      v-model="state.tipo_documento_id"
                    >
                      <option
                        v-for="tipo in state.tipos"
                        :key="tipo.id"
                        :value="tipo.id"
                      >
                        {{ tipo.nome }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group">
                    <label>Doc Fornecedor</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.n_documento"
                    />
                  </div>
                </div>
                <div class="col-sm-4 col-lg-2 ">
                  <div class="form-group">
                    <label>Data Emissão</label>
                    <Datepicker
                      v-model="state.data_emissao"
                      :locale="pt"
                      inputFormat="dd/MM/yyyy"
                      style="
                        border-color: #b8b8b8;
                        color: #505050;
                        border-radius: 5px;
                        border: #b8b8b8 solid 1px;
                        transition: 0.5s;
                        width: 100%;
                        padding: 7px;
                      "
                    />
                    <!-- <input
                      type="text"
                      class="form-control"
                      v-model="state.data_emissao"
                      v-mask-date.br
                    />-->
                  </div>
                </div>
                <div class="col-sm-5 col-lg-3">
                  <div class="form-group">
                    <label>Categoria</label>
                    <select class="form-control" v-model="state.id_categoria">
                      <option
                        v-for="categoria in state.categorias"
                        :key="categoria.id"
                        :value="categoria.id"
                      >
                        {{ categoria.nome }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-3 col-lg-2" style="padding-left: 0">
                  <button
                    class="btn btn_add_cat"
                    type="button"
                    style="margin-top: 32px"
                    @click="modalAddCategoria()"
                  >
                    ADD CAT
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-lg-2 ">
                  <div class="form-group">
                    <label>Valor a Pagar</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.valor_pagar"
                      v-mask-decimal.br="2"
                      @blur="valoresRecebidos"
                    />
                  </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group">
                    <label>Data Vencimento</label>
                    <Datepicker
                      v-model="state.data_vencimento"
                      :locale="pt"
                      inputFormat="dd/MM/yyyy"
                      style="
                        border-color: #b8b8b8;
                        color: #505050;
                        border-radius: 5px;
                        border: #b8b8b8 solid 1px;
                        transition: 0.5s;
                        width: 100%;
                        padding: 7px;
                      "
                    />
                    <!-- <input
                      type="text"
                      class="form-control"
                      v-model="state.data_vencimento"
                      v-mask-date.br
                    />-->
                  </div>
                </div>
                <div class="col-sm-6 col-lg-3" v-if="state.status == 'pago'">
                  <div class="form-group">
                    <label>Valor Pago</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.valor_pago"
                      v-mask-decimal.br="2"
                    />
                  </div>
                </div>
                <div class="col-sm-6 col-lg-4" v-if="state.status == 'pago'">
                  <div class="form-group">
                    <label>Data de Pagamento</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.data_pagamento"
                      v-mask-date.br
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <!--
                <div class="col-md-12 col-lg-8 d-none d-none d-md-block">
                  <div class="form-group">
                    <label>Observação</label>
                    <textarea
                      class="area_obs"
                      v-model="state.descricao"
                    ></textarea>
                  </div>
                </div>
                -->

                <div class="col-md-12" v-if="state.status == 'pago'">
                  <div class="row">
                    <div class="col-sm-8">
                      <label>Funcionario Pagador</label>
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              v-model="state.id_cadastrante.value"
                              readonly
                            />
                          </div>
                        </div>
                        <div class="col-sm-8">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              v-model="state.nome_cadastrante.value"
                              readonly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 col-lg-4">
                      <label> Tipo de Pagamento </label>
                      <div class="form-group">
                        <select
                          class="form-control"
                          v-model="state.tipo_pagameto_id"
                        >
                          <option
                            v-for="tipo_pagar in state.tipos_pagamentos"
                            :key="tipo_pagar.id"
                            :value="tipo_pagar.id"
                          >
                            {{ tipo_pagar.nome }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <label>Local Pagamento</label>
                      <div class="form-group">
                        <select
                          class="form-control"
                          v-model="state.local_conta_id"
                        >
                          <option
                            v-for="local in state.locais_contas"
                            :key="local.id"
                            :value="local.id"
                          >
                            {{ local.nome }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-8">
                  <div class="form-group">
                    <label>Observação</label>
                    <textarea
                      class="area_obs"
                      v-model="state.descricao"
                    ></textarea>
                  </div>
                </div>
              </div>

              <button
                class="btn btn_salvar my-4"
                id="submit-button"
                :disabled="state.isLoading"
                type="button"
                @click="handleSubmit()"
              >
                Salvar
              </button>
              <button
                class="btn btn_cancelar my-4"
                id="submit-button"
                type="button"
                @click="cancel()"
              >
                Cancelar
              </button>
            </form>
          </stats-card>
        </div>
      </div>
    </div>
  </div>
  <Modal
    :open="state.modal_pesquisar_fornecedor"
    :width="'800px'"
    @close="
      state.modal_pesquisar_fornecedor = !state.modal_pesquisar_fornecedor
    "
  >
    <div style="padding: 20px">
      <div class="row">
        <div class="col-sm-3">
          <label for="tipo_pesquisa">Tipo de Pesquisa</label>
          <select id="tipo_pesquisa" class="form-control">
            <option>Nome</option>
            <option>Código</option>
          </select>
        </div>
        <div class="col-sm-8">
          <label>Pesquisa</label>
          <input
            type="text"
            class="form-control"
            v-model="state.nome_busca_fornecedor"
          />
        </div>
        <div class="col-sm-1" style="padding-left: 0">
          <button
            class="btn btn_lupa"
            type="button"
            style="margin-top: 32px"
            @click="fetchFornecedores"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path
                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
              />
            </svg>
          </button>
        </div>
      </div>

      <div class="row div_s" style="margin-top: 15px">
        <div class="col">
          <div class="table-responsive">
            <table
              class="table tablesorter table align-items-center table-flush"
            >
              <thead class="thead-light">
                <th>Código</th>
                <th>Nome</th>
                <th></th>
              </thead>
              <tbody class="list">
                <corpo-busca-fornecedores
                  @carregarFornecedor="carregarFornecedor"
                  v-for="fornecedor in state.fornecedores"
                  :key="fornecedor.id"
                  :fornecedor="fornecedor"
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div>
        <button
          type="button"
          class="btn btn_fechar"
          @click="fecharModal('pesquisa_fornecedor')"
        >
          Fechar
        </button>
      </div>
    </div>
  </Modal>
  <Modal
    :width="'800px'"
    :open="state.modal_pesquisar_colaborador"
    @close="
      state.modal_pesquisar_colaborador = !state.modal_pesquisar_colaborador
    "
  >
    <div style="padding: 20px">
      <div class="row">
        <div class="col-sm-3">
          <label for="tipo_pesquisa">Tipo de Pesquisa</label>
          <select id="tipo_pesquisa" class="form-control">
            <option>Nome</option>
            <option>Código</option>
          </select>
        </div>
        <div class="col-sm-8">
          <label>Pesquisa</label>
          <input
            type="text"
            class="form-control"
            v-model="state.nome_busca_colaborador"
          />
        </div>
        <div class="col-sm-1" style="padding-left: 0">
          <button
            class="btn btn_lupa"
            type="button"
            style="margin-top: 32px"
            @click="fetchColaboradores"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path
                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
              />
            </svg>
          </button>
        </div>
      </div>

      <div class="row div_s" style="margin-top: 15px">
        <div class="col">
          <div class="table-responsive">
            <table
              class="table tablesorter table align-items-center table-flush"
            >
              <thead class="thead-light">
                <th>Código</th>
                <th>Nome</th>
                <th></th>
              </thead>
              <tbody class="list">
                <CorpoBuscaColaboradores
                  @carregarColaborador="carregarColaborador"
                  v-for="colaborador in state.colaboradores"
                  :key="colaborador.id"
                  :colaborador="colaborador"
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div>
        <button
          type="button"
          class="btn btn_fechar"
          @click="fecharModal('pesquisa_colaborador')"
        >
          Fechar
        </button>
      </div>
    </div>
  </Modal>
  <Modal
  :width="'800px'"
    :open="state.modal_add_despesa"
    @close="state.modal_add_despesa = !state.modal_add_despesa"
  >
    <div class="row">
      <div class="col-sm-12">
        <label>Adicionar Despesa</label>
        <input class="form-control" v-model="state.add_despesa" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label>Recorrencia</label>
        <input class="form-control" v-model="state.recorrencia_add_despesa" />
      </div>
      <div class="col-md-6">
        <label>Valor</label>
        <input
          type="text"
          class="form-control"
          v-model="state.valor_add_despesa"
          v-mask-decimal.br="2"
        />
      </div>
    </div>
    <div class="espaco_despesa">
      <button
        class="btn btn_salva_despesa"
        type="button"
        @click="addDespesaCadastrada()"
      >
        Adicionar Despesa
      </button>
      <button
        type="button"
        class="btn btn_fechar"
        @click="fecharModal('modal_add_despesa')"
      >
        Cancelar
      </button>
    </div>
  </Modal>
  <Modal
  :width="'800px'"
    :open="state.modal_add_categoria"
    @close="state.modal_add_categoria = !state.modal_add_categoria"
  >
    <div class="col-sm-12">
      <label>Adicionar Categoria</label>
      <input class="form-control" v-model="state.add_categoria" />
    </div>
    <div class="espaco_despesa">
      <button
        class="btn btn_salva_despesa"
        type="button"
        @click="addCategoriaPagar()"
      >
        Adicionar Categoria
      </button>
      <button
        type="button"
        class="btn btn_fechar"
        @click="fecharModal('modal_add_categoria')"
      >
        Cancelar
      </button>
    </div>
  </Modal>
</template>
<script>
import { onMounted, reactive } from "vue";
import services from "../services";
import Modal from "../components/Modal/Modal.vue";
import CorpoBuscaColaboradores from "../components/CorpoBuscaColaboradores";
import CorpoBuscaFornecedores from "../components/CorpoBuscaFornecedores";
import { useRouter } from "vue-router";
import { useStorage } from "vue3-storage";
import Datepicker from "vue3-datepicker";
import { pt } from "date-fns/locale";
import topo from "../components/topo";

export default {
  components: { topo },
  setup(_, { emit }) {
    const storage = useStorage();
    var data_atual = new Date();
    var dd = String(data_atual.getDate()).padStart(2, "0");
    var mm = String(data_atual.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = data_atual.getFullYear();

    data_atual = dd + "/" + mm + "/" + yyyy;

    var nome_usuario = storage.getStorageSync("nome_pessoa");
    var id_func_usuario = storage.getStorageSync("colaborador_id");
    var empresa_id = storage.getStorageSync("empresa_id");
    var token = storage.getStorageSync("token");

    const router = useRouter();

    function modalBuscarFornecedor() {
      state.modal_pesquisar_fornecedor = true;
    }
    function modalAddDespesa() {
      state.modal_add_despesa = true;
    }
    function modalAddCategoria() {
      state.modal_add_categoria = true;
    }
    function modalBuscarColaborador() {
      state.modal_pesquisar_colaborador = true;
    }
    function fecharModal(modal) {
      if (modal == "pesquisa_fornecedor") {
        state.modal_pesquisar_fornecedor = false;
      } else if (modal == "pesquisa_colaborador") {
        state.modal_pesquisar_colaborador = false;
      } else if (modal == "modal_add_despesa") {
        state.modal_add_despesa = false;
      } else if (modal == "modal_add_categoria") {
        state.modal_add_categoria = false;
      }
    }
    function cancel() {
      router.push({ name: "contasPagar" });
    }

    function carregarFornecedor(fornecedor_id, fornecedor_nome) {
      state.fornecedor_id = fornecedor_id;
      state.fornecedor_nome = fornecedor_nome;
      state.modal_pesquisar_fornecedor = false;
    }

    function carregarColaborador(colaborador_id, colaborador_nome) {
      console.log("aqui");
      state.colaborador_id = colaborador_id;
      state.colaborador_nome = colaborador_nome;
      state.modal_pesquisar_colaborador = false;
    }
    async function addCategoriaPagar() {
      try {
        state.isLoading = true;
        const { data } = await services.catContaPagar.insert({
          empresa_id: empresa_id,
          token: token,
          nome: state.add_categoria,
        });
        state.categoria = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function addDespesaCadastrada() {
      try {
        state.isLoading = true;
        const { data } = await services.despesasFixa.insertViaContaPagar({
          empresa_id: empresa_id,
          token: token,
          nome: state.add_despesa,
          recorrencia: state.recorrencia_add_despesa,
          valor: state.valor_add_despesa,
        });

        //      console.log(data)
        state.despesa_fixa_id = data.id;
        var despesa_fixa = {};
        despesa_fixa.id = data.id;
        despesa_fixa.nome = state.add_despesa;
        state.despesas_fixas.push(despesa_fixa);

        state.isLoading = false;
        state.modal_add_despesa = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    function valoresRecebidos() {
      state.valor_pago = state.valor_pagar;
    }
    const state = reactive({
      id: null,
      hasErrors: false,
      isLoading: false,
      fornecedores: [],
      despesas_fixas: [],
      locais_contas: [],
      id_cadastrante: {
        value: id_func_usuario,
      },
      nome_cadastrante: {
        value: nome_usuario,
      },
      data_cadastro: {
        value: data_atual,
      },
      id_categoria: null,
      funcionario_cadastrante_id: null,
      tipo_conta: "DESPESA_FORNECEDOR",
      tipo_despesa: "variavel",
      nome: "",
      n_documento: "",
      tipo_documento_id: null,
      //data_emissao: null,
      modal_pesquisar_fornecedor: false,
      nome_busca_fornecedor: "",
      nome_busca_colaborador: "",
      fornecedor_id: "",
      fornecedor_nome: "",
      colaborador_recebedor_id: "",
      colaborador_nome: "",
      status: "pendente",
      tipo_pagameto_id: "",
      funcionario_pagador_id: "",
      local_pagamento: "",
      descricao: "",
      valor_pagar: "",
      //data_vencimento: "",
      data_pagamento: "",
      valor_pago: "",
      despesa_fixa_id: "",
      tempo_recorrencia: "",
      add_categoria: "",
      add_despesa: "",
      recorrencia_add_despesa: "",
      valor_add_despesa: "",
      data_emissao: new Date(),
      data_vencimento: new Date(),
    });

    onMounted(() => {
      fetchCategoria();
      fetchTipos();
      fetchDespesasFixa();
      fetchLocalContas();
      fetchTipoPagamento();
    });

    async function fetchFornecedores() {
      try {
        state.isLoading = true;
        const { data } = await services.fornecedores.busca({
          empresa_id: empresa_id,
          token: token,
          nome: state.nome_busca_fornecedor,
        });
        state.fornecedores = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function fetchDespesasFixa() {
      try {
        state.isLoading = true;
        const { data } = await services.despesasFixa.getAll({
          empresa_id: empresa_id,
          token: token,
        });
        console.log(data);
        //  console.log(data)
        state.despesas_fixas = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function fetchLocalContas() {
      try {
        state.isLoading = true;
        const { data } = await services.localContas.getAll({
          empresa_id: empresa_id,
          token: token,
        });
        console.log(data);
        //  console.log(data)
        state.locais_contas = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function fetchTipoPagamento() {
      try {
        state.isLoading = true;
        const { data } = await services.tipoPagamento.listar({
          empresa_id: empresa_id,
          token: token,
        });
        console.log(data);
        //  console.log(data)
        state.tipos_pagamentos = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function fetchCategoria() {
      try {
        state.isLoading = true;
        const { data } = await services.catContaPagar.getAll({
          empresa_id: empresa_id,
          token: token,
        });
        console.log(data);
        //  console.log(data)
        state.categorias = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function fetchTipos() {
      try {
        state.isLoading = true;
        const { data } = await services.tiposContasPagar.getAll({
          empresa_id: empresa_id,
          token: token,
        });
        //  console.log(data)
        state.tipos = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function fetchColaboradores() {
      try {
        state.isLoading = true;
        const { data } = await services.colaboradores.busca({
          empresa_id: empresa_id,
          token: token,
          nome: state.nome,
        });
        console.log(data);
        //  console.log(data)
        state.colaboradores = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }
    async function handleSubmit() {
      try {
        state.isLoading = true;
        var dia_data_emissao = state.data_emissao.getDate();
        if (dia_data_emissao < 10) {
          dia_data_emissao = "0" + dia_data_emissao;
        }

        var mes_data_emissao = state.data_emissao.getMonth();
        mes_data_emissao = mes_data_emissao + 1;
        if (mes_data_emissao < 10) {
          mes_data_emissao = "0" + mes_data_emissao;
        }
        var data_emissao =
          dia_data_emissao.toString() +
          "/" +
          mes_data_emissao.toString() +
          "/" +
          state.data_emissao.getFullYear().toString();

        //-----Data de vencimento-----------------------------
        var dia_data_vencimento = state.data_vencimento.getDate();
        if (dia_data_vencimento < 10) {
          dia_data_vencimento = "0" + dia_data_vencimento;
        }

        var mes_data_vencimento = state.data_vencimento.getMonth();
        mes_data_vencimento = mes_data_vencimento + 1;
        if (mes_data_vencimento < 10) {
          mes_data_vencimento = "0" + mes_data_vencimento;
        }

        var data_vencimento =
          dia_data_vencimento.toString() +
          "/" +
          mes_data_vencimento.toString() +
          "/" +
          state.data_vencimento.getFullYear().toString();

        const { errors } = await services.contaPagar.save({
          empresa_id: empresa_id,
          token: token,
          funcionario_cadastrante_id: state.id_cadastrante.value,
          data_cadastro: state.data_cadastro.value,
          tipo_conta: state.tipo_conta,
          tipo_despesa: state.tipo_despesa,
          id: state.id,
          nome: state.nome,
          tipo_documento_id: state.tipo_documento_id,
          n_documento: state.n_documento,
          data_emissao: data_emissao,
          id_categoria: state.id_categoria,
          status: state.status,
          fornecedor_id: state.fornecedor_id,
          fornecedor_nome: state.fornecedor_nome,
          colaborador_recebedor_id: state.colaborador_recebedor_id,
          colaborador_nome: state.colaborador_nome,
          tipo_pagameto_id: state.tipo_pagameto_id,
          funcionario_pagador_id: state.funcionario_pagador_id,
          local_conta_id: state.local_conta_id,
          descricao: state.descricao,
          valor_pagar: state.valor_pagar,
          data_vencimento: data_vencimento,
          data_pagamento: state.data_pagamento,
          valor_pago: state.valor_pago,
          despesa_fixa_id: state.despesa_fixa_id,
          tempo_recorrencia: state.tempo_recorrencia,
        });

        if (!errors) {
          //       window.localStorage.setItem('token', data.token)
          //  router.push({ name: "contasPagar" });
          state.isLoading = false;
          return;
        }

        if (errors.status === 400) {
          //  toast.error('Ocorreu um erro ao criar a conta')
        }

        state.isLoading = false;
      } catch (error) {
        console.log(error);
        state.isLoading = false;
        state.hasErrors = !!error;
        //  toast.error('Ocorreu um erro ao criar a conta')
      }
    }
    return {
      state,
      // handleCategory,
      modalBuscarFornecedor,
      modalBuscarColaborador,
      modalAddDespesa,
      modalAddCategoria,
      addCategoriaPagar,
      addDespesaCadastrada,
      fecharModal,
      handleSubmit,
      emit,
      cancel,
      Modal,
      CorpoBuscaColaboradores,
      CorpoBuscaFornecedores,
      fetchColaboradores,
      fetchFornecedores,
      carregarFornecedor,
      carregarColaborador,
      Datepicker,
      pt,
      valoresRecebidos,
    };
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}
input {
  border-color: #b8b8b8;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
}
input:focus {
  border-color: #252525;
}
select {
  border-color: #b8b8b8;
  border-width: px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
}
select:focus {
  border-color: #252525;
}
.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}
.btn_salvar:hover {
  transform: none;
  color: #fff;
}
.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}
.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}
.btn_lupa {
  background-color: #aede00;
  padding: 10px;
  border-color: #aede00 solid 1px !important;
  color: rgb(0, 0, 0) !important;
  border-radius: 10px !important;
}
.btn_lupa:hover {
  transform: none;
}
.btn_fechar {
  color: #fff;
  background-color: #788596;
}
.btn_fechar:hover {
  color: #fff;
  background-color: #788596;
  transform: none;
}
.area_obs {
  width: 100%;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
  outline: none;
  height: 100px;
  border: 1px solid #b8b8b8;
  resize: none;
}
.area_obs:focus {
  border-color: #252525;
  outline: none;
}
.btn_mais {
  background-color: #aede00;
  padding: 10px;
  border-color: #aede00 solid 1px !important;
  color: rgb(0, 0, 0) !important;
  border-radius: 10px !important;
}
.btn_mais:hover {
  transform: none;
}
.btn_add_cat {
  background-color: #aede00;
  padding: 10px;
  border-color: #aede00 solid 1px !important;
  color: rgb(0, 0, 0) !important;
  border-radius: 10px !important;
  width:100%;
}
.btn_add_cat:hover {
  transform: none;
}
.btn_salva_despesa {
  color: #fff;
  background-color: #034aa6;
}
.btn_salva_despesa:hover {
  color: #fff;
  background-color: #034aa6;
  transform: none;
}
.espaco_despesa {
  margin-top: 20px;
  text-align: right;
}
</style>