<template>
  <div class="topo">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <h1>Clientes</h1>
        </div>
        <!--
        <div class="col-md-3 offset-3 div_pessoa">
          <div class="div_imagem"><img :src="foto" /></div>
          <div class="nome_usuario">Marcelo</div>
        </div>-->
      </div>
    </div>
  </div>

  <div class="container-fluid mt-md-3">
    <div class="row d-md-none">
      <div class="col-12">
        <router-link to="/adicionar-cliente" class="primary">
          <button type="button" class="btn_add_cliente">
            <i class="bi bi-plus i"></i> Novo Cliente
          </button>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3 mt-2 col-md-6 col-lg-3">
        <input
          type="text"
          class="form-control"
          placeholder="Código"
          v-model="state.codigo"
        />
      </div>
      <div class="col-sm-9 mt-2 col-md-6 col-lg-3">
        <input
          type="text"
          class="form-control"
          placeholder="Nome"
          v-model="state.nome_busca"
        />
      </div>
      <div class="col-sm-2 col-md-3 col-lg-2 d-none d-md-block">
        <button class="btn_buscar_nome" @click="buscarPorNome()">Buscar</button>
      </div>
      <div
        class="col-sm-3 col-md-6 offset-md-3 col-lg-3 offset-lg-1 d-none d-md-block"
      >
        <router-link
          to="/adicionar-cliente"
          class="primary"
          v-if="array_permissoes.includes('72')"
        >
          <button type="button" class="btn_add_cliente">
            <i class="bi bi-plus i"></i> Novo Cliente
          </button>
        </router-link>
        <button type="button" class="btn_add_cliente" v-else disabled>
          <i class="bi bi-plus i"></i> Novo Cliente
        </button>
      </div>

      <div class="col-6 col-md-6 col-lg-3 mt-3">
        <select class="form-control" placeholder="Filtrar por Status">
          <option value="ativo">Ativo</option>
          <option value="inativo">Inativo</option>
        </select>
      </div>
      <div class="col-6 col-md-6 col-lg-3 mt-3">
        <select class="form-control" placeholder="Filtrar por Situação">
          <option value="adimplente">Adimplente</option>
          <option value="inadimplente">Inadimplente</option>
        </select>
      </div>
      <div class="col-6 col-md-6 col-lg-3 mt-3">
        <select class="form-control" placeholder="Filtrar por Plano">
          <select-planos
            v-for="plano in state.planos"
            :key="plano.id"
            :plano="plano"
          />
        </select>
      </div>
      <div class="col-6 col-md-6 col-lg-3 mt-3">
        <select class="form-control" placeholder="Filtrar por Personal">
          <select-personais
            v-for="personal in state.personais"
            :key="personal.id"
            :personal="personal"
          />
        </select>
      </div>
      <div class="col-12 d-md-none">
        <button class="btn_buscar_nome" @click="buscarPorNome()">Buscar</button>
      </div>
    </div>
  </div>
  <div>
    <base-header
      type="gradient-success"
      style="padding-top: 2rem !important"
      class="pb-6 pb-8 pt-5 pt-md-8"
    >
      <!-- Card stats -->
      <div class="row" v-if="array_permissoes.includes('104')">
        <div class="col-xl-3 col-lg-6 mt-sm-2">
          <card class="card-stats">
            <i class="bi bi-people-fill i_quad"></i>
            <p class="avaliacao">Clientes Cadastrados</p>
            <div class="num">{{ state.qtdClientes }}</div>
            <div class="conteudo"></div>
          </card>
        </div>
        <div class="col-xl-3 col-lg-6 mt-2">
          <card class="card-stats">
            <i class="bi bi-person-check-fill i_quad"></i>
            <p class="avaliacao">Clientes Ativos</p>
            <div class="num">{{ state.qtdClientesAtivos }}</div>
            <div class="conteudo"></div>
          </card>
        </div>
        <div class="col-xl-3 col-lg-6 mt-2">
          <card class="card-stats">
            <i class="bi bi-clipboard2-x-fill i_quad"></i>
            <p class="avaliacao">Sem Matrículas</p>
            <div class="num">{{ state.qtdClientesSemMatricula }}</div>
            <div class="conteudo"></div>
          </card>
        </div>
        <div class="col-xl-3 col-lg-6 mt-2">
          <card class="card-stats">
            <i class="bi bi-person-fill-exclamation i_quad"></i>
            <p class="avaliacao">Ausentes</p>
            <div class="num">{{ state.qtdClientesAusentes }}</div>
            <div class="conteudo"></div>
          </card>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div
        class="card shadow p-3"
        :class="type === 'dark' ? 'bg-default' : ''"
        s
      >
        <div class="row">
          <div class="col">
            <div class="table-responsive">
              <cliente-loading v-if="state.isLoading" />
              <table
                v-else
                class="table tablesorter table align-items-center table-flush"
              >
                <thead class="thead-light">
                  <th>Código</th>
                  <th>Nome</th>
                  <th class="plano_none">Plano</th>
                  <th>Status</th>
                  <th class="plano_none">Biometria / Visitado</th>
                  <th></th>
                </thead>
                <tbody class="list">
                  <corpo-tabela-cliente
                    v-for="cliente in state.clientes"
                    :key="cliente.id"
                    :cliente="cliente"
                    @deletarCliente="deletarCliente"
                    :permissoes="array_permissoes"
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
          <nav aria-label="">
            <ul class="pagination">
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Previous</span>
                </a>
              </li>
              <div
                class="page-item"
                v-for="pagina in state.qtd_paginacao"
                :key="pagina"
                :class="{
                  active: pagina == state.pagina_selecionada,
                }"
              >
                <li
                  v-if="
                    pagina == state.pagina_selecionada ||
                    (pagina >= state.pagina_selecionada - 2 &&
                      pagina <= state.pagina_selecionada + 2) ||
                    pagina == state.qtd_paginacao
                  "
                >
                  <a class="page-link" @click="selecionarPagina(pagina)">{{
                    pagina
                  }}</a>
                </li>
              </div>

              <li class="page-item">
                <a class="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Next</span>
                </a>
              </li>
              <li>
                <select
                  class="form-control"
                  v-model="state.qtd_por_pagina"
                  @change="fetchClientes"
                >
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, onErrorCaptured } from "vue";
import CorpoTabelaCliente from "../components/CorpoTabelaCliente";
import ClienteLoading from "../components/CorpoTabelaCliente/Loading";

import services from "../services";
import SelectPlanos from "../components/PlanosSelect.vue";
import SelectPersonais from "../components/PersonaisSelect.vue";
import { useStorage } from "vue3-storage";

export default {
  name: "tables",
  components: {
    CorpoTabelaCliente,
    ClienteLoading,
  },

  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");
    var permissoes = storage.getStorageSync("permissoes");

    var array_permissoes = [];
    for (let i = 0; i < permissoes.length; i++) {
      const element = permissoes[i];
      array_permissoes.push(element.id);
    }
    async function deletarCliente(id) {
      if (!array_permissoes.includes("33")) {
        return;
      }
      if (confirm("Deseja deletar este cliente?")) {
        try {
          state.isLoading = true;
          const { data } = await services.clientes.deletarCliente({
            token: token,
            empresa_id: empresa_id,
            id,
          });
          if (data) {
            fetchClientes();
          } else {
            alert("Erro ao deletar cliente.");
          }
          state.isLoading = true;
        } catch (error) {
          handleErrors(error);
          state.isLoading = true;
        }
      }
    }
    const state = reactive({
      //card-stats
      cadadastoClient: false,
      ativoClient: false,
      semMatricula: false,
      ausente: false,
      //////////////////

      isLoading: false,
      codigo: "",
      nome_busca: "",
      clientes: [],
      planos: [],
      personais: [],
      currentClientType: "",
      hasError: false,
      qtd_por_pagina: 50,
      pagina_selecionada: 1,
      qtd_paginacao: 0,
      qtd_anterior: 0,
    });
    onErrorCaptured(handleErrors);

    onMounted(() => {
      telaClienteWeb();
      fetchClientes(), fetchPlanos(), fetchPersonais();
    });

    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }

    async function buscarPorNome() {
      if (state.codigo.length > 0) {
        try {
          const { data } = await services.clientes.obterPorId({
            token: token,
            empresa_id: empresa_id,
            id: state.codigo,
          });
          var array = [];
          array.push(data);
          state.clientes = array;
        } catch (error) {
          handleErrors(error);
        }
      } else {
        try {
          state.isLoading = true;
          const { data } = await services.clientes.clientesPorNome({
            type: state.currentClientType,
            token: token,
            empresa_id: empresa_id,
            nome: state.nome_busca,
          });
          console.log(data);
          //  console.log(data)
          state.clientes = data;
          state.isLoading = false;
        } catch (error) {
          handleErrors(error);
        }
      }
    }

    async function fetchClientes() {
      try {
        state.isLoading = true;
        const { data } = await services.clientes.getAll({
          type: state.currentClientType,
          token: token,
          empresa_id: empresa_id,
          quantidade: state.qtd_por_pagina,
          quantidade_anterior: state.qtd_anterior,
        });
        qtdClientes();

        console.log(data);
        //  console.log(data)
        state.clientes = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function qtdClientes() {
      try {
        const qtdClientes = await services.clientes.qtdClientes({
          token: token,
          empresa_id: empresa_id,
        });
        state.qtdClientes = qtdClientes.data;

        console.log(qtdClientes.data);
        state.qtd_paginacao = Math.ceil(
          parseInt(state.qtdClientes) / state.qtd_por_pagina
        );
      } catch (error) {
        handleErrors(error);
      }
    }

    async function telaClienteWeb() {
      try {
        const data = await services.clientes.telaClienteWeb({
          token: token,
          empresa_id: empresa_id,
        });
        console.log(data);
        state.qtdClientesAtivos = data.data.qtd_clientes_ativos;
        state.qtdClientesSemMatricula = data.data.qtd_clientes_sem_matricula;
        state.qtdClientesAusentes = data.data.qtd_clientes_ausentes;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function fetchPlanos() {
      try {
        const planos = await services.planos.getAtivos({
          token: token,
          empresa_id: empresa_id,
        });
        state.planos = planos.data;
        // state.qtdClientesSemMatricula = qtdClientesSemMatricula.data;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function fetchPersonais() {
      try {
        const personais = await services.personais.getAtivos({
          token: token,
          empresa_id: empresa_id,
        });
        state.personais = personais.data;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function selecionarPagina(n_pagina) {
      try {
        var qtd_anterior = (n_pagina - 1) * state.qtd_por_pagina;

        state.isLoading = true;
        var retorno = await services.clientes.getAll({
          type: state.currentClientType,
          token: token,

          empresa_id: empresa_id,
          quantidade: state.qtd_por_pagina,
          quantidade_anterior: qtd_anterior,
        });
        console.log(retorno);
        state.clientes = retorno.data;
        //   state.pagination = data.pagination
        state.isLoading = false;
        state.pagina_selecionada = n_pagina;
      } catch (error) {
        handleErrors(error);
      }
    }

    return {
      state,
      buscarPorNome,
      SelectPlanos,
      SelectPersonais,
      selecionarPagina,
      deletarCliente,
      fetchClientes,
      array_permissoes,
    };
  },
};
</script>
<style scoped>
.i {
  font-size: 12pt;
}
.topo {
  background-color: #fff;
  padding-top: 25px;
  padding-bottom: 10px;

  border-bottom: 1px solid var(--color-stroke, #e7e7e7);
}
.topo h1 {
  color: var(--Color-azul-2, #162b48);
  font-weight: 800;
}

.btn_buscar_nome {
  font-size: 10pt;
  height: 40px;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 5px;
  background-color: rgb(3, 74, 166);
  border: none;
  color: #fff;
  font-weight: 700;
}

.div_pessoa {
  display: flex;
  text-align: right;
  justify-content: flex-end;
}
.avaliacao {
  color: #000;
  margin-top: 10px;
}
.num {
  margin-top: -15px;
}

.card .table th {
  padding: 15px !important;
}
.btn_add_cliente {
  transform: none;
  background: var(--Color-secundary, #aede00);
  color: rgb(255, 255, 255);
  border: 1px solid #aede00;
  border-radius: 5px;
  font-size: 9pt;
  padding: 5px;
  font-weight: 700;
  width: 100%;
  margin-top: 2px;
}
.page-item.active .page-link {
  border: #034aa6 solid 1px;
  background-color: #034aa6;
}
.quad_top {
  padding: 13px;
  gap: 10px;
  width: 50px;
  height: 50px;
  background: #010d1f;
  border-radius: 12px;
}
.persona {
  color: #aede00;
  width: 24px;
  height: 24px;
}
.i_quad {
  background: #034aa6;
  padding: 10px;
  border-radius: 100px;
  font-size: 16pt;
  color: #fff;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .plano_none {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .btn_buscar_nome {
    margin-top: 1em;
  }
  .btn_add_cliente {
    margin-top: 1em;
  }
  .plano_none {
    display: none;
  }
}
@media (max-width: 767px) {
  .btn_buscar_nome {
    width: 100%;
    margin-top: 10px;
  }
  .btn_add_cliente {
    margin-top: 10px;
  }
  .div_pessoa {
    text-align: left;
  }
}
</style>