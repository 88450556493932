<template>
  <topo :titulo="'Objetivos'"></topo>
  <div>
    <div class="container-fluid mt-3">
      <div
        class="card shadow p-3"
        :class="type === 'dark' ? 'bg-default' : ''"
        s
      >
        <div class="row align-items-center">
          <div class="col">
            <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
              Objetivos
            </h3>
          </div>
          <div class="col text-right mb-2">
            <base-button type="primary" id="btn_new_product" @click="abrirModal"
              >Adicionar Novo Objetivo</base-button
            >
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="table-responsive">
              <table
                class="table tablesorter table align-items-center table-flush"
              >
                <thead class="thead-light">
                  <th>Código</th>
                  <th>Nome</th>
                  <th></th>
                </thead>
                <tbody class="list">
                  <corpo-objetivo
                    v-for="objetivo in state.objetivos"
                    :key="objetivo.id"
                    :objetivo="objetivo"
                    @deletar="deletar"
                    @modalVisualizar="modalVisualizar"
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    :open="state.status_modal"
    @close="state.status_modal = !state.status_modal"
    :width="'800px'"
  >
    <h1>Objetivo</h1>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label>Código</label>
          <input
            type="text"
            class="form-control input_modal"
            v-model="state.id"
            readonly
          />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group">
          <label>Nome</label>
          <input
            type="text"
            class="form-control input_modal"
            v-model="state.nome"
          />
        </div>
      </div>
      <div class="col-lg-2">
        <div class="form-group">
          <label>Status</label>
          <select class="form-control" v-model="state.status">
            <option value="ativo">Ativo</option>
            <option value="inativo">Inativo</option>
          </select>
        </div>
      </div>
      <div class="col-lg-2">
        <div class="form-group">
          <label>Mostrar Home</label>
          <select class="form-control" v-model="state.mostrar_home">
            <option value="sim">Sim</option>
            <option value="nao">Não</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Descrição</label>
          <textarea class="form-control" v-model="state.descricao"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label>Tipo Caixa</label>
          <select class="form-control" v-model="state.tipo_caixa">
            <option value="interno">Interno</option>
            <option value="externo">Externo</option>
          </select>
        </div>
      </div>
    </div>
    <button
      class="btn btn_salvar my-4"
      id="submit-button"
      type="submit"
      @click="salvarObjetivo()"
    >
      Salvar
    </button>
    <button
      class="btn btn_cancelar my-4"
      id="submit-button"
      type="button"
      @click="fecharModal()"
    >
      Cancelar
    </button>
  </Modal>
</template>
<script>
import { onMounted, reactive, onErrorCaptured } from "vue";
import CorpoObjetivo from "../components/CorpoTabelaObjetivoTreino";
import services from "../services";
import Modal from "../components/Modal/Modal.vue";
import topo from "../components/topo";
import { useStorage } from "vue3-storage";
export default {
  name: "tables",
  components: { topo },
  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");
    const state = reactive({
      isLoading: false,
      objetivos: [],
      currentClientType: "",
      hasError: false,
      id: "",
      nome: "",
      descricao: "",
      status: "ativo",
    });
    onErrorCaptured(handleErrors);

    onMounted(() => {
      fetchObjetivos();
    });
    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }
    async function abrirModal() {
      state.status_modal = true;
    }

    function fecharModal() {
      state.id = "";
      state.nome = "";
      state.descricao = "";
      state.tipo_caixa = "interno";
      state.status_modal = false;
    }

    async function modalVisualizar(id) {
      try {
        let data = await services.objetivos.obterPorId({
          empresa_id,
          id,
          token,
        });
        state.status_modal = true;
        let dados = data.data;
        state.id = dados.id;
        state.nome = dados.nome;
        state.mostrar_home = dados.mostrar_home;
        state.descricao = dados.descricao;
      } catch (error) {
        console.log(error);
      }
    }
    async function deletar(id) {
      if (confirm("Deseja deletar o Objetivo?")) {
        await services.objetivos
          .deletar({
            token,
            empresa_id,
            id,
          })
          .then((data) => {
            if (data.data.verifica_erro) {
              alert("Erro ao deletar Objetivo");
            } else {
              fetchObjetivos();
            }
          })
          .catch(() => {
            alert("Erro ao deletar Objetivo");
          });
      }
    }

    async function salvarObjetivo() {
      if (state.id != null && state.id != undefined && state.id != "") {
        let retorno = await services.objetivos.update({
          empresa_id,
          token,
          id: state.id,
          nome: state.nome,
          mostrar_home: state.mostrar_home,
          caixa: state.caixa,
          descricao: state.descricao,
        });

        if (retorno.data.verifica_erro == false) {
          fetchObjetivos();
          state.status_modal = false;
        } else {
          alert("Erro ao salvar plano");
        }
      } else {
        let retorno = await services.objetivos.save({
          empresa_id,
          token,
          nome: state.nome,
          mostrar_home: state.mostrar_home,
          caixa: state.caixa,
          descricao: state.descricao,
        });

        if (retorno.data.verifica_erro == false) {
          fetchObjetivos();
          state.status_modal = false;
        } else {
          alert("Erro ao salvar plano");
        }
      }
    }

    async function fetchObjetivos() {
      try {
        state.isLoading = true;
        const { data } = await services.objetivos.listar({
          token: token,
          empresa_id: empresa_id,
        });
        //  console.log(data)
        state.objetivos = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    return {
      state,
      CorpoObjetivo,
      abrirModal,
      fecharModal,
      salvarObjetivo,
      modalVisualizar,
      Modal,
      deletar,
    };
  },
};
</script>

<style scoped>
.objetivo {
  width: 100%;
  height: 100px;
  border: #034aa6 solid 1px;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
}
.nome_objetivo {
  font-weight: bold;
}
.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}
.btn_salvar:hover {
  transform: none;
  color: #fff;
}
.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}
.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}
textarea {
  resize: none;
}
</style>