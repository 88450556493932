<template>
    <div class="topo">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-9">
                    <h1>{{ titulo }}</h1>
                    <slot></slot>
                </div>
                <div class="col-lg-3 div_pessoa d-none d-md-block">
                <div class="div_imagem" v-if="foto != undefined && foto != null">
                    <img :src="foto" />
                </div>
                <div class="div_imagem" v-else>
                    <img src="/img/user_sifit.png" />
                </div>
                <div class="nome_usuario">{{ state.nome_pessoa }}</div>
                </div>
                
            </div>
        </div>
      </div>
        
</template>

<script>
import { reactive } from "vue";

export default {
    props: {
        titulo: { type: String, required: true }
    },
    setup() {
        const state = reactive({
            idioma: 'PT',
        })

        return {
            state,
        //    titulo: props.titulo
        }
    }
}
</script>

<style scoped>
.topo{
    background-color: #fff;
    padding-top: 25px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--color-stroke, #E7E7E7); 
}
    .div_pessoa{
        display: flex;
        text-align: right;
        justify-content:flex-end;
    }
    .div_imagem{
        margin-top:10px;
        margin-right: 12px;
    }
    .div_imagem img{
        border-radius: 50%;
        width: 40px; 
        
    }

</style>