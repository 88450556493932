<template>
  <div class="container-fluid">
    <div class="topo">
      <div class="row">
        <div class="col-lg-3">
          <p class="bv" v-if="state.idioma == 'PT'">Bem Vindo</p>
          <p class="bv" v-else>Bienvenido</p>
          <p class="data">{{ data_atual }}</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3">
        <p class="bv" v-if="state.idioma == 'PT'">Clientes</p>
      </div>
    </div>
    <div class="row div_grafico">
      <div class="col-xl-4 col-lg-12 mt-4 mt-xl-0">
        <card type="default" header-classes="bg-transparent">
          <template v-slot:header>
            <div class="row align-items-center">
              <div class="col" v-if="state.idioma == 'PT'">
                <h6 class="text-light text-uppercase ls-1 mb-1">Frequência</h6>
              </div>
              <div class="col" v-else>
                <h6 class="text-light text-uppercase ls-1 mb-1">Frequência</h6>
              </div>
            </div>
          </template>
          <div class="chart-area">
            <canvas :height="250" id="chartFrequenciaAlunos"></canvas>
          </div>
        </card>
      </div>
      <div class="col-xl-4 col-lg-8 mt-4 mt-xl-0">
        <card type="default" header-classes="bg-transparent">
          <template v-slot:header>
            <div class="row align-items-center">
              <div class="col" v-if="state.idioma == 'PT'">
                <h6 class="text-light text-uppercase ls-1 mb-1">Idade</h6>
              </div>
              <div class="col" v-else>
                <h6 class="text-light text-uppercase ls-1 mb-1">Idade</h6>
              </div>
            </div>
          </template>
          <div class="chart-area">
            <canvas :height="250" id="chartIdadeAlunos"></canvas>
          </div>
        </card>
      </div>
      <div class="col-xl-4 col-lg-4 mt-4 mt-xl-0">
        <card type="default" header-classes="bg-transparent">
          <template v-slot:header>
            <div class="row align-items-center">
              <div class="col" v-if="state.idioma == 'PT'">
                <h6 class="text-light text-uppercase ls-1 mb-1">Sexo</h6>
              </div>
              <div class="col" v-else>
                <h6 class="text-light text-uppercase ls-1 mb-1">Sexo</h6>
              </div>
            </div>
          </template>
          <div class="chart-area">
            <canvas :height="250" id="chartSexoAlunos"></canvas>
          </div>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3">
        <p class="bv" v-if="state.idioma == 'PT'">Matrículas</p>
      </div>
    </div>
    <div class="row div_grafico">
      <div class="col-xl-9 col-lg-8">
        <card type="default" header-classes="bg-transparent">
          <template v-slot:header>
            <div class="row align-items-center">
              <div class="col" v-if="state.idioma == 'PT'">
                <h6 class="text-light text-uppercase ls-1 mb-1">Por Mês</h6>
              </div>
              <div class="col" v-else>
                <h6 class="text-light text-uppercase ls-1 mb-1">Por Mês</h6>
              </div>
            </div>
          </template>
          <div class="chart-area">
            <canvas :height="250" id="chartMatriculasMes"></canvas>
          </div>
        </card>
      </div>
      <div class="col-xl-3 col-lg-4 mt-4 mt-lg-0">
        <card type="default" header-classes="bg-transparent">
          <template v-slot:header>
            <div class="row align-items-center">
              <div class="col" v-if="state.idioma == 'PT'">
                <h6 class="text-light text-uppercase ls-1 mb-1">Por Plano</h6>
              </div>
              <div class="col" v-else>
                <h6 class="text-light text-uppercase ls-1 mb-1">Por Plano</h6>
              </div>
            </div>
          </template>
          <div class="chart-area">
            <canvas :height="250" id="chartMatriculasPlano"></canvas>
          </div>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3">
        <p class="bv" v-if="state.idioma == 'PT'">Contas a Receber</p>
      </div>
    </div>
    <div class="row div_grafico">
      <div class="col-xl-12">
        <card type="default" header-classes="bg-transparent">
          <template v-slot:header>
            <div class="row align-items-center">
              <div class="col" v-if="state.idioma == 'PT'">
                <h6 class="text-light text-uppercase ls-1 mb-1">Por Mês</h6>
              </div>
              <div class="col" v-else>
                <h6 class="text-light text-uppercase ls-1 mb-1">Por Mês</h6>
              </div>
            </div>
          </template>
          <div class="chart-area">
            <canvas :height="250" id="chartContasReceber"></canvas>
          </div>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3">
        <p class="bv" v-if="state.idioma == 'PT'">Cancelamentos</p>
      </div>
    </div>
    <div class="row div_grafico">
      <div class="col-xl-12">
        <card type="default" header-classes="bg-transparent">
          <template v-slot:header>
            <div class="row align-items-center">
              <div class="col" v-if="state.idioma == 'PT'">
                <h6 class="text-light text-uppercase ls-1 mb-1">Por Mês</h6>
              </div>
              <div class="col" v-else>
                <h6 class="text-light text-uppercase ls-1 mb-1">Por Mês</h6>
              </div>
            </div>
          </template>
          <div class="chart-area">
            <canvas :height="250" id="chartCancelamentos"></canvas>
          </div>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3">
        <p class="bv" v-if="state.idioma == 'PT'">Avaliações</p>
      </div>
    </div>
    <div class="row div_grafico">
      <div class="col-xl-9 col-lg-8 mt-4 mt-xl-0">
        <card type="default" header-classes="bg-transparent">
          <template v-slot:header>
            <div class="row align-items-center">
              <div class="col" v-if="state.idioma == 'PT'">
                <h6 class="text-light text-uppercase ls-1 mb-1">Por Mês</h6>
              </div>
              <div class="col" v-else>
                <h6 class="text-light text-uppercase ls-1 mb-1">Por Mês</h6>
              </div>
            </div>
          </template>
          <div class="chart-area">
            <canvas :height="250" id="chartAvaliacaoMes"></canvas>
          </div>
        </card>
      </div>
      <div class="col-xl-3 col-lg-4 mt-4 mt-xl-0">
        <card type="default" header-classes="bg-transparent">
          <template v-slot:header>
            <div class="row align-items-center">
              <div class="col" v-if="state.idioma == 'PT'">
                <h6 class="text-light text-uppercase ls-1 mb-1">
                  Por Personal
                </h6>
              </div>
              <div class="col" v-else>
                <h6 class="text-light text-uppercase ls-1 mb-1">
                  Por Personal
                </h6>
              </div>
            </div>
          </template>
          <div class="chart-area">
            <canvas :height="250" id="chartAvaliacaoPersonal"></canvas>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive } from "vue";
import services from "../services";
import { useStorage } from "vue3-storage";
import Chart from "chart.js";
export default {
  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");

    const state = reactive({
      idioma: "PT",
    });
    onMounted(() => {
      montaCharFrequenciaAlunos();
      montaChartIdadeAlunos();
      montaChartSexo();
      montaChartContasReceber();
      montaChartCancelamentos();
      montaChartMatriculaPorMes();
      montaChartMatriculaPorPlano();
      montaChartAvaliacaoPorMes();
      montaChartAvaliacaoPorPersonal();
    });
    async function montaCharFrequenciaAlunos() {
      var data = await services.home.frequenciaAlunos({
        token: token,
        empresa_id,
      });

      var titulo = [];
      var dados = [];

      for (let i = 0; i < data.data.length; i++) {
        const element = data.data[i];
        titulo.push(element.dias_na_semana);
        dados.push(element.quantidade_pessoas);
      }
      //   var vezes = ["1", "2", "3", "4", "5", "6"];
      //  dados = [20, 50, 10, 40, 30, 10];
      new Chart(document.querySelector("#chartFrequenciaAlunos"), {
        type: "bar",
        data: {
          labels: titulo,
          datasets: [
            {
              data: dados,
              backgroundColor: "#fff",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  fontColor: "#fff",
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  fontColor: "#fff",
                },
              },
            ],
          },
        },
      });
    }

    async function montaChartIdadeAlunos() {
      var data = await services.home.faixaEtaria({
        token: token,
        empresa_id,
      });
      console.log("sexo: " + data.data);
      var faixa_etaria = [];
      var dados = [];
      for (let i = 0; i < data.data.length; i++) {
        const element = data.data[i];
        faixa_etaria.push(element.faixa_etaria);
        dados.push(element.total);
      }

      new Chart(document.querySelector("#chartIdadeAlunos"), {
        type: "bar",
        data: {
          labels: faixa_etaria,
          datasets: [
            {
              data: dados,
              backgroundColor: "#fff",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  fontColor: "#fff",
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  fontColor: "#fff",
                },
              },
            ],
          },
        },
      });
    }
    //montaChartMatriculaPorMes
    async function montaChartSexo() {
      var data = await services.home.sexo({
        token: token,
        empresa_id,
      });
      var sexo = [];
      var dados = [];
      for (let i = 0; i < data.data.length; i++) {
        const element = data.data[i];
        sexo.push(element.sexo);
        dados.push(element.porcentagem);
      }

      new Chart(document.querySelector("#chartSexoAlunos"), {
        type: "pie",
        data: {
          labels: sexo,
          datasets: [
            {
              data: dados,
              backgroundColor: ["rgb(3, 74, 166)", "#fff"],
              borderWidth: 0, // Define a largura da borda como 0
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },

          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },

            yAxes: [
              {
                display: false,
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    }

    async function montaChartMatriculaPorMes() {
      var data = await services.home.matriculasPorMes({
        token: token,
        empresa_id,
      });
      var titulo = [];
      var dados = [];
      for (let i = 0; i < data.data.length; i++) {
        const element = data.data[i];
        titulo.push(element.nome_mes);
        dados.push(element.total_matriculas);
      }

      new Chart(document.querySelector("#chartMatriculasMes"), {
        type: "bar",
        data: {
          labels: titulo,
          datasets: [
            {
              data: dados,
              backgroundColor: "#fff",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  fontColor: "#fff",
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  fontColor: "#fff",
                },
              },
            ],
          },
        },
      });
    }

    async function montaChartMatriculaPorPlano() {
      var data = await services.home.matriculasPorPlano({
        token: token,
        empresa_id,
      });
      var titulo = [];
      var dados = [];
      for (let i = 0; i < data.data.length; i++) {
        const element = data.data[i];
        titulo.push(element.nome);
        dados.push(element.porcentagem_clientes);
      }

      new Chart(document.querySelector("#chartMatriculasPlano"), {
        type: "pie",
        data: {
          labels: titulo,
          datasets: [
            {
              data: dados,
              backgroundColor: "rgb(3, 74, 166)",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },

          scales: {
            yAxes: [
              {
                display: false,
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    }

    //aa

    async function montaChartContasReceber() {
      var data = await services.home.contasReceberPorMes({
        token: token,
        empresa_id,
      });
      var titulo = [];
      var dados1 = [];
      var dados2 = [];
      for (let i = 0; i < data.data.receber.length; i++) {
        const element = data.data.receber[i];
        titulo.push(element.mes);
        dados1.push(element.total_por_status);
      }

      for (let i = 0; i < data.data.recebidas.length; i++) {
        const element = data.data.recebidas[i];
        dados2.push(element.total_por_status);
      }

      /*   const lbl = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
      ];
      const data1 = [20, 35, 40, 25, 30, 20, 15];
      const data2 = [15, 25, 30, 20, 25, 10, 21];
*/
      new Chart(document.querySelector("#chartContasReceber"), {
        type: "bar",
        data: {
          labels: titulo,
          datasets: [
            {
              data: dados1,
              backgroundColor: "rgba(255, 99, 132, 0.2)", // cor de fundo da primeira série
              borderColor: "rgba(255, 99, 132, 1)", // cor da borda da primeira série
            },
            {
              data: dados2,
              backgroundColor: "rgba(54, 162, 235, 0.2)", // cor de fundo da segunda série
              borderColor: "rgba(255, 255, 255, 1)", // cor da borda da segunda série
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  fontColor: "#fff",
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  fontColor: "#fff",
                },
              },
            ],
          },
        },
      });
    }

    async function montaChartCancelamentos() {
      var data = await services.home.contasReceberPorMes({
        token: token,
        empresa_id,
      });
      console.log(data.data[0]);
      var titulo = [];
      var dados = [];
      for (let i = 0; i < data.data[0].length; i++) {
        const element = data.data[i];
        titulo.push(element.mes);
        dados.push(element.quantidade);
      }

      const lbl = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
      ];
      const data1 = [20, 35, 40, 25, 30, 20, 15];

      new Chart(document.querySelector("#chartCancelamentos"), {
        type: "bar",
        data: {
          labels: lbl,
          datasets: [
            {
              data: data1,
              backgroundColor: "rgba(255, 99, 132, 0.2)", // cor de fundo da primeira série
              borderColor: "rgba(255, 99, 132, 1)", // cor da borda da primeira série
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  fontColor: "#fff",
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  fontColor: "#fff",
                },
              },
            ],
          },
        },
      });
    }

    async function montaChartAvaliacaoPorMes() {
      var data = await services.home.avaliacoesPorMes({
        token: token,
        empresa_id,
      });
      var titulo = [];
      var dados = [];
      for (let i = 0; i < data.data.length; i++) {
        const element = data.data[i];
        titulo.push(element.mes);
        dados.push(element.quantidade);
      }

      new Chart(document.querySelector("#chartAvaliacaoMes"), {
        type: "bar",
        data: {
          labels: titulo,
          datasets: [
            {
              data: dados,
              backgroundColor: "#fff",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  fontColor: "#fff",
                },
              },
            ],
            xAxes: [
              {
                display: false,
                ticks: {
                  fontColor: "#fff",
                },
              },
            ],
          },
        },
      });
    }

    async function montaChartAvaliacaoPorPersonal() {
      var data = await services.home.avaliacoesPorPersonal({
        token: token,
        empresa_id,
      });
      var hora = [];
      var dados = [];
      for (let i = 0; i < data.data.length; i++) {
        const element = data.data[i];
        hora.push(element.nome);
        dados.push(element.percentual);
      }

      new Chart(document.querySelector("#chartAvaliacaoPersonal"), {
        type: "pie",
        data: {
          labels: hora,
          datasets: [
            {
              data: dados,
              backgroundColor: "rgb(3, 74, 166)",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },

          scales: {
            yAxes: [
              {
                display: false,
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    }

    /*       async function qtdClientesAusentes() {
             try {
               const qtdClientesAusentes = await services.clientes.qtdClientesAusentes(
                 {
                   token: token,
                   empresa_id: empresa_id,
                 }
               );
               state.qtdClientesAusentes = qtdClientesAusentes.data;
             } catch (error) {
               handleErrors(error);
             }
           } */
    return {
      state,
    };
  },
};
</script>
<style>
.bv {
  font-weight: bold;
  font-size: 18pt;
  color: #162b48;
  margin-bottom: 0;
}
</style>