const defaultPagination = {
  limit: 5,
  offset: 0
}
export default httpClient => ({
  listar: async ({ token, empresa_id } = defaultPagination) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/objetivos/' + empresa_id, { headers });
    return { data: response.data }
  },

  obterPorId: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/seleciona/objetivo/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },


  save: async ({ empresa_id, token, nome, mostrar_home, caixa,descricao }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/adiciona/grupo/' + empresa_id, {
      token,
      nome,
      mostrar_home,
      caixa,
      descricao
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

    update: async ({ empresa_id, token, id, nome, mostrar_home, caixa,descricao }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/edita/grupo/' + empresa_id, {
      token,
      id,
      nome,
      mostrar_home,
      caixa,
      descricao
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  deletar: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/deleta/grupo/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },

})