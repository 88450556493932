<template>
  <teleport to="body">
    <div
      v-if="state.isActive"
      class=" fixed-top   justify-content-center w-100 h-100 special-card"
      @click="handleModalToogle({ status: false })"
    >
    <div class="modal-dialog modal-lg animate__animated animate__fadeInDown animate__faster" role="document" @click.stop>
      <div class="modal-content">
        <component :is="state.component" />
      </div>
    </div>
    </div>
  </teleport>
</template>

<script>
import { reactive, onMounted, onBeforeUnmount, defineAsyncComponent } from 'vue'
import useModal from '../../hooks/useModal'
const ModalBuscarAluno = defineAsyncComponent(() => import('../ModalBuscarAluno'))
const ModalAgendarAvaliacoes = defineAsyncComponent(() => import('../ModalAgendarAvaliacoes'))
const ModalFazerAvaliacao = defineAsyncComponent(() => import('../ModalFazerAvaliacao'))
const DEFAULT_WIDTH = 'w-75 p-3'
export default {
  components: {
    ModalAgendarAvaliacoes,
    ModalFazerAvaliacao,
    ModalBuscarAluno
  },
  setup () {
    const modal = useModal()
    const state = reactive({
      isActive: false,
      component: {},
      props: {},
      width: DEFAULT_WIDTH
    })
    onMounted(() => {
      modal.listen(handleModalToogle)
    })
    onBeforeUnmount(() => {
      modal.off(handleModalToogle)
    })
    function handleModalToogle (payload) {
        

      if (payload.status) {
        console.log(payload);
        state.component = payload.component
        state.props = payload.props
        state.width = payload.width ?? DEFAULT_WIDTH
      } else {
        state.component = {}
        state.props = {}
        state.width = DEFAULT_WIDTH
      }
      state.isActive = payload.status
    }
    return {
      state,
      handleModalToogle
    }
  }
}
</script>

<style scoped>
.special-card {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>