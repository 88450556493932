<template>
  <div style="width: 100vw; height: 100vh;" :style="{ 'background-color': state.cor1 }">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <div class="card" style="margin-top: 40px;" :style="{ 'background-color': state.cor2 }">
            <div style="position: relative; margin-bottom: 20px;">
              <img src="/img/centrion.jpg" style="width: 100%; height: 180px;">
              <div class="logo" style="width: 150px; height: 150px;background-color: #fff; margin-top: -100px;position: absolute; z-index: 100; text-align: center;
              border-radius: 50%; left: 50%; margin-left: -75px; border: #000 solid 2px;">
                <img src=" /img/logo_sifit.png" style="width: 70%; margin-top: 55px">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <h4 :style="{ 'color': state.cor1 }">
                  Agende sua aula experimental
                </h4>
                <h5 :style="{ 'color': state.cor1 }">
                  Seus Dados
                </h5>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label :style="{ 'color': state.cor1 }">Nome</label>
                  <input type="text" class="form-control"
                    :style="{ 'background-color': state.cor2, 'color': state.cor1,borderColor: state.cor1 }">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-7">
                <div class="form-group">
                  <label :style="{ 'color': state.cor1 }">E-mail</label>
                  <input type="text" class="form-control"
                    :style="{ 'background-color': state.cor2, 'color': state.cor1, borderColor: state.cor1 }">
                </div>
              </div>
              <div class="col-sm-5">
                <div class="form-group">
                  <label :style="{ 'color': state.cor1 }">Telefone / Whatsapp</label>
                  <input type="text" class="form-control"
                    :style="{ 'background-color': state.cor2, 'color': state.cor1, borderColor: state.cor1 }">
                </div>
              </div>
            </div>
            <h5 :style="{ 'color': state.cor1 }">Seu Agendamento</h5>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label :style="{ 'color': state.cor1 }">Modalidade</label>
                  <select class="form-control"
                    :style="{ 'background-color': state.cor2, 'color': state.cor1, borderColor: state.cor1 }">
                    <option :value="modalidade.id" v-for="modalidade in state.modalidades" :key="modalidade.id">{{ modalidade.nome }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label :style="{ 'color': state.cor1 }">Data</label>
                  <Datepicker
                  v-model="state.data_final"
                      :locale="pt"
                      inputFormat="dd/MM/yyyy"
                      :style="{ 'background-color': state.cor2, 'color': state.cor1, borderColor: state.cor1 }"
                      style="
                    color: #505050;
                    border-radius: 7px;
                    border:  solid 1px;
                    transition: 0.5s;
                    width: 100%; 
                    padding: 8px;
                    "
                      />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label :style="{ 'color': state.cor1 }">Hora</label>
                  <select class="form-control"
                    :style="{ 'background-color': state.cor2, 'color': state.cor1, borderColor: state.cor1 }">
                    <option value="1">10:00</option>
                    <option value="2">11:00</option>
                    <option value="3">12:00</option>
                  </select>
                </div>
              </div>
            </div>
            <button class="btn btn_agendar"
              :style="{'background-color': state.cor1, 'color': state.cor2}">Agendar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import services from '../../services';
import Datepicker from "vue3-datepicker";
import { pt } from "date-fns/locale";
export default {
  components: {
    Datepicker
  },
  setup() {
    
    var empresa_id = 1;
    const state = reactive({
      nome: "",
      email: "",
      telefone: "",
      modalidade: "",
      cor1: "#000",
      cor2: "#fff",
      modalidades:[]
    });

    onMounted(() => {
      fetchModalidade();
    })

    async function fetchModalidade() {

      try {
        state.isLoading = true;
        let data = await services.grupos.listarGrupoAulaExperimental({
          empresa_id,
        });
        state.modalidades = data.data;
      } catch (error) {
        console.log(error);
      }
    }

    return {
      fetchModalidade,
      state,
      pt
    }
  }



}
</script>

<style>
.card{
  padding: 15px;
}
label{
  color: #000;
}
h3, h4,h5{
  color: #000;
}
</style>