<template>
<nav class="navbar navbar-expand-sm ">
  <a class="imagem navbar-brand header-link-1" href="#">
    <img src="@/assets/img_new/logo2.png" alt="logo" class="img"/>
  </a>
  <button style="color: #fff;" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-justify" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
    </svg>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link homeSite" v-if="idioma=='PT'" href="/">Home</a>
        <a class="nav-link homeSite" v-else href="/">Inicio</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="funcionalidades">Funcionalidades</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/#banner_4" v-if="idioma == 'PT'">Planos</a>
        <a class="nav-link" href="/#banner_4" v-else>Planes</a>
        
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/contato" v-if="idioma == 'PT'">Contato</a>
        <a class="nav-link" href="/contato" v-else>Contacto</a>
        
      </li>
    </ul>
    <ul class="my-2 my-lg-0 navbar-nav">
      <li class="nav-item ja_sou_cliente">
        <select class="select_idioma" @change="$emit('alterarIdioma', $event.target.value)">
          <option value="PT">PT</option>
          <option value="ES">ES</option>
        </select>
      </li>
      <li class="nav-item ja_sou_cliente">
        <a class="nav-link login" href="/login">Login</a>
      </li>
      <li>
        <button class="btn" type="submit" @click="$emit('abrirModal')" >
          <span v-if="idioma == 'PT'">Solicitar Demonstração</span>
          <span v-else>Solicitar demostración</span>
        </button>
      </li>
    </ul>
  </div>
</nav>
</template> 
<script>
import {reactive} from "vue";
export default {
  props: {
    idioma: { type: String, required: true },
  },
  setup(_, { emit }) {
    const state = reactive({
      planos: [],
      modalOpen: false,
    });
    function abrirModal() {
      state.modalOpen = true;
    }
    return {
      abrirModal,
      emit
    }
  }
  
}
</script>
<style scoped>

.select_idioma{
  background:none; border: #aede00 solid 1px; border-radius: 5px; padding: 8px; margin-top: -5px; color: #aede00;
}

.select_idioma option {
  margin: 40px;
  background-color: #000d1f;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

html {
  scroll-behavior: smooth;
}
.ja_sou_cliente {
  margin-top: 6px;
}
.nav-link {
  color: #ffffff;
  transition: 1s;
}
.nav-link:hover {
  color: #aede00;
}
/*---Botão---*/
.btn:hover span:after {
  opacity: 1;
  right: 1;
}
.btn {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  text-align: center;
  border: #aede00 solid 1px;
  color: #aede00;
  cursor: pointer;
}
.btn span {
  position: relative;
  transition: 0.2s;
}
.btn:hover {
  background-color: #aede00;
  border: #aede00 solid 1px;
  color: #000d1f;
  transform: none;
}
.btn span:after {
  margin-top: 0px;
  content: "\2794";
  position: absolute;
  opacity: 0;
  top: 0px;
  right: -10px;
  transition: 0.2s;
}
.btn:hover span {
  padding-right: 15px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .img{
    width: 110px;
  }
.btn{
  padding: 5px;
  font-size: 11px;
}
/*---Botão---*/
.btn:hover span:after {
  opacity: 1;
  right: 1;
}
.btn span {
  position: relative;
  transition: 0.2s;
}
.btn span:after {
  margin-top: 0px;
  content: "\2794";
  position: absolute;
  opacity: 0;
  top: -1px;
  right: -15px;
  transition: 0.2s;
}
.btn:hover span {
  padding-right: 15px;
}
}
@media (min-width: 576px) and (max-width: 767px) {
  .img{
    width: 100px;
  }
  .btn{
    display: none;
  }
  .navbar{
    padding: 5px;
  }
}
@media (max-width: 575px){
  .homeSite{
    margin-top: 20px;
  }
  ul{
    text-align: center;
    margin-top: 0px;
  }
  .ja_sou_cliente{
    margin-top: 0px;
  }
  .mt-2, .my-2{
    margin-top: 0px !important;
  }
  .login{
    margin-bottom: 10px;
  }
}
</style>