
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
import Vue3Storage from "vue3-storage";
import VueMask from '@devindex/vue-mask'; // <-- ADD THIS LINE
import { Collapse } from 'vue-collapsed'

import "element-plus/lib/theme-chalk/index.css";

const appInstance = createApp(App);
appInstance.use(router);
appInstance.use(VueMask);
appInstance.use(Vue3Storage);
appInstance.use(ArgonDashboard);
appInstance.component('Collapse', Collapse);
appInstance.mount("#app");
