<template>
  <topo :titulo="'Grupos Musculares'"></topo>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-3">
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <card class="card-stats">
            <i class="bi bi-clipboard2-data i_quad"></i>
            <p class="title1">Quantidade de Músculos</p>
            <div class="num">20</div>
          </card>
        </div>
        <div
          class="col-xl-3 offset-xl-3 col-lg-6 offset-md-6 mt-4 text-right mb-2"
        >
          <button type="button" class="btn_add_cliente" @click="abrirModal">
            Adicionar Novo Grupo
          </button>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <div
        class="card shadow p-3"
        :class="type === 'dark' ? 'bg-default' : ''"
        s
      >
        <div class="row align-items-center">
          <div class="col">
            <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
              Grupos Musculares
            </h3>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="table-responsive">
              <table
                class="table tablesorter table align-items-center table-flush"
              >
                <thead class="thead-light">
                  <th>Código</th>
                  <th>Nome</th>
                  <th></th>
                </thead>
                <tbody class="list">
                  <corpo-grupo
                    v-for="grupo in state.grupos"
                    :key="grupo.id"
                    :grupo="grupo"
                    @deletar="deletar"
                    @modalVisualizar="modalVisualizar"
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    :open="state.status_modal"
    @close="state.status_modal = !state.status_modal"
    :width="'800px'"
  >
    <h1>Grupo Muscular</h1>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label>Código</label>
          <input
            type="text"
            class="form-control input_modal"
            v-model="state.id"
            readonly
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Nome</label>
          <input
            type="text"
            class="form-control input_modal"
            v-model="state.nome"
          />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group">
          <label>Tipo Membro</label>
          <select class="form-control" v-model="state.tipo_membro">
            <option value="INFERIORES">Inferiores</option>
            <option value="SUPERIORES">Superiores</option>
            <option value="AEROBIO">Aeróbio</option>
          </select>
        </div>
      </div>
      <div class="col-md-5">
        <div class="input_img">
          <label>Imagem</label>
          <input type="file" class="form-control" @change="addImagem" />
          <div v-if="state.imagem.imagem != null && state.imagem.imagem != undefined">
            <img :src="state.imagem.imagem">
          </div>
          <span v-if="state.erro_imagem" class="text-danger"
            >Este campo é obrigatório</span
          >
        </div>
      </div>
    </div>
    <button
      class="btn btn_salvar my-4"
      id="submit-button"
      type="submit"
      @click="salvarGrupo()"
    >
      Salvar
    </button>
    <button
      class="btn btn_cancelar my-4"
      id="submit-button"
      type="button"
      @click="fecharModal()"
    >
      Cancelar
    </button>
  </Modal>
</template>
<script>
import { onMounted, reactive, onErrorCaptured } from "vue";
import CorpoGrupo from "../components/CorpoTabelaGrupo";
import services from "../services";
import Modal from "../components/Modal/Modal.vue";
import topo from "../components/topo";
import { useStorage } from "vue3-storage";
export default {
  name: "tables",
  components: {
    topo
  },
  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");
    const state = reactive({
      imagem:{},
      isLoading: false,
      grupos: [],
      currentClientType: "",
      hasError: false,
      id: "",
      nome: "",
      tipo_membro: "",
    });
    onErrorCaptured(handleErrors);

    onMounted(() => {
      fetchGrupos();
    });
    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }
    async function abrirModal() {
      state.status_modal = true;
    }

    function fecharModal() {
      state.id = "";
      state.nome = "";
      state.tipo_membro = "";
      state.status_modal = false;
    }

    async function modalVisualizar(id) {
      try {
        state.isLoading = true;
        let data = await services.gruposMusculares.obterPorId({
          empresa_id,
          id,
          token,
        });
        state.status_modal = true;
        let dados = data.data;
        state.id = dados.id;
        state.nome = dados.nome;
        state.tipo_membro = dados.tipo_membro;
        if(dados.imagem){
          state.imagem.imagem = dados.imagem;
        }
        
      } catch (error) {
        console.log(error);
      }
    }

    async function addImagem(event) {
      const element = event.target.files[0];
      var objImagem = new Object();
      //   objImagem.id = 0 + 1;
      objImagem.file = element;
      objImagem.imagem = URL.createObjectURL(element);
      state.imagem = objImagem;
    }

    async function deletar(id) {
      if (confirm("Deseja deletar o Grupo?")) {
        await services.gruposMusculares.deletar({
            token,
            empresa_id,
            id,
          })
          .then((data) => {
            if (data.data.verifica_erro) {
              alert("Erro ao deletar Grupo");
            } else {
              fetchGrupos();
            }
          })
          .catch(() => {
            alert("Erro ao deletar Grupo");
          });
      }
    }

    async function salvarGrupo() {
      var formGrupo = new FormData();
      formGrupo.append("nome", state.nome);
      formGrupo.append("tipo_membro", state.tipo_membro);
     
      if(state.imagem.file != undefined){
        formGrupo.append("imagem", state.imagem.file);
      }
      console.log(state.id);

      if (state.id != null && state.id != undefined && state.id != "") {
        formGrupo.append("id", state.id);
        
        let retorno = await services.gruposMusculares.update({
          empresa_id,
          formGrupo,
          token,
        });

        if (retorno.data.verifica_erro == false) {
          fetchGrupos();
          state.status_modal = false;
        } else {
          alert("Erro ao salvar plano");
        }
      } else {
        let retorno = await services.gruposMusculares.save({
          empresa_id,
          token,
          formGrupo,
        });

        if (retorno.data.verifica_erro == false) {
          fetchGrupos();
          state.status_modal = false;
        } else {
          alert("Erro ao salvar plano");
        }
      }
    }

    async function fetchGrupos() {
      try {
        state.isLoading = true;
        const { data } = await services.gruposMusculares.listar({
          token: token,
          empresa_id: empresa_id,
        });
        //  console.log(data)
        state.grupos = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    return {
      state,
      CorpoGrupo,
      abrirModal,
      fecharModal,
      salvarGrupo,
      modalVisualizar,
      Modal,
      deletar,
      addImagem,
    };
  },
};
</script>

<style scoped>
.grupo {
  width: 100%;
  height: 100px;
  border: #034aa6 solid 1px;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
}
.nome_grupo {
  font-weight: bold;
}
.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}
.btn_salvar:hover {
  transform: none;
  color: #fff;
}
.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}
.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}
textarea {
  resize: none;
}
.i_quad {
  background: #034aa6;
  padding: 10px;
  border-radius: 100px;
  font-size: 16pt;
  color: #fff;
}
.title1 {
  color: #000;
  margin-top: 10px;
  font-size: 11pt;
  line-height: 1;
}
.num {
  margin-top: -15px;
}
.btn_add_cliente {
  transform: none;
  background: var(--Color-secundary, #aede00);
  color: rgb(255, 255, 255);
  border: 1px solid #aede00;
  border-radius: 5px;
  font-size: 9pt;
  padding: 5px;
  font-weight: 700;
  width: 100%;
  margin-top: 2px;
}
</style>