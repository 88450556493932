<template>
<div></div>
</template>
<script>
import { onMounted, reactive } from "vue";
import { useStorage } from "vue3-storage";
import { useRouter } from "vue-router";

export default {
  setup() {    
    const router = useRouter();
    const storage = useStorage();
    const state = reactive({
    
        nome_usuario:""
    })
    onMounted(() => {
      var nome = storage.getStorageSync("nome_pessoa");
      nome = nome.split(" ")
      state.nome_usuario = nome[0]
    });

    async function deslogar(){
      storage.clearStorageSync();
      router.push({ name: "homeSite" });
    }
    return{
      state,
      useRouter,
      deslogar
    }
  },

  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
    };
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>
<style scoped>
  .nav-link{
    cursor: pointer;
  }

.baseNav{
  background-color: #fff !important;
}

</style>