<template>
    <topo :titulo="'Cadastrar Ficha de Treino'"></topo>
  <div>
    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <stats-card>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/modelos-contrato" class="primary"
                    >Modelos Contrato</router-link
                  >
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Adicionar
                </li>
              </ol>
            </nav>
            <form>
              <div class="row">
                <div class="col-lg-1">
                  <div class="form-group">
                    <label>ID</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.id"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Título</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.nome"
                    />
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label>Status</label>
                    <select v-model="state.status" class="form-control">
                      <option value="ATIVO">Ativo</option>
                      <option value="INATIVO">Inativo</option>
                    </select>
                  </div>
                </div>

              </div>

              <div class="row">
                <div class="col-lg-9">
                  <div class="form-group">
                    <label>Texto</label>
                    <textarea
                      class="form-control"
                      v-model="state.texto"
                    ></textarea>
                  </div>
                </div>
              </div>

              <button
                @click="salvarModelo()"
                class="btn btn_salvar my-4"
                id="submit-button"
                :disabled="state.isLoading"
                type="button"
              >
                Salvar
              </button>
              <button
                class="btn btn_cancelar my-4"
                id="submit-button"
                type="button"
                @click="cancel()"
              >
                Cancelar
              </button>
            </form>
          </stats-card>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import { onMounted, reactive } from "vue";
import services from "../services";
import CorpoBuscaCliente from "../components/CorpoBuscaClientes";
import { useRouter } from "vue-router";
import { useStorage } from "vue3-storage";
import { pt } from "date-fns/locale";
import Datepicker from "vue3-datepicker";
import topo from "../components/topo";
export default {
  components: { topo },
  setup(_, { emit }) {
    // var isOpen = ref(false);
    const storage = useStorage();
    var empresa_id = storage.getStorageSync("empresa_id");
    var token = storage.getStorageSync("token");

    const router = useRouter();

    function cancel() {
      router.push({ name: "fichasTreino" });
    }

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      isOpen: false,
      id:null,
      titulo: "",
      texto: "",
      status: "",

    });

    onMounted(() => {
      state.id = router.currentRoute._value.params.id;
      if (state.id != undefined && state.id != null) {
        fetchModeloContrato(router.currentRoute._value.params.id);
      }
      fetchAvaliadores();
    });



    function buscarCliente(id_cliente, nome_cliente) {
      state.id_cliente = id_cliente;
      state.nome_cliente = nome_cliente;
      state.isOpen = false;
    }


    async function fetchClientes() {
      try {
        state.isLoading = true;
        const { data } = await services.clientes.clientesPorNome({
          empresa_id: empresa_id,
          token: token,
          nome: state.nome,
        });
        console.log(data);
        //  console.log(data)
        state.clientes = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function fetchModeloContrato(id) {
      try {
        state.isLoading = true;
        const { data } = await services.modeloContrato.obterPorId({
          empresa_id: empresa_id,
          token: token,
          id
        });
        state.nome = data.nome;
        state.texto = data.texto;
        state.status = data.status;
        console.log(data);
        //  console.log(data)
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function fetchAvaliadores() {
      try {
        state.isLoading = true;
        const { data } = await services.colaboradores.getAvaliadores({
          empresa_id: empresa_id,
          token: token,
        });
        console.log(data);
        //  console.log(data)
        state.avaliadores = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }
    

    async function salvarModelo() {
      try {
        state.isLoading = true;
        if (state.id != undefined && state.id != null) {
          await services.modeloContrato.update({
          id: state.id,
          empresa_id: empresa_id,
          token: token,
          nome: state.nome,
          texto: state.texto,
          status: state.status
         }).then((res) => {
          if (res.data.verifica_erro) {
            alert("Erro ao salvar Ficha.");
            return
           }
        })
        .catch(() => {
            alert("Erro ao salvar Ficha.");
        });
         
        } else {
           await services.modeloContrato.save({
          empresa_id: empresa_id,
          token: token,
          nome: state.nome,
          texto: state.texto,
          status: state.status
         }).then((res) => {
          if (res.data.verifica_erro) {
            alert("Erro ao salvar Ficha.");
            return
           }
        })
        .catch(() => {
            alert("Erro ao salvar Ficha.");
        });
        }
        

        state.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    }
    return {
      state,
      salvarModelo,
      fetchClientes,
      CorpoBuscaCliente,
      emit,
      buscarCliente,
      cancel,
      Datepicker,
      pt,
    };
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}
input {
  border-color: #b8b8b8;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
}
input:focus {
  border-color: #252525;
}
select {
  border-color: #b8b8b8;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
}
select:focus {
  border-color: #252525;
}
.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}
.btn_salvar:hover {
  transform: none;
  color: #fff;
}
.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}
.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}
.div_s {
  height: 350px;
  overflow-y: scroll;
}
.btn_fechar {
  color: #fff;
  background-color: #788596;
}
.btn_fechar:hover {
  color: #fff;
  background-color: #788596;
  transform: none;
}
.btn_lupa {
  background-color: #aede00;
  padding: 10px;
  border-color: #aede00 solid 1px !important;
  color: rgb(0, 0, 0) !important;
  border-radius: 10px !important;
}
.btn_lupa:hover {
  transform: none;
}
</style>