import axios from 'axios'
import AuthService from './auth'
import HomeService from './home'
import ClienteService from './clientes'
import ColaboradorService from './colaboradores'
import FornecedoresService from './fornecedores'
import AvaliacaoService from './avaliacoes'
import MatriculaService from './matriculas'
import PaisService from './paises'
import EstadoService from './estados'
import CidadeService from './cidades'
import DepartamentoService from './departamentos'
import DistritoService from './distritos'
import ContaReceberService from './conta_receber'
import ContaPagarService from './conta_pagar'
import CategoriaContasPagar from './categoria_conta_pagar'
import PlanoService from './planos'
import PlanosSifitServices from './planos_sifit'
import PersonaisService from './personais'
import TipoPagamentoService from './tipo_pagamento'
import LocalRecebimetoService from './local_recebimento'
import BandeiraService from './bandeira'
import FichaTreinoService from './ficha_treino'
import CaixaService from './caixa'
import GrupoService from './grupos'
import SiteService from "./site";
import TiposContasPagarService from './tipo_contas_pagar';
import DespesasFixaService from './despesas_fixa';
import LocalContasService from './local_contas';
import FuncaoService from './funcao';
import ConfiguracoesService from './configuracoes';
import BiometriasService from './biometrias';
import PermissaoService from './permissao';
import BancoService from './bancos';
import GrupoMuscularService from './grupos_musculares';
import ExercicioService from './exercicio';
import AplicativoService from './aplicativo';
import ModeloContratoService from './modelo_contrato';
import EnqueteService from './enquete';
import PresencaService from './presencas'
import ObjetivosTreinoService from './objetivos'

const API_ENVS = {
  production: '',
  development: '',
  local: 'https://backend.sifit.com.br/api/'
}

const httpClient = axios.create({
  baseURL: API_ENVS.local
})

export default {
  auth: AuthService(httpClient),
  home: HomeService(httpClient),
  clientes: ClienteService(httpClient),
  colaboradores: ColaboradorService(httpClient),
  fornecedores: FornecedoresService(httpClient),
  avaliacoes: AvaliacaoService(httpClient),
  caixa: CaixaService(httpClient),
  matriculas: MatriculaService(httpClient),
  paises: PaisService(httpClient),
  estados: EstadoService(httpClient),
  cidades: CidadeService(httpClient),
  departamentos: DepartamentoService(httpClient),
  distrito: DistritoService(httpClient),
  contaReceber: ContaReceberService(httpClient),
  contaPagar: ContaPagarService(httpClient),
  catContaPagar: CategoriaContasPagar(httpClient),
  planos: PlanoService(httpClient),
  planosSifit: PlanosSifitServices(httpClient),
  personais: PersonaisService(httpClient),
  tipoPagamento: TipoPagamentoService(httpClient),
  bandeira: BandeiraService(httpClient),
  ficha: FichaTreinoService(httpClient),
  grupos: GrupoService(httpClient),
  localRecebimento: LocalRecebimetoService(httpClient),
  site: SiteService(httpClient),
  tiposContasPagar: TiposContasPagarService(httpClient),
  despesasFixa: DespesasFixaService(httpClient),
  localContas: LocalContasService(httpClient),
  funcao: FuncaoService(httpClient),
  configuracoes: ConfiguracoesService(httpClient),
  biometrias: BiometriasService(httpClient),
  permissao: PermissaoService(httpClient),
  bancos: BancoService(httpClient),
  gruposMusculares: GrupoMuscularService(httpClient),
  exercicio: ExercicioService(httpClient),
  aplicativo: AplicativoService(httpClient),
  modeloContrato: ModeloContratoService(httpClient),
  enquete: EnqueteService(httpClient),
  presenca: PresencaService(httpClient),
  objetivos: ObjetivosTreinoService(httpClient)
}