<template>
  <topo :titulo="'Modelos Contrato'"></topo>
  <div>
        <div class="container-fluid mt-3">
      <div class="card shadow p-3" :class="type === 'dark' ? 'bg-default' : ''" s>
        <div class="row align-items-center">

          <div class="col text-right mb-2">
            <a href="/adicionar-modelo-contrato">
               <base-button type="primary" id="btn_new_product">Adicionar Modelo Contrato</base-button>
            </a>
          </div>
        </div>
        
          <div class="row">
            <div class="col">
              <div class="table-responsive">
                <table
                  class="table tablesorter table align-items-center table-flush"
                >
                  <thead class="thead-light">
                    <th>Código</th>
                    <th>Nome</th>
                    <th>Status</th>
                    <th></th>
                  </thead>
                  <tbody class="list">
                    <corpo-modelo
                      v-for="modelo in state.modelos"
                      :key="modelo.id"
                      :modelo="modelo"
                      @deletar="deletar"
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { onMounted, reactive, onErrorCaptured  } from 'vue'
  import CorpoModelo from '../components/CorpoTabelaModeloContrato'
  import services from '../services'
  import topo from "../components/topo";
  import { useStorage } from "vue3-storage";
export default {
  name: "tables",
  components: { topo },
    setup () {
    const storage = useStorage();
    var token =  storage.getStorageSync('token')
    var empresa_id = storage.getStorageSync('empresa_id')
    const state = reactive({
      isLoading: false,
      modelos: [],
      currentClientType: '',
      hasError: false,
      id:"",
      nome: "",
      mostrar_home: "",
      tipo_caixa: "interno",
      descricao:""
    })
    onErrorCaptured(handleErrors)

    onMounted(() => {
      fetchModelosContrato()
    })
    function handleErrors (error) {
      console.log(error)
      state.hasError = !!error
    }

    async function deletar(id) {
      if (confirm("Deseja deletar o Modelo?")) {
        await services.modelos.deletar({
          token,
          empresa_id,
          id
        }).then((data) => {
          if (data.data.verifica_erro) {
            alert("Erro ao deletar Modelo")
          } else {
            fetchModelosContrato();
          }
        })
          .catch(() => {
            alert("Erro ao deletar Modelo")
          });
      }
    }

      
    async function salvarModelo() {
      if (state.id != null && state.id != undefined && state.id != "") {
        let retorno = await services.modelos.update({
          empresa_id,
          token,
          id: state.id,
          nome: state.nome,
          mostrar_home: state.mostrar_home,
          caixa: state.caixa,
          descricao: state.descricao
        });

        if (retorno.data.verifica_erro == false) {
          fetchModelosContrato();
          state.status_modal = false;
        } else {
          alert("Erro ao salvar plano");
        }
      } else {
        let retorno = await services.modelos.save({
          empresa_id,
          token,
          nome: state.nome,
          mostrar_home: state.mostrar_home,
          caixa: state.caixa,
          descricao: state.descricao
        });

        if (retorno.data.verifica_erro == false) {
          fetchModelosContrato();
          state.status_modal = false;
        } else {
          alert("Erro ao salvar plano");
        }
      }
    }


    async function fetchModelosContrato () {
      try {
        state.isLoading = true
        const { data } = await services.modeloContrato.listar({
          token: token,
          empresa_id: empresa_id
        }
        )
      //  console.log(data)
        state.modelos = data
        state.isLoading = false
        
      } catch (error) {
        handleErrors(error)
      }
    }
    return {
      state,
      CorpoModelo,
      salvarModelo,
      deletar
    }
  }
}

</script>

<style scoped>
.modelo{
  width: 100%;
  height: 100px;
  border: #034AA6 solid 1px;
  border-radius: 5px;
  margin-bottom: 10px;
  padding:15px;
}
.nome_modelo{
  font-weight:bold;
}
.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}
.btn_salvar:hover {
  transform: none;
  color: #fff;
}
.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}
.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}
textarea{
  resize: none;
}
</style>