<template>
  <topo :titulo="'Planos'"></topo>
  <div>
    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-lg-2 col-md-3 mb-2 col-sm-4">
          <input type="text" class="form-control" placeholder="Código" />
        </div>
        <div class="col-lg-6 col-md-9 col-sm-8">
          <input placeholder="Nome" type="text" class="form-control" />
        </div>
        <div class="col-lg-2 col-md-4 mt-2 col-sm-6">
          <button type="primary" class="btn_busca">Buscar</button>
        </div>
        <div class="col-lg-2 offset-lg-0 col-md-6 offset-md-2 mt-2 col-sm-6">
          <button type="primary" class="btn_add" @click="abrirModal">
            Adicionar
          </button>
        </div>
      </div>
    </div>
    <div class="container-fluid mt-4">
      <div class="card shadow p-3" :class="type === 'dark' ? 'bg-default' : ''">
        <div class="row">
          <div class="col">
            <div class="table-responsive">
              <table
                class="table tablesorter table align-items-center table-flush"
              >
                <thead class="thead-light">
                  <th>Código</th>
                  <th>Nome</th>
                  <th>Status</th>
                  <th></th>
                </thead>
                <tbody class="list">
                  <corpo-tabela-plano
                    v-for="plano in state.planos"
                    :key="plano.id"
                    :plano="plano"
                    @deletar="deletar"
                    @modalPermissao="modalPermissao"
                    @modalVisualizar="modalVisualizar"
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    :open="state.status_modal"
    @close="state.status_modal = !state.status_modal"
    :width="'800px'"
  >
    <h1>Plano</h1>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label>Código</label>
          <input
            type="text"
            class="form-control input_modal"
            v-model="state.id"
            readonly
          />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group">
          <label>Nome</label>
          <input
            type="text"
            class="form-control input_modal"
            v-model="state.nome"
          />
        </div>
      </div>
      <div class="col-lg-3">
        <div class="form-group">
          <label>Grupo</label>
          <select class="form-control input_modal" v-model="state.grupo_id">
            <option
              v-for="grupo in state.grupos"
              :key="grupo.id"
              :value="grupo.id"
            >
              {{ grupo.nome }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="form-group">
          <label>Status</label>
          <select class="form-control input_modal" v-model="state.status">
            <option value="ativo">Ativo</option>
            <option value="inativo">Inativo</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3">
        <div class="form-group">
          <label>Duração</label>(meses)
          <input
            type="number"
            class="form-control input_modal"
            v-model="state.duracao"
          />
        </div>
      </div>
      <div class="col-lg-2">
        <div class="form-group">
          <label>Parcelas</label>
          <input
            type="number"
            class="form-control input_modal"
            v-model="state.qtd_parcelas"
          />
        </div>
      </div>
      <div class="col-lg-3">
        <div class="form-group">
          <label>Valor Mensal</label>
          <input
            type="number"
            class="form-control input_modal"
            v-model="state.valor"
          />
        </div>
      </div>
      <div class="col-lg-3">
        <div class="form-group">
          <label>Valor Matrícula</label>
          <input
            type="number"
            class="form-control input_modal"
            v-model="state.valor_matricula"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3">
        <div class="form-group">
          <label>Tem Personal?</label>
          <select v-model="state.personal" class="form-control input_modal">
            <option value="sim">Sim</option>
            <option value="nao">Não</option>
          </select>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="form-group">
          <label>Função dos Professores</label>
          <select class="form-control input_modal" v-model="state.funcao_id">
            <option
              v-for="funcao in state.funcoes"
              :key="funcao.id"
              :value="funcao.id"
            >
              {{ funcao.nome }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="form-group">
          <label>Dependentes</label>
          <select
            v-model="state.qtd_dependentes"
            class="form-control input_modal"
          >
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label>Benefícios</label>
          <div v-for="(beneficio, index) in state.beneficios" :key="index.id">
            <input
              type="text"
              class="form-control input_modal input_beneficios"
              v-model="state.beneficios[index].beneficio"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <button class="btn" @click="adicionarBeneficio()">
          <i class="fas fa-plus"></i>
        </button>
      </div>
    </div>

    <button
      class="btn btn_salvar my-4"
      id="submit-button"
      type="button"
      @click="salvarPlano()"
    >
      Salvar
    </button>
    <button
      class="btn btn_cancelar my-4"
      id="submit-button"
      type="button"
      @click="fecharModal()"
    >
      Cancelar
    </button>
  </Modal>

  <Modal
    :open="state.status_modal_permissao"
    @close="state.status_modal_permissao = !state.status_modal_permissao"
  >
    <h1>Permissões</h1>

    <button class="btn btn_salvar my-4" id="submit-button" type="submit">
      Salvar
    </button>
    <button
      class="btn btn_cancelar my-4"
      id="submit-button"
      type="button"
      @click="fecharPermissoes()"
    >
      Cancelar
    </button>
  </Modal>
</template>
<script>
import { onMounted, reactive } from "vue";
import Modal from "../components/Modal/Modal.vue";
import { useStorage } from "vue3-storage";
import CorpoTabelaPlano from "../components/CorpoTabelaPlano";
import services from "../services";
import topo from "../components/topo";

export default {
  components: { topo },
  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");

    const state = reactive({
      id: null,
      nome: "",
      tipo_funcionario: "",
      avaliador: "",
      personal: "",
      valor_mensal: "",
      valor_matricula: "",
      planos: [],
      grupos: [],
      beneficios: [],
      isOpen: false,
      status_modal: false,
      status_modal_permissao: false,
      status: "ativo",
    });

    onMounted(() => {
      fetchPlanos();
    });

    async function adicionarBeneficio() {
      state.beneficios.push({ beneficio: null });
    }

    async function abrirModal() {
      await fetchGrupos();
      await fetchFuncoes();
      state.status_modal = true;
    }

    function fecharModal() {
      limparCampos();
      state.status_modal = false;
    }

    function modalPermissao() {
      fetchCategoriasPermissao();
      state.status_modal_permissao = true;
    }

    function fecharPermissoes() {
      state.status_modal_permissao = false;
    }

    async function fetchPlanos() {
      try {
        state.isLoading = true;
        const { data } = await services.planos.getAll({
          empresa_id,
          token,
        });
        state.planos = data;
      } catch (error) {
        console.log(error);
      }
    }

    async function deletar(id) {
      if (confirm("Deseja deletar o Plano?")) {
        await services.planos
          .deletar({
            token,
            empresa_id,
            id,
          })
          .then((data) => {
            if (data.data.verifica_erro) {
              alert("Erro ao deletar Plano");
            } else {
              fetchPlanos();
            }
          })
          .catch(() => {
            alert("Erro ao deletar Plano");
          });
      }
    }

    async function fetchGrupos() {
      try {
        state.isLoading = true;
        const { data } = await services.grupos.listar({
          empresa_id,
          token,
        });
        state.grupos = data;
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchFuncoes() {
      try {
        state.isLoading = true;
        const { data } = await services.funcao.getAll({
          empresa_id,
          token,
        });
        state.funcoes = data;
      } catch (error) {
        console.log(error);
      }
    }

    async function modalVisualizar(id) {
      try {
        await fetchGrupos();
        await fetchFuncoes();
        state.isLoading = true;
        let data = await services.planos.obterPorId({
          empresa_id,
          id,
          token,
        });
        state.status_modal = true;
        let dados = data.data;
        state.id = dados.id;
        state.nome = dados.nome_plano;
        state.grupo_id = dados.grupo_id;
        state.duracao = dados.duracao;
        state.parcelas = dados.parcelas;
        state.valor = dados.valor;
        state.valor_matricula = dados.valor_matricula;
        state.personal = dados.personal;
        state.funcao_id = dados.funcao_id;
        state.qtd_parcelas = dados.qtd_parcelas;
        state.qtd_dependentes = dados.qtd_dependentes;
        state.status = dados.status;
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchCategoriasPermissao() {
      try {
        state.isLoading = true;
        const { data } = await services.permissao.buscaCategoria({
          empresa_id,
          token,
        });
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    }

    async function salvarPlano() {
      if (state.id != null && state.id != undefined) {
        let retorno = await services.planos.update({
          empresa_id,
          token,
          id: state.id,
          nome: state.nome,
          grupo_id: state.grupo_id,
          duracao: state.duracao,
          qtd_parcelas: state.qtd_parcelas,
          personal: state.personal,
          funcao_id: state.funcao_id,
          valor: state.valor,
          valor_matricula: state.valor_matricula,
          beneficios: state.beneficios,
          qtd_dependentes: state.qtd_dependentes,
          status: state.status,
        });
        if (retorno.data.verifica_erro == false) {
          fetchPlanos();
          state.status_modal = false;
          limparCampos();
        } else {
          alert("Erro ao salvar plano");
        }
      } else {
        let retorno = await services.planos.save({
          empresa_id,
          token,
          nome: state.nome,
          grupo_id: state.grupo_id,
          duracao: state.duracao,
          qtd_parcelas: state.qtd_parcelas,
          personal: state.personal,
          funcao_id: state.funcao_id,
          valor: state.valor,
          valor_matricula: state.valor_matricula,
          beneficios: state.beneficios,
          qtd_dependentes: state.qtd_dependentes,
          status: state.status,
        });

        if (retorno.data.verifica_erro == false) {
          fetchPlanos();
          state.status_modal = false;
          limparCampos();
        } else {
          alert("Erro ao salvar plano");
        }
      }
    }

    async function limparCampos() {
      state.id = null;
      state.nome = null;
      state.grupo_id = null;
      state.duracao = null;
      state.qtd_parcelas = null;
      state.personal = null;
      state.funcao_id = null;
      state.qtd_dependentes = null;
      state.beneficios = [];
      state.status_modal = false;
      state.valor = null;
      state.valor_matricula = null;
    }

    return {
      fetchPlanos,
      state,
      Modal,
      CorpoTabelaPlano,
      abrirModal,
      adicionarBeneficio,
      salvarPlano,
      modalPermissao,
      fecharModal,
      fecharPermissoes,
      fetchCategoriasPermissao,
      modalVisualizar,
      deletar,
      //fecharModal,
    };
  },
};
</script>
<style scoped>
.btn_busca {
  font-size: 10pt;
  height: 40px;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 5px;
  background-color: rgb(3, 74, 166);
  border: none;
  color: #fff;
  font-weight: 700;
  margin-top: -6px;
}
.btn_add {
  transform: none;
  background: var(--Color-secundary, #aede00);
  color: rgb(255, 255, 255);
  border: 1px solid #aede00;
  border-radius: 5px;
  font-size: 9pt;
  padding: 10px;
  font-weight: 700;
  width: 100%;
  margin-top: -5px;
}
.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}
.btn_salvar:hover {
  transform: none;
  color: #fff;
}
.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}
.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}
.input_modal {
  margin: 0;
}
.input_beneficios {
  margin-bottom: 5px;
}
</style>
