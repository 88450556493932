<template>
  <topo :titulo="'Visualizar Cliente'"></topo>

  <div class="container-fluid mt-3">
    <div class="card shadow p-3" :class="type === 'dark' ? 'bg-default' : ''" s>
      <div class="row">
        <div class="col-xl-6">
          <div class="card mb-3" :class="type === 'dark' ? 'bg-default' : ''">
            <div class="row">
              <div class="col-sm-8 col-xl-8">
                <div>
                  <div
                    class="card-header border-0"
                    :class="type === 'dark' ? 'bg-transparent' : ''"
                  >
                    <div class="row">
                      <div class="col-xl-8">
                        <div class="form-group" style="margin-bottom: 0">
                          <p
                            v-if="state.status == 'ativo'"
                            style="
                              font-size: 10pt;
                              margin-bottom: 0;
                              color: #00a65a;
                              text-transform: capitalize;
                            "
                          >
                            Cliente {{ state.status }}
                          </p>
                          <p
                            v-else
                            style="
                              font-size: 10pt;
                              margin-bottom: 0;
                              color: #dd4b39;
                              text-transform: capitalize;
                            "
                          >
                            Cliente {{ state.status }}
                          </p>
                        </div>
                      </div>

                      <div class="col-xl-12">
                        <div class="form-group" style="margin-bottom: 0">
                          <p style="font-size: 20pt; margin-bottom: 0">
                            {{ state.nome }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-8" style="margin-bottom: 10px">
                        Data Nasc: {{ state.data_nascimento }} -
                        {{ state.idade }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-8" style="margin-bottom: 10px">
                        Data de Cadastro: {{ state.data_cadastro }}
                      </div>
                    </div>
                    <h4>Telefones</h4>
                    <div class="row">
                      <div class="col-md-12">
                        Principal: {{ state.telefone }}
                      </div>
                      <div class="col-md-12" v-if="state.celular != ''">
                        Alternativo: {{ state.celular }}
                      </div>
                    </div>
                    <div class="row" v-if="state.tel_emergencia != ''">
                      <div class="col-md-12">
                        <h4>Tel. Emergência</h4>
                        {{ state.tel_emergencia }}
                      </div>
                    </div>
                    <div class="row" v-if="state.email != ''">
                      <div class="col-md-6">
                        <h4>E-mail</h4>
                        {{ state.email }}
                      </div>
                    </div>
                    <div class="row" v-if="state.endereco != ''">
                      <div class="col-md-12">
                        <h4>Endereço</h4>
                        {{ state.endereco }} - {{ state.numero }} -
                        {{ state.bairro }} - {{ state.complemento }}
                      </div>
                    </div>
                    <div class="row" v-if="state.endereco != ''">
                      <div class="col-md-12">
                        {{ state.nome_cidade }} - {{ state.nome_estado }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <h4>Observação</h4>
                        {{ state.observacao }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="space_btn">
                  <router-link
                    :to="'/alterar-cliente/' + state.cliente_id"
                    class="primary"
                  >
                    <button
                      type="primary"
                      id="btn_new_product"
                      class="btn_edit"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
                        />
                        <path
                          fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>
                    </button>
                  </router-link>
                </div>
                <div style="width: 100%; text-align: left">
                  <img
                    src="/img/user.jpg"
                    v-if="!state.imagem"
                    style="width: 80%"
                  />
                  <img
                    :src="state.imagem"
                    v-else
                    style="width: 80%; max-height: 220px"
                  />
                  <button type="button" style="width: 80%; border: none">
                    Alterar Imagem
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3" :class="type === 'dark' ? 'bg-default' : ''">
            <h4>Desempenho do aluno</h4>
            <div class="row">
              <div class="col-sm-3">
                <canvas id="gaugeChart" width="100" height="100"></canvas>
              </div>
              <div class="col-sm-9">
                <p>Média de presenças por semana: <span>3 / 6</span></p>

                <p>
                  Tempo parado por mês (acima de 3 dias seguindos sem treinar):
                  <span>3 / 6</span>
                </p>
              </div>
            </div>
          </div>
          <div class="card mb-3" :class="type === 'dark' ? 'bg-default' : ''">
            <h4>Objetivos e resultados</h4>
            <div class="row">
              <div class="col-sm-3">
                <canvas id="gaugeChart" width="100" height="100"></canvas>
              </div>
              <div class="col-sm-9">
                <p>Objetivos: <span>3 / 6</span></p>
                <p>
                  Tempo parado por mês (acima de 3 dias seguindos sem treinar):
                  <span>3 / 6</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-6">
          <div
            class="card shadow mb-3"
            :class="type === 'dark' ? 'bg-default' : ''"
          >
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div
                  class="col"
                  style="display: flex; justify-content: space-between"
                >
                  <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                    Últimas Presenças
                  </h3>
                  <button
                    type="button"
                    class="btn_transparente"
                    @click="modalUltimasPresencas"
                  >
                    <i class="bi bi-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3" :class="type === 'dark' ? 'bg-default' : ''">
            <div class="col">
              <div class="table-responsive">
                <table
                  class="table tablesorter table align-items-center table-flush"
                >
                  <thead class="thead-light">
                    <th>Data / Hora</th>
                  </thead>
                  <tbody class="list">
                    <tabela-presenca-cliente
                      v-for="presenca in state.presencas"
                      :key="presenca.id"
                      :presenca="presenca"
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div
            class="card shadow mb-3"
            :class="type === 'dark' ? 'bg-default' : ''"
          >
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div
                  class="col"
                  style="display: flex; justify-content: space-between"
                >
                  <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                    Últimas Contas
                  </h3>
                  <button
                    type="button"
                    class="btn_transparente"
                    @click="modalUltimasContas"
                  >
                    <i class="bi bi-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3" :class="type === 'dark' ? 'bg-default' : ''">
            <div class="col">
              <div class="table-responsive">
                <table
                  class="table tablesorter table align-items-center table-flush"
                >
                  <thead class="thead-light">
                    <th>Data</th>
                    <th>Conta</th>
                    <th>Valor</th>
                    <th>Status</th>
                  </thead>
                  <tbody class="list">
                    <tabela-conta-cliente
                      v-for="conta in state.contas"
                      :key="conta.id"
                      :conta="conta"
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div
            class="card shadow mb-3"
            :class="type === 'dark' ? 'bg-default' : ''"
          >
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div
                  class="col"
                  style="display: flex; justify-content: space-between"
                >
                  <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                    Últimas Matrículas
                  </h3>
                  <button
                    type="button"
                    class="btn_transparente"
                    @click="modalUltimasMatriculas"
                  >
                    <i class="bi bi-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3" :class="type === 'dark' ? 'bg-default' : ''">
            <div class="col">
              <div class="table-responsive">
                <table
                  class="table tablesorter table align-items-center table-flush"
                >
                  <thead class="thead-light">
                    <th>Plano</th>
                    <th>Data Inicial</th>
                    <th>Data Final</th>
                    <th>Status</th>
                  </thead>
                  <tbody class="list">
                    <tabela-matricula-cliente
                      v-for="matricula in state.matriculas"
                      :key="matricula.id"
                      :matricula="matricula"
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div
            class="card shadow mb-3"
            :class="type === 'dark' ? 'bg-default' : ''"
          >
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div
                  class="col"
                  style="display: flex; justify-content: space-between"
                >
                  <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                    Últimas Avaliações
                  </h3>
                  <button
                    class="btn_transparente"
                    @click="modalUltimasAvaliacoes"
                  >
                    <i class="bi bi-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3" :class="type === 'dark' ? 'bg-default' : ''">
            <div class="col">
              <div class="table-responsive">
                <table
                  class="table tablesorter table align-items-center table-flush"
                >
                  <thead class="thead-light">
                    <th>Data</th>
                    <th>Instrutor</th>
                    <th>Status</th>
                  </thead>
                  <tbody class="list">
                    <tabela-avaliacao-cliente
                      v-for="avaliacao in state.avaliacoes"
                      :key="avaliacao.id"
                      :avaliacao="avaliacao"
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal
    :open="state.modal_presencas"
    :width="'800px'"
    @close="state.modal_presencas = !state.modal_presencas"
  >
    <h2>Presenças</h2>
    <div class="row"></div>
    <div class="row">
      <div class="col-md-12">
        <table class="table">
          <thead>
            <th>Data/Hora</th>
          </thead>
          <tbody>
            <tabela-presenca-cliente
              v-for="presenca in state.presencas_modal"
              :key="presenca.id"
              :presenca="presenca"
            />
          </tbody>
        </table>
      </div>
    </div>
    <button
      class="btn btn_cancelar my-4"
      id="submit-button"
      type="button"
      @click="state.modal_presencas = false"
    >
      Fechar
    </button>
  </Modal>

  <Modal
    :open="state.modal_contas"
    :width="'800px'"
    @close="state.modal_contas = !state.modal_contas"
  >
    <h2>Contas a Receber</h2>
    <div class="row"></div>
    <div class="row">
      <div class="col-md-12">
        <table class="table">
          <thead>
            <th>Data/Hora</th>
          </thead>
          <tbody>
            <tabela-presenca-cliente
              v-for="presenca in state.presencas"
              :key="presenca.id"
              :presenca="presenca"
            />
          </tbody>
        </table>
      </div>
    </div>
    <button
      class="btn btn_cancelar my-4"
      id="submit-button"
      type="button"
      @click="state.modal_contas = false"
    >
      Fechar
    </button>
  </Modal>

  <Modal
    :open="state.modal_matriculas"
    :width="'800px'"
    @close="state.modal_matriculas = !state.modal_matriculas"
  >
    <h2>Matrículas</h2>
    <div class="row"></div>
    <div class="row">
      <div class="col-md-12">
        <table class="table">
          <thead>
            <th>Data/Hora</th>
          </thead>
          <tbody>
            <tabela-presenca-cliente
              v-for="presenca in state.presencas"
              :key="presenca.id"
              :presenca="presenca"
            />
          </tbody>
        </table>
      </div>
    </div>
    <button
      class="btn btn_cancelar my-4"
      id="submit-button"
      type="button"
      @click="state.modal_matriculas = false"
    >
      Fechar
    </button>
  </Modal>

  <Modal
    :open="state.modal_avaliacoes"
    :width="'800px'"
    @close="state.modal_avaliacoes = !state.modal_avaliacoes"
  >
    <h2>Avaliações</h2>
    <div class="row"></div>
    <div class="row">
      <div class="col-md-12">
        <table class="table">
          <thead>
            <th>Data/Hora</th>
          </thead>
          <tbody>
            <tabela-avaliacao-cliente
              v-for="avaliacao in state.ultimas_avaliacoes"
              :key="avaliacao.id"
              :avaliacao="avaliacao"
            />
          </tbody>
        </table>
      </div>
    </div>
    <button
      class="btn btn_cancelar my-4"
      id="submit-button"
      type="button"
      @click="state.modal_avaliacoes = false"
    >
      Fechar
    </button>
  </Modal>
</template>
<script>
import { onMounted, reactive } from "vue";
import services from "../services";
import TabelaPresencaCliente from "../components/TabelaPresencaCliente";
import TabelaContaCliente from "../components/TabelaContaCliente";
import TabelaAvaliacaoCliente from "../components/TabelaAvaliacaoCliente";
import TabelaMatriculaCliente from "../components/TabelaMatriculaCliente";
import { useRouter } from "vue-router";
import { useStorage } from "vue3-storage";
import topo from "../components/topo";
import Modal from "../components/Modal/Modal.vue";
import Chart from "chart.js";

//  import services from '../services'

export default {
  name: "tables",
  components: {
    TabelaContaCliente,
    TabelaAvaliacaoCliente,
    TabelaMatriculaCliente,
    TabelaPresencaCliente,
    topo,
    Modal,
  },

  setup() {
    const storage = useStorage();
    const router = useRouter();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");

    const state = reactive({
      isLoading: false,
      currentClientType: "",
      hasError: false,
      cliente_id: router.currentRoute._value.params.id,
      imagem: "",
      modal_presencas: false,
      modal_contas: false,
      modal_matriculas: false,
      modal_avaliacoes: false,
    });

    async function modalUltimasPresencas() {
      const { data } = await services.presenca.porPessoa({
        token: token,
        empresa_id: empresa_id,
        pessoa_id: router.currentRoute._value.params.id,
        limite: 50,
      });
      state.presencas_modal = data;
      state.modal_presencas = true;
    }

    async function modalUltimasContas() {
      try {
        state.isLoading = true;
        const { data } = await services.contaReceber.contasPorClienteId({
          type: state.currentClientType,
          token: token,
          empresa_id: empresa_id,
          cliente_id: router.currentRoute._value.params.id,
          limit: 50,
          offset: 0,
        });
        console.log(data);
        //  console.log(data)
        state.contas = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        alert("Erro ao buscar contas!");
      }
      state.modal_contas = true;
    }

    async function modalUltimasMatriculas() {
      state.modal_matriculas = true;
    }

    async function modalUltimasAvaliacoes() {
      try {
        state.isLoading = true;
        const { data } = await services.avaliacoes.avaliacoesPorClienteId({
          token: token,
          empresa_id: empresa_id,
          cliente_id: router.currentRoute._value.params.id,
          offset: 0,
          limit: 50,
        });
        state.ultimas_avaliacoes = data;
        state.isLoading = false;
      } catch (error) {
        console.log(error);
      }
      state.modal_avaliacoes = true;
    }

    async function fetchPresencas() {
      try {
        state.isLoading = true;
        const { data } = await services.presenca.porPessoa({
          token: token,
          empresa_id: empresa_id,
          pessoa_id: router.currentRoute._value.params.id,
          limite: 5,
        });
        state.presencas = data;
        state.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchMatriculas() {
      try {
        state.isLoading = true;
        const { data } = await services.matriculas.porCliente({
          token: token,
          empresa_id: empresa_id,
          cliente_id: router.currentRoute._value.params.id,
        });
        console.log(data);
        //  console.log(data)
        state.matriculas = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchFrequenciaCliente() {
      try {
        state.isLoading = true;
        const { data } = await services.clientes.frequenciaCliente({
          token: token,
          empresa_id: empresa_id,
          id: router.currentRoute._value.params.id,
        });
        state.frequencia = data;
        console.log(state.frequencia);
        //  console.log(data)
        //  state.matriculas = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchContas() {
      try {
        state.isLoading = true;
        const { data } = await services.contaReceber.contasPorClienteId({
          type: state.currentClientType,
          token: token,
          empresa_id: empresa_id,
          cliente_id: router.currentRoute._value.params.id,
          limit: 5,
          offset: 0,
        });
        console.log(data);
        //  console.log(data)
        state.contas = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchAvaliacoes() {
      try {
        state.isLoading = true;
        const { data } = await services.avaliacoes.avaliacoesPorClienteId({
          type: state.currentClientType,
          token: token,
          empresa_id: empresa_id,
          cliente_id: router.currentRoute._value.params.id,
          limit: 5,
          offset: 0,
        });
        console.log(data);
        //  console.log(data)
        state.avaliacoes = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchCliente() {
      try {
        state.isLoading = true;
        const { data } = await services.clientes.obterPorId({
          type: state.currentClientType,
          token: token,
          empresa_id: empresa_id,
          id: state.cliente_id,
        });
        state.nome = data.nome;
        state.idade = data.idade + " anos";
        state.telefone = data.telefone;
        state.celular = data.celular;
        state.tel_emergencia = data.tel_emergencia;
        state.data_nascimento = data.data_nascimento;
        state.status = data.status;
        state.email = data.email;
        state.endereco = data.endereco;
        state.numero = data.numero;
        state.nome_cidade = data.nome_cidade;
        state.nome_estado = data.nome_estado;
        state.bairro = data.bairro;
        state.complemento = data.complemento;
        state.observacao = data.observacao;
        state.imagem = data.imagem;

        var data_cadastro = new Date(data.created_at);
        var dt_cadastro = data_cadastro.toLocaleDateString("pt-BR", {
          timeZone: "UTC",
        });
        state.data_cadastro = dt_cadastro;
        //  console.log(data)
        //   state.pagination = data.pagination
      } catch (error) {
        console.log(error);
      }
    }

    onMounted(() => {
      fetchCliente(),
        fetchMatriculas(),
        fetchContas(),
        fetchAvaliacoes(),
        fetchPresencas();
      fetchFrequenciaCliente();

      var ctx = document.getElementById("gaugeChart").getContext("2d");
      new Chart(ctx, {
        type: "doughnut",
        data: {
          datasets: [
            {
              data: [75, 25], // Valores para o medidor
              backgroundColor: [
                "rgba(37, 211, 102, 1)", // Cor do medidor (porcentagem preenchida)
                "rgba(245, 0, 0, 1)", // Cor do espaço em branco (100% - preenchido)
              ],
              borderWidth: 0,
            },
          ],
        },
        options: {
          cutoutPercentage: 70, // Espessura do medidor
          rotation: -Math.PI, // Começa a preenchê-lo do topo
          circumference: Math.PI, // Preenche metade do gráfico
          tooltips: { enabled: false },
          hover: { mode: null },
          responsive: true,
          maintainAspectRatio: false,
          legend: { display: false },
          elements: {
            arc: {
              borderWidth: 0,
            },
          },
        },
      });
    });
    /*
    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }*/

    return {
      state,
      empresa_id,
      modalUltimasPresencas,
      modalUltimasContas,
      modalUltimasMatriculas,
      modalUltimasAvaliacoes,
      pagination: {
        default: 1,
      },
    };
  },
};
</script>
<style scoped>
.btn_transparente {
  background: transparent;
  border: none;
}
.space_btn {
  text-align: right;
}
.btn_edit {
  cursor: pointer;
  border: none;
  background: transparent;
}
h4 {
  margin-bottom: 0;
  margin-top: 10px;
}
.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}
.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}
</style>