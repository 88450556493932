<template>
  <topo :titulo="'Colaboradores'"></topo>
  <div>
    <base-header type="gradient-success" class="pb-md-6 pb-md-8 pt-md-3">
      <div class="row">
        <div class="col-12 d-md-none">
          <router-link to="/adicionar-colaborador" class="primary">
            <!-- v-if="array_permissoes.includes('51')" -->
            <button
              type="button"
              id="btn_new_product"
              class="btn_add_colaborador"
            >
              <i class="bi bi-plus i"></i> Novo Colaborador
            </button>
          </router-link>
          <!--
          <button
            v-else
            disabled
            type="button"
            id="btn_new_product"
            class="btn_add_colaborador"
          >
            <i class="bi bi-plus i"></i> Novo Colaborador
          </button>-->
        </div>
        <div class="col-lg-2 col-md-3 mt-sm-2 mt-2">
          <input
            type="text"
            class="form-control"
            placeholder="Código"
            v-model="state.codigo"
          />
        </div>
        <div class="col-lg-4 col-md-6 mt-sm-2 mt-2">
          <input
            type="text"
            class="form-control"
            v-model="state.nome_busca"
            placeholder="Nome"
          />
        </div>
        <div class="col-lg-2 col-md-3 mt-sm-2 mt-2">
          <base-button type="button" class="btn_busca" @click="buscarPorNome()"
            >Buscar</base-button
          >
        </div>
        <div
          class="d-none d-md-block col-lg-4 col-xl-3 offset-xl-1 col-md-6 offset-lg-0 offset-md-6 mt-md-2 mt-sm-2 mt-2"
        >
          <router-link to="/adicionar-colaborador" class="primary"
            ><!-- v-if="!array_permissoes.includes('51')" -->
            <button
              type="button"
              id="btn_new_product"
              class="btn_add_colaborador"
            >
              <i class="bi bi-plus i"></i> Adicionar Colaborador
            </button>
          </router-link>
          <!--
          <button
            type="button"
            id="btn_new_product"
            disabled
            class="btn_add_colaborador"
            v-else
          >
            <i class="bi bi-plus i"></i> Adicionar Colaborador
          </button>-->
        </div>
      </div>
      <!-- Card stats -->
      <div class="row d-none d-md-block">
        <div class="col-xl-3 col-lg-6 mt-lg-4 mt-md-4 mt-2">
          <card class="card-stats">
            <i class="bi bi-people-fill i_quad"></i>
            <p class="title1">Colaboradores Cadastrados</p>
            <div class="num">{{ state.colaboradoresQtd }}</div>
            <div class="conteudo">
              <!-- <span class="arow"><i class="fa fa-arrow-up"></i> 12.18%</span>
              <span class="text1">Nos últimos 30 dias</span> -->
            </div>
          </card>
        </div>
        <div class="col-xl-3 col-lg-6 mt-lg-4" v-if="ativoColab">
          <card class="card-stats">
            <i class="bi bi-people-fill i_quad"></i>

            <p class="title2">Colaboradores Ativos</p>
            <div class="num">{{ state.colaboradoresAtivos }}</div>
            <div class="conteudo">
              <!--     <span class="arow"><i class="fa fa-arrow-up"></i> 12.18%</span> -->
            </div>
          </card>
        </div>
        <div class="col-xl-3 col-lg-6 mt-lg-4" v-if="personais">
          <card class="card-stats">
            <i class="bi bi-people-fill i_quad"></i>
            <p class="title2">Personais</p>
            <div class="num">{{ state.personaisAtivos }}</div>
            <div class="conteudo">
              <!-- <span class="arow"><i class="fa fa-arrow-up"></i> 12.18%</span> -->
            </div>
          </card>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt-md--7">
      <div
        class="card shadow p-3"
        :class="type === 'dark' ? 'bg-default' : ''"
        s
      >
        <div class="row">
          <div class="col">
            <div class="table-responsive">
              <colaborador-loading v-if="state.isLoading" />
              <table
                v-else
                class="table tablesorter table align-items-center table-flush"
              >
                <thead class="thead-light">
                  <th>Código</th>
                  <th>Nome</th>
                  <th>Status</th>
                  <th class="th_dipslay">ID Biometria / Dias Última Visita</th>
                  <th></th>
                </thead>
                <tbody class="list">
                  <corpo-tabela-colaborador
                    v-for="colaborador in state.colaboradores"
                    :key="colaborador.id"
                    :colaborador="colaborador"
                    @deletarColaborador="deletarColaborador"
                    @modalFuncao="modalFuncao"
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    :open="state.statusModalFuncao"
    :width="'800px'"
    @close="state.statusModalFuncao = !state.statusModalFuncao"
  >
    <h1>Gerenciar Funções</h1>
    <div class="row">
      <div class="col-sm-5">
        <select class="form-control" v-model="state.funcao_id">
          <option
            v-for="funcao in state.funcoes_empresa"
            :key="funcao"
            :value="funcao.id"
          >
            {{ funcao.nome }}
          </option>
        </select>
      </div>
      <div class="col-sm-2">
        <button
          class="btn btn_ok"
          id="submit-button"
          :disabled="state.isLoading"
          type="button"
          @click="adicionarFuncao"
        >
          <i class="bi bi-check-lg"></i>
        </button>
      </div>
      <div class="col-sm-2"></div>
    </div>

    <table class="table">
      <thead>
        <th>Código</th>
        <th>Nome</th>
        <th>Ação</th>
      </thead>
      <tbody>
        <tr v-for="funcao in state.funcoes_carregadas" :key="funcao.id">
          <td>{{ funcao.id }}</td>
          <td>{{ funcao.nome }}</td>
          <td>
            <button
              class="btn btn_deletar"
              title="Deletar"
              @click="deletarFuncao(funcao.id)"
            >
              <i class="bi bi-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div style="display: flex; justify-content: flex-end">
      <button
        class="btn btn_cancelar"
        id="submit-button"
        type="button"
        @click="fecharModalFuncao"
      >
        Fechar
      </button>
    </div>
  </Modal>
</template>
<script>
import { onMounted, reactive, onErrorCaptured } from "vue";
import CorpoTabelaColaborador from "../components/CorpoTabelaColaborador";
import ColaboradorLoading from "../components/CorpoTabelaCliente/Loading";
import services from "../services";
import { useStorage } from "vue3-storage";
import Modal from "../components/Modal/Modal.vue";
import topo from "../components/topo";
export default {
  name: "tables",
  components: {
    CorpoTabelaColaborador,
    ColaboradorLoading,
  },

  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");
    var permissoes = storage.getStorageSync("permissoes");

    var array_permissoes = [];
    for (let i = 0; i < permissoes.length; i++) {
      const element = permissoes[i];
      array_permissoes.push(element.id);
    }

    const state = reactive({
      //card-stats
      cadadastoColab: false,
      ativoColab: false,
      personais: false,
      isOpenVisualizar: false,
      statusModalFuncao: false,
      isLoading: false,
      codigo: "",
      nome_busca: "",
      colaboradores: [],
      currentClientType: "",
      hasError: false,
      qtd_por_pagina: 50,
      pagina_selecionada: 1,
      qtd_paginacao: 10,
      qtd_anterior: 0,
    });
    onErrorCaptured(handleErrors);

    onMounted(() => {
      buscarTodasFuncoes();
      buscarTodos();
      personaisAtivos();
      colaboradoresAtivos();
      colaboradoresQtd();
    });

    function fecharModalFuncao() {
      state.statusModalFuncao = false;
    }

    async function carregarFuncoesColaborador(colaborador_id) {
      try {
        const { data } = await services.funcao.porColaborador({
          token: token,
          empresa_id: empresa_id,
          colaborador_id,
        });
        state.funcoes_carregadas = data;
      } catch (error) {
        handleErrors(error);
      }
      state.statusModalFuncao = true;
    }

    async function modalFuncao(colaborador_id) {
      await carregarFuncoesColaborador(colaborador_id);

      //  console.log(data)
      state.colaborador_selecionado = colaborador_id;
      state.isLoading = false;

      state.statusModalFuncao = true;
    }

    async function adicionarFuncao() {
      try {
        state.isLoading = true;
        await services.colaboradores.salvarFuncaoColaborador({
          token: token,
          empresa_id: empresa_id,
          funcao_id: state.funcao_id,
          colaborador_id: state.colaborador_selecionado,
        });
        carregarFuncoesColaborador(state.colaborador_selecionado);
        // state.funcoes_carregadas = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function deletarFuncao(id) {
      try {
        state.isLoading = true;
        await services.colaboradores.deletarFuncaoColaborador({
          id,
          empresa_id,
          token,
        });
        carregarFuncoesColaborador(state.colaborador_selecionado);
      } catch (error) {
        console.log(error);
      }
    }

    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }

    async function buscarTodasFuncoes() {
      try {
        state.isLoading = true;
        const { data } = await services.funcao.getAll({
          token: token,
          empresa_id: empresa_id,
        });
        console.log(data);
        //  console.log(data)
        state.funcoes_empresa = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function buscarTodos() {
      try {
        state.isLoading = true;
        const { data } = await services.colaboradores.getAll({
          token: token,
          empresa_id: empresa_id,
        });
        console.log(data);
        //  console.log(data)
        state.colaboradores = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function buscarPorNome() {
      if (state.codigo.length > 0) {
        try {
          const { data } = await services.colaboradores.obterPorId({
            token: token,
            empresa_id: empresa_id,
            id: state.codigo,
          });
          var array = [];
          array.push(data);
          state.colaboradores = array;
        } catch (error) {
          handleErrors(error);
        }
      } else {
        try {
          state.isLoading = true;
          const { data } = await services.colaboradores.colaboradoresPorNome({
            type: state.currentClientType,
            token: token,
            empresa_id: empresa_id,
            nome: state.nome_busca,
          });
          console.log(data);
          //  console.log(data)
          state.colaboradores = data;
          //   state.pagination = data.pagination
          state.isLoading = false;
        } catch (error) {
          handleErrors(error);
        }
      }
    }
    async function deletarColaborador(id) {
      if (confirm("Deseja deletar este colaborador?")) {
        try {
          state.isLoading = true;
          const { data } = await services.colaboradores.deletarColaborador({
            token: token,
            empresa_id: empresa_id,
            id,
          });
          console.log(data);
          if (data) {
            buscarTodos();
          } else {
            alert("Erro ao deletar colaborador.");
          }
        } catch (error) {
          handleErrors(error);
        }
      }
    }
    async function personaisAtivos() {
      try {
        const personaisAtivos = await services.colaboradores.personaisAtivos({
          token: token,
          empresa_id: empresa_id,
        });
        state.personaisAtivos = personaisAtivos.data;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function colaboradoresAtivos() {
      try {
        const colaboradoresAtivos =
          await services.colaboradores.colaboradoresAtivos({
            token: token,
            empresa_id: empresa_id,
          });
        state.colaboradoresAtivos = colaboradoresAtivos.data;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function colaboradoresQtd() {
      try {
        const colaboradoresQtd = await services.colaboradores.colaboradoresQtd({
          token: token,
          empresa_id: empresa_id,
        });
        state.colaboradoresQtd = colaboradoresQtd.data;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function selecionarPagina(n_pagina) {
      try {
        var qtd_anterior = (n_pagina - 1) * state.qtd_por_pagina;

        state.isLoading = true;
        var retorno = await services.clientes.getAll({
          type: state.currentClientType,
          token: token,

          empresa_id: empresa_id,
          quantidade: state.qtd_por_pagina,
          quantidade_anterior: qtd_anterior,
        });
        console.log(retorno);
        state.clientes = retorno.data;
        //   state.pagination = data.pagination
        state.isLoading = false;
        state.pagina_selecionada = n_pagina;
      } catch (error) {
        handleErrors(error);
      }
    }
    return {
      state,
      buscarTodos,
      selecionarPagina,
      deletarColaborador,
      buscarPorNome,
      Modal,
      modalFuncao,
      array_permissoes,
      fecharModalFuncao,
      adicionarFuncao,
      deletarFuncao,
      topo,
    };
  },
};
</script>
<style scoped>
.bv {
  font-weight: bold;
  font-size: 18pt;
  color: #162b48;
  margin-bottom: 0;
}
.btn_ok {
  color: #ffff;
  background: #034aa6;
  padding: 7px 15px;
}

.btn_ok i {
  font-size: 14pt;
}

.btn_ok {
  transform: none;
  color: #fff;
}
.btn_deletar {
  padding: 4px;
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
  border-radius: 5px;
  margin-right: 10px;
  transform: none;
}
.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}
.btn_salvar:hover {
  transform: none;
  color: #fff;
}
.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}
.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}
.title2 {
  color: #000;
  margin-top: 10px;
}
.title1 {
  color: #000;
  margin-top: 10px;
  font-size: 11pt;
  line-height: 1;
}
.arow {
  margin-right: 5px;
  font-size: 0.7rem !important;
  color: #034aa6;
  font-weight: 700;
}
.text1 {
  color: #034aa6;
  font-size: 7pt !important;
  font-weight: bold;
}
.num {
  margin-top: -15px;
}
.card .table th {
  padding: 15px !important;
}
.btn_busca {
  font-size: 10pt;
  height: 40px;
  border-radius: 5px;
  background-color: rgb(3, 74, 166);
  border: none;
  color: #fff;
  font-weight: 700;
  width: 100%;
}
.btn_busca:hover {
  transform: none;
}
.div_btn_buscar {
  margin-left: -20px;
}
.btn_add_colaborador:hover {
  transform: none;
}
.btn_add_colaborador {
  transform: none;
  background: var(--Color-secundary, #aede00);
  color: rgb(255, 255, 255);
  border: 1px solid #aede00;
  border-radius: 5px;
  font-size: 9pt;
  padding: 5px;
  font-weight: 700;
  width: 100%;
  margin-top: 2px;
}
.page-item.active .page-link {
  border: #034aa6 solid 1px;
  background-color: #034aa6;
}

.i_quad {
  background: #034aa6;
  padding: 10px;
  border-radius: 100px;
  font-size: 16pt;
  color: #fff;
}
.i {
  font-size: 12pt;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .btn_add_colaborador {
    font-size: 8pt;
    width: 100%;
    padding: 9px;
  }
  .th_dipslay {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .th_dipslay {
    display: none;
  }
}
</style>