<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar :background-color="sidebarBackground">
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: 'Início',
            icon: 'ni ni-tv-2 text-default',
            path: '/dashboard',
          }"
        />
        <!--
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-default',
            path: '/dash',
          }"
        />-->
        <sidebar-item
          :link="{
            name: 'Avaliações',
            icon: 'ni ni-collection text-default',
            path: '/avaliacoes',
            disabled: !this.permissao_avaliacao,
          }"
        />
        <sidebar-item
          :link="{
            name: 'Clientes',
            icon: 'ni ni-single-02 text-default',
            path: '/clientes',
            disabled: !this.permissao_cliente,
          }"
        />
        <sidebar-item
          :link="{
            name: 'Colaboradores',
            icon: 'ni ni-single-02 text-default',
            path: '/colaboradores',
            disabled: !this.permissao_colaborador,
          }"
        />
        <sidebar-item
          :link="{
            name: 'Fornecedores',
            icon: 'ni ni-single-02 text-default',
            path: '/fornecedores',
            disabled: !this.permissao_fornecedor,
          }"
        />
        <sidebar-item
          :link="{
            name: 'Matrículas',
            icon: 'ni ni-bullet-list-67 text-default',
            path: '/matriculas',
            disabled: !this.permissao_matricula,
          }"
        />
        <!--
        <sidebar-item
          :link="{
            name: 'Contas a Pagar',
            icon: 'ni ni-money-coins text-default',
            path: '/contas-pagar',
          }"
        />-->
        <sidebar-item
          :link="{
            name: 'Contas a Receber',
            icon: 'ni ni-money-coins text-default',
            path: '/contas-receber',
            disabled: !this.permissao_conta_receber,
          }"
        />

        <sidebar-item
          :link="{
            name: 'Contas a Pagar',
            icon: 'ni ni-money-coins text-default',
            path: '/contas-pagar',
            disabled: !this.permissao_conta_pagar,
          }"
        />

        <sidebar-item
          :link="{
            name: 'Caixa',
            icon: 'ni ni-money-coins text-default',
            path: '/caixas',
            disabled: !this.permissao_caixa,
          }"
        />

        <sidebar-item
          :link="{
            name: 'Fichas de Treino',
            icon: 'ni ni-bullet-list-67 text-default ',
            path: '/fichas-treino',
            disabled: !this.permissao_ficha,
          }"
        />

        <sidebar-item
          :link="{
            name: 'Recorrencias',
            icon: 'ni ni-bullet-list-67 text-default ',
            path: '/recorrencias',
            disabled: true,
          }"
        />

        <sidebar-item
          :link="{
            name: 'Banco Recorrente',
            icon: 'ni ni-bullet-list-67 text-default ',
            path: '/banco-recorrente',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Aplicativo',
            icon: 'ni ni-mobile-button text-default ',
            path: '/aplicativo',
          }"
        />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { useStorage } from "vue3-storage";
export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },

  setup() {},
  mounted() {
    //  console.log(this.permissoes);
  },
  data() {
    const storage = useStorage();
    this.token = storage.getStorageSync("token");
    this.empresa_id = storage.getStorageSync("empresa_id");
    this.permissoes = storage.getStorageSync("permissoes");

    //Avaliações
    const permissao_avaliacao = this.permissoes.filter(
      (permissao) => permissao.id == 37
    );
    if (permissao_avaliacao.length > 0) {
      this.permissao_avaliacao = true;
    } else {
      this.permissao_avaliacao = false;
    }

    //Clientes
    const permissao_cliente = this.permissoes.filter(
      (permissao) => permissao.id == 32
    );
    if (permissao_cliente.length > 0) {
      this.permissao_cliente = true;
    } else {
      this.permissao_cliente = false;
    }

    //Clientes
    const permissao_matricula = this.permissoes.filter(
      (permissao) => permissao.id == 9
    );
    if (permissao_matricula.length > 0) {
      this.permissao_matricula = true;
    } else {
      this.permissao_matricula = false;
    }

    //Colaboradores
    const permissao_colaborador = this.permissoes.filter(
      (permissao) => permissao.id == 10
    );
    if (permissao_colaborador.length > 0) {
      this.permissao_colaborador = true;
    } else {
      this.permissao_colaborador = false;
    }

    //Fornecedores
    const permissao_fornecedor = this.permissoes.filter(
      (permissao) => permissao.id == 11
    );
    if (permissao_fornecedor.length > 0) {
      this.permissao_fornecedor = true;
    } else {
      this.permissao_fornecedor = false;
    }

    //Contas a Receber
    const permissao_conta_receber = this.permissoes.filter(
      (permissao) => permissao.id == 2
    );
    if (permissao_conta_receber.length > 0) {
      this.permissao_conta_receber = true;
    } else {
      this.permissao_conta_receber = false;
    }

    //Contas a Pagar
    const permissao_conta_pagar = this.permissoes.filter(
      (permissao) => permissao.id == 3
    );
    if (permissao_conta_pagar.length > 0) {
      this.permissao_conta_pagar = true;
    } else {
      this.permissao_conta_pagar = false;
    }

    //Caixas
    const permissao_caixa = this.permissoes.filter(
      (permissao) => permissao.id == 4
    );
    if (permissao_caixa.length > 0) {
      this.permissao_caixa = true;
    } else {
      this.permissao_caixa = false;
    }

    //Fichas
    const permissao_ficha = this.permissoes.filter(
      (permissao) => permissao.id == 4
    );
    if (permissao_ficha.length > 0) {
      this.permissao_ficha = true;
    } else {
      this.permissao_ficha = false;
    }

    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
