<template>
  <section id="sct_login">
    <div class="container">
      <div
        class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12 offset-lg-3 tamanho_quad"
      >
        <button class="btn2" type="button" @click="irParaHome">
          <span>Voltar</span>
        </button>
        <div class="quad">
          <div style="position: relative; text-align: center">
            <div class="div_erro" v-if="state.erro_login == true">
              <p class="text-danger">
                O nome de usuário e senha não correspondem.
              </p>
            </div>
          </div>

          <img class="logo1" src="../../assets/img_new/logo2.png" alt="Sifit" />
          <form>
            <div class="div_login">
              <label for="login">Login</label>
              <input type="text" id="login" v-model="state.login" />
            </div>
            <div class="div_senha">
              <label for="senha">Senha</label>
              <input type="password" id="senha" v-model="state.password" />
            </div>

            <div class="row">
              <div class="col-lg-6">
                <div class="check">
                  <input
                    type="checkbox"
                    id="manter_conectodo"
                    name="manter_conectodo"
                  />
                  <label for="manter_conectodo">Manter conectado</label>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="minha_senha">
                  <a href="#">Esqueci minha senha</a>
                </div>
              </div>
            </div>
            <button class="btn" type="button" @click="handleSubmit">
              <span>Entrar</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { reactive } from "vue";
import services from "../../services";
import { useRouter } from "vue-router";
import { useStorage } from "vue3-storage";
export default {
  setup() {
    const router = useRouter();
    const storage = useStorage();

    const state = reactive({
      erro_login: false,
      hasErrors: false,
      isLoading: false,
      login: "",
      password: "",
    });

    async function handleSubmit() {
      try {
        state.isLoading = true;
        const { data } = await services.auth.login({
          login: state.login,
          password: state.password,
        });
        if (data.jwt) {
          storage.setStorageSync("token", data.jwt);
          storage.setStorageSync("empresa_id", data.empresa_id);
          storage.setStorageSync("nome_pessoa", data.nome_pessoa);
          storage.setStorageSync("colaborador_id", data.funcionario_id);
          storage.setStorageSync("nome_empresa", data.nome_empresa);
          storage.setStorageSync("permissoes", data.permissoes);
          storage.setStorageSync('pais_id', data.pais_id);
          storage.setStorageSync('cidade_id', data.cidade_id);
          storage.setStorageSync('estado_id', data.estado_id);
          storage.setStorageSync('foto', data.imagem);
          router.push({ name: "dashboard" });
        } else {
          state.erro_login = true;
        }
      } catch (error) {
        console.log(error);
        state.isLoading = false;
        state.hasErrors = !!error;
      }
    }

    function irParaHome() {
      router.push({ name: "homeSite" });
    }

    return {
      handleSubmit,
      irParaHome,
      state,
    };
  },
};
</script>

<style scoped>
#sct_login {
  background-image: url("../../assets/img_new/groundImg.png");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
img {
  margin-top: 10px;
}
.quad {
  position: relative;
  text-align: center;
  padding: 20px;
  height: 500px;
  background: linear-gradient(
    179.97deg,
    rgba(255, 255, 255, 0.75) 0.03%,
    rgba(255, 255, 255, 0) 118.95%
  );
  backdrop-filter: blur(13.5px);
  border-radius: 44px 0px;
}
.div_login {
  margin-top: 40px;
}
.div_erro {
  margin-top: 68px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
.btn {
  border-radius: 8px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  border: #aede00 solid 1px;
  color: #000d1f;
  background-color: #aede00;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  margin-top: 45px;
}
.btn span {
  position: relative;
  transition: 0.2s;
}
.btn:hover {
  background-color: #aede00;
  border: #000d1f solid 1px;
  color: #000d1f;
}
.btn span:after {
  margin-top: -8px;
  content: "\261B	";
  position: absolute;
  opacity: 0;
  right: -10px;
  transition: 0.2s;
  font-size: x-large;
}
.btn:hover span {
  padding-right: 15px;
  margin-right: 15px;
}
.btn:hover span:after {
  opacity: 1;
  right: 1;
}
form {
  width: 100%;
}

.text-danger {
  color: #c62323 !important;
  background-color: #fff;
  border-radius: 10px;
  display: inline-block;
  padding: 5px 10px;
}

form div + div {
  margin-top: 15px;
}
.div_login label,
.div_senha label {
  width: 100%;
  color: #1d1d1d;
  text-align: left;
}
input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: #1d1d1d solid 1px;
  border-radius: 10px;
  background: transparent;
  color: black;
  resize: none;
  outline: none;
}
.tamanho_quad {
  padding: 50px;
}
a {
  color: #aede00;
}
a:hover {
  color: #000d1f;
}
.btn2 {
  margin-left: -385px;
  border: transparent solid 1px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  color: #aede00;
  background-color: transparent;
  cursor: pointer;
  padding: 10px;
  width: 30%;
  margin-bottom: 0px;
}
.btn2 span {
  position: relative;
  transition: 0.2s;
}
.btn2 span:after {
  margin-top: -8px;
  content: "\2190";
  position: absolute;
  opacity: 0;
  left: -20px;
  transition: 0.2s;
  font-size: x-large;
}
.btn2:hover span {
  padding-right: 15px;
  margin-right: 15px;
}
.btn2:hover span:after {
  opacity: 1;
  right: 1;
}
.check {
  margin-top: 15px;
  color: #000d1f;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .btn2 {
    margin-left: -250px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .div_erro {
    width: 100%;
    font-size: 10px;
  }
  .btn2 {
    margin-left: -195px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .div_erro {
    width: 100%;
  }
  .btn2 {
    margin-left: 0px;
  }
}
@media (max-width: 575px) {
  .div_erro {
    width: 100%;
    margin-left: 0;
    font-size: 10px;
    padding: 3px;
  }
  .btn2 {
    margin-left: 0px;
  }
}
</style>