<template>
  <div class="topo">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-3">
          <p class="bv" v-if="state.idioma == 'PT'">Bem Vindo</p>
          <p class="bv" v-else>Bienvenido</p>
          <p class="data">{{ data_atual }}</p>
        </div>
        <div class="col-6 col-lg-2 div_datas">
          <label v-if="state.idioma == 'PT'">Data Inicial</label>
          <label v-else>La Fecha de Inicio</label>
          <!-- v-model="state.data_inicial" -->
          <Datepicker
            v-model="state.data_inicial"
            :locale="pt"
            inputFormat="dd/MM/yyyy"
            style="
              color: #505050;
              border-radius: 5px;
              border: #e7e7e7 solid 1px;
              transition: 0.5s;
              width: 100%;
              padding: 5px;
            "
          />
        </div>
        <div class="col-6 col-lg-2 div_datas">
          <label v-if="state.idioma == 'PT'">Data final</label>
          <label v-else>Fecha Final</label>
          <Datepicker
            v-model="state.data_final"
            :locale="pt"
            inputFormat="dd/MM/yyyy"
            style="
              color: #505050;
              border-radius: 5px;
              border: #e7e7e7 solid 1px;
              transition: 0.5s;
              width: 100%;
              padding: 5px;
            "
          />
        </div>
        <div class="col-12 col-lg-2">
          <button type="button" class="btn_buscar" @click="dadosPrincipais()">
            Buscar
          </button>
        </div>
        <div class="col-lg-3 div_pessoa d-none d-md-block">
          <div class="div_imagem" v-if="foto != undefined && foto != null">
            <img :src="foto" />
          </div>
          <div class="div_imagem" v-else>
            <img src="/img/user_sifit.png" />
          </div>
          <div class="nome_usuario">{{ state.nome_pessoa }}</div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="pb-6 pb-8 px-3" style="padding-top: 20px">
      <div class="row">
        <div class="col-xl-3 col-sm-6">
          <card class="card-stats">
            <a href="#">
              <div class="card-flex">
                <div>
                  <img
                    class="icon"
                    src="/img/icons/clientes_ativos.png"
                    alt="Finanças"
                  />
                </div>
                <div class="card_dir">
                  <p class="avaliacao">{{ state.qtdClientesAtivos }}</p>
                  <div class="num" v-if="state.idioma == 'PT'">
                    Clientes Ativos
                  </div>
                  <div class="num" v-else>Clientes Activos</div>
                </div>
              </div>
            </a>
          </card>
        </div>
        <div class="col-xl-3 col-sm-6">
          <card class="card-stats">
            <a @click="modalClienteAusente">
              <div class="card-flex">
                <div>
                  <img
                    class="icon"
                    src="/img/icons/clientes_ativos.png"
                    alt="Finanças"
                  />
                </div>
                <div class="card_dir">
                  <p class="avaliacao">{{ state.qtdClientesAusentes }}</p>
                  <div class="num">Clientes Ausentes</div>
                </div>
              </div>
            </a>
          </card>
        </div>
        <div class="col-xl-3 col-sm-6">
          <card class="card-stats">
            <a @click="modalClienteSemMatricula()">
              <div class="card-flex">
                <img
                  class="icon"
                  src="/img/icons/sem_matriculas.png"
                  alt="Finanças"
                />
                <div class="card_dir">
                  <p class="avaliacao">{{ state.qtdClientesSemMatricula }}</p>
                  <div class="num" v-if="state.idioma == 'PT'">
                    Sem Matrículas
                  </div>
                  <div class="num" v-else>Sin Matrículas</div>
                </div>
              </div>
            </a>
          </card>
        </div>
        <div class="col-xl-3 col-sm-6">
          <card class="card-stats">
            <a href="#" @click="modalPersonais">
              <div class="card-flex">
                <img
                  class="icon"
                  src="/img/icons/personais.png"
                  alt="Finanças"
                />
                <div class="card_dir">
                  <p class="avaliacao">{{ state.personaisAtivos.length }}</p>
                  <div class="num" v-if="state.idioma == 'PT'">Personais</div>
                  <div class="num" v-else>Entrenadores personales</div>
                </div>
              </div>
            </a>
          </card>
        </div>
        <div class="col-lg-12 col-xl-9 col-sm-12">
          <div class="row">
            <div class="col-xl-4 col-sm-6">
              <card class="card-stats">
                <a href="#" @click="modalMatriculasNoPeriodo()">
                  <div class="card-flex">
                    <div>
                      <img
                        class="icon"
                        src="/img/icons/matriculas_mes.png"
                        alt="Finanças"
                      />
                    </div>
                    <div class="card_dir">
                      <div></div>
                      <div></div>
                      <p class="avaliacao">
                        {{ state.dados_busca.matriculas }}
                      </p>
                      <div class="num" v-if="state.idioma == 'PT'">
                        Matrículas no Período
                      </div>
                      <div class="num" v-else>Inscripción en el Período</div>
                    </div>
                  </div>
                </a>
              </card>
            </div>
            <div class="col-xl-4 col-sm-6">
              <a href="#">
                <card class="card-stats">
                  <div class="card-flex">
                    <div>
                      <img
                        class="icon"
                        src="/img/icons/desistencias.png"
                        alt="Finanças"
                      />
                    </div>
                    <div class="card_dir">
                      <p class="avaliacao">0</p>
                      <div class="num" v-if="state.idioma == 'PT'">
                        Desistências no Período
                      </div>
                      <div class="num" v-else>Retiros en el Período</div>
                    </div>
                  </div>
                </card>
              </a>
            </div>
            <div class="col-xl-4 col-lg-6">
              <a href="#">
                <card class="card-stats">
                  <div class="card-flex">
                    <div>
                      <img
                        class="icon"
                        src="/img/icons/renovacoes.png"
                        alt="Finanças"
                      />
                    </div>
                    <div class="card_dir">
                      <p class="avaliacao">{{ state.qtdClientesAtivos }}</p>
                      <div class="num" v-if="state.idioma == 'PT'">
                        Renovações
                      </div>
                      <div class="num" v-else>Renovaciones</div>
                    </div>
                  </div>
                </card>
              </a>
            </div>
          </div>

          <div class="row div_grafico">
            <div class="col-xl-7 col-lg-7">
              <card type="default" header-classes="bg-transparent">
                <template v-slot:header>
                  <div class="row align-items-center">
                    <div class="col" v-if="state.idioma == 'PT'">
                      <h6 class="text-light text-uppercase ls-1 mb-1">
                        Movimento por Horário
                      </h6>
                      <h5 class="h3 text-white mb-0">Por Horário</h5>
                    </div>
                    <div class="col" v-else>
                      <h6 class="text-light text-uppercase ls-1 mb-1">
                        Movimiento de Gimnasio
                      </h6>
                      <h5 class="h3 text-white mb-0">A Tiempo</h5>
                    </div>
                  </div>
                </template>
                <div class="chart-area">
                  <canvas :height="250" id="chartHorarios"></canvas>
                </div>
              </card>
            </div>
            <div class="col-xl-5 col-lg-5">
              <card type="default" header-classes="bg-transparent">
                <template v-slot:header>
                  <div class="row align-items-center">
                    <div class="col" v-if="state.idioma == 'PT'">
                      <h6 class="text-light text-uppercase ls-1 mb-1">
                        Movimento por dia
                      </h6>
                      <h5 class="h3 text-white mb-0">Por Horário</h5>
                    </div>
                    <div class="col" v-else>
                      <h6 class="text-light text-uppercase ls-1 mb-1">
                        Movimiento de Gimnasio
                      </h6>
                      <h5 class="h3 text-white mb-0">A Tiempo</h5>
                    </div>
                  </div>
                </template>
                <div class="chart-area">
                  <canvas :height="250" id="chartDias"></canvas>
                </div>
              </card>
            </div>
          </div>

          <div class="row d-none d-sm-block">
            <div class="col-xl-12 col-lg-12">
              <card
                class="card-stats"
                style="font-size: 32pt; margin-top: 20px"
              >
                <p v-if="state.idioma == 'PT'">Últimas entradas na academia</p>
                <p v-else>Últimas entradas al gimnasio</p>
                <table class="table" style="width: 100%">
                  <thead>
                    <th v-if="state.idioma == 'PT'">Nome</th>
                    <th v-else>Nombre</th>
                    <th>Hora</th>
                  </thead>
                  <tbody>
                    <tr v-for="presenca in state.presencas" :key="presenca.id">
                      <td>{{ presenca.nome }}</td>
                      <td>{{ presenca.horario }}</td>
                    </tr>
                  </tbody>
                </table>
              </card>
            </div>
          </div>
          <div class="row d-block d-sm-none">
            <div class="col-xl-12 col-lg-12">
              <card
                class="card-stats"
                style="font-size: 32pt; margin-top: 20px"
              >
                <p v-if="state.idioma == 'PT'">Últimas entradas na academia</p>
                <p v-else>Últimas entradas al gimnasio</p>
                <table class="table" style="width: 100%">
                  <thead>
                    <th v-if="state.idioma == 'PT'">Nome</th>
                    <th v-else>Nombre</th>
                  </thead>
                  <tbody>
                    <tr v-for="presenca in state.presencas" :key="presenca.id">
                      <td>
                        <b>{{ presenca.nome }}</b>
                        <br />{{ presenca.horario }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </card>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-12">
          <card class="card-stats" style="font-size: 32pt">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 10px;
              "
            >
              <img src="/img/icons/info.png" />
              <div
                v-if="state.idioma == 'PT'"
                style="
                  margin-left: 10px;
                  color: var(--color-azul-2, #162b48);
                  font-weight: 600;
                "
              >
                Mensalidades pendentes
              </div>
              <div
                v-else
                style="
                  margin-left: 10px;
                  color: var(--color-azul-2, #162b48);
                  font-weight: 600;
                "
              >
                Pagos mensuales pendientes
              </div>
            </div>
            <div
              v-if="state.loadContasVencidas == true"
              style="margin-top: 30px; width: 100%; text-align: center"
            >
              <img src="/img/spinner.gif" style="width: 60px" />
            </div>

            <table class="table" style="width: 100%" v-else>
              <tbody>
                <tr
                  v-for="(pendente, i) in state.pendentes"
                  :key="pendente.id"
                  style="padding-bottom: 5px"
                >
                  <td v-if="i <= 12" style="padding: 12px 0px">
                    <img src="/img/user_sifit.png" />
                  </td>
                  <td v-if="i <= 12" style="padding: 8px 0px">
                    <div style="font-size: 12px">
                      {{ pendente.nome_cliente }}
                    </div>
                    <div>{{ pendente.data_vencimento }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </card>
        </div>
      </div>
    </div>
  </div>
  <Modal
    :width="'800px'"
    :open="state.status_modal_ausentes"
    @close="state.status_modal_ausentes = !state.status_modal_ausentes"
  >
    <h2>Clientes Ausentes</h2>
    <table class="table tabela_ausentes">
      <thead v-if="state.idioma == 'PT'">
        <th>Código</th>
        <th>Nome</th>
        <th>Dias Última Visita</th>
        <th>Data Última Visita</th>
      </thead>
      <thead v-else>
        <th>Código</th>
        <th>Nombre</th>
        <th>Dias Última Visita</th>
        <th>Data Última Visita</th>
      </thead>
      <tbody>
        <tr v-for="ausente in state.clientes_ausentes" :key="ausente.id">
          <td>{{ ausente.id }}</td>
          <td>{{ ausente.nome }}</td>
          <td>{{ ausente.dias_ultima_visita }}</td>
          <td>{{ ausente.data_ultima_visita }}</td>
        </tr>
      </tbody>
    </table>
    <div>
      <button
        class="btn btn-primary"
        @click="state.status_modal_ausentes = false"
      >
        Fechar
      </button>
    </div>
  </Modal>

  <Modal
    :open="state.status_modal_sem_matricula"
    @close="
      state.status_modal_sem_matricula = !state.status_modal_sem_matricula
    "
  >
    <h2>Clientes Sem Matrículas</h2>
    <table class="table tabela_sem_matriculas">
      <thead>
        <th>Código</th>
        <th>Nome</th>
        <th>Dias Última Visita</th>
        <th>Data Última Visita</th>
      </thead>
      <tbody>
        <tr v-for="sem_mat in state.clientes_sem_matricula" :key="sem_mat.id">
          <td>{{ sem_mat.id }}</td>
          <td>{{ sem_mat.nome }}</td>
          <td>{{ sem_mat.dias_ultima_visita }}</td>
          <td>{{ sem_mat.data_ultima_visita }}</td>
        </tr>
      </tbody>
    </table>
    <div>
      <button
        class="btn btn-primary"
        @click="state.status_modal_sem_matricula = false"
      >
        Fechar
      </button>
    </div>
  </Modal>

  <Modal
    :open="state.status_modal_personais"
    @close="state.status_modal_personais = !state.status_modal_personais"
  >
    <h2>Personais</h2>
    <table class="table">
      <thead>
        <th>Código</th>
        <th>Nome</th>
        <th>Dias Última Visita</th>
        <th>Data Última Visita</th>
      </thead>
      <tbody>
        <tr v-for="personal in state.personaisAtivos" :key="personal.id">
          <td>{{ personal.id }}</td>
          <td>{{ personal.nome }}</td>
          <td>{{ personal.dias_ultima_visita }}</td>
          <td>{{ personal.data_ultima_visita }}</td>
        </tr>
      </tbody>
    </table>
    <div>
      <button
        class="btn btn-primary"
        @click="state.status_modal_personais = false"
      >
        Fechar
      </button>
    </div>
  </Modal>

  <Modal
    :open="state.status_modal_matricula_periodo"
    @close="
      state.status_modal_matricula_periodo =
        !state.status_modal_matricula_periodo
    "
  >
    <label></label>
    <div class="form-group" style="margin-bottom: 0">
      <textarea class="form-control" style="resize: none"></textarea>
    </div>
    <div>
      <button
        class="btn btn-primary"
        @click="state.status_modal_matricula_periodo = false"
      >
        Fechar
      </button>
    </div>
  </Modal>
</template>
<script>
import Chart from "chart.js";
import { onMounted, reactive, onErrorCaptured } from "vue";
import Modal from "../components/Modal/Modal.vue";
//import { ordersChart } from "@/components/Charts/Chart";
import { pt } from "date-fns/locale";
import services from "../services";
import { useStorage } from "vue3-storage";
import Datepicker from "vue3-datepicker";
export default {
  components: {
    Datepicker,

    //  MaioresFrequentadoresTable,
    //  UltimosVisitantesTable,
  },

  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");
    var nome_pessoa = storage.getStorageSync("nome_pessoa");
    var foto = storage.getStorageSync("foto");
    nome_pessoa = nome_pessoa.split(" ")[0];

    const date = new Date();

    var meses = [
      "janeiro",
      "fevereiro",
      "março",
      "abril",
      "maio",
      "junho",
      "julho",
      "agosto",
      "setembro",
      "outubro",
      "novembro",
      "dezembro",
    ];

    var data_atual =
      date.getDate() +
      " de " +
      meses[date.getMonth()] +
      " de " +
      date.getFullYear();

    //  var colaborador_id = storage.getStorageSync("colaborador_id");

    var today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const state = reactive({
      dados_busca: {
        matriculas_periodo: "-",
        desistencias_periodo: "-",
      },
      loadContasVencidas: false,
      personaisAtivos: {},
      clientes_ausentes: [],
      clientes_sem_matricula: [],
      isLoading: false,
      currentClientType: "",
      hasError: false,
      ultimos_visitantes: [],
      maiores_visitantes: [],
      data_inicial: firstDayOfMonth,
      data_final: lastDayOfMonth,
      entradas: [],
      contasReceberVencidas: [],
      pendentes: [],
      personais: [],
      status_modal_ausentes: false,
      status_modal_sem_matricula: false,
      status_modal_personais: false,
      status_modal_matricula_periodo: false,
      idioma: "PT",
      nome_pessoa: nome_pessoa,
    });

    async function modalClienteAusente() {
      state.status_modal_ausentes = true;
      try {
        const clientesAusentes = await services.clientes.listaClientesAusentes({
          token: token,
          empresa_id: empresa_id,
          quantidade: 15,
          quantidade_anterior: 0,
          dias_ausencia: 60,
        });
        state.clientes_ausentes = clientesAusentes.data;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function modalClienteSemMatricula() {
      try {
        const clientesAusentes =
          await services.clientes.listaClientesSemMatricula({
            token: token,
            empresa_id: empresa_id,
            quantidade: 15,
            quantidade_anterior: 0,
          });
        state.clientes_sem_matricula = clientesAusentes.data;
        state.status_modal_sem_matricula = true;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function modalPersonais() {
      state.status_modal_personais = true;
      /* try {
        const clientesAusentes = await services.clientes.listaPerso({
          token: token,
          empresa_id: empresa_id,
          quantidade: 15,
          quantidade_anterior: 0
        });
        state.clientes_ausentes = clientesAusentes.data;
        state.status_modal_sem_matricula = true;
      } catch (error) {
        handleErrors(error);
      }*/
    }

    async function modalMatriculasNoPeriodo() {
      state.status_modal_matricula_periodo();
    }

    function padZero(num) {
      return num.toString().padStart(2, "0");
    }

    function formataData(data) {
      // Cria um objeto Date a partir da string
      const date = new Date(data);

      // Obtém os componentes da data
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // Os meses são indexados a partir de 0
      const day = date.getDate();

      // Formata a data em um formato mais legível
      const formattedDate = `${padZero(day)}/${padZero(month)}/${year}`;
      return formattedDate;
    }

    function formatHour(num) {
      let hours = num;
      if (hours < 10) {
        hours = "0" + hours;
      }
      return hours + ":00";
    }

    async function montaChartHorarioMovimento() {
      var data = await services.home.horarioMovimentoAcademia({
        token: token,
        empresa_id,
        data_inicial: formataData(state.data_inicial),
        data_final: formataData(state.data_final),
      });
      var hora = [];
      var dados = [];
      for (let i = 0; i < data.data.length; i++) {
        const element = data.data[i];
        hora.push(formatHour(element.hora));
        dados.push(element.percentual);
      }

      new Chart(document.querySelector("#chartHorarios"), {
        type: "bar",
        data: {
          labels: hora,
          datasets: [
            {
              data: dados,
              backgroundColor: "rgb(3, 74, 166)",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    }

    async function montaChartDiaSemana() {
      var data = await services.home.diasMovimentoAcademia({
        token: token,
        empresa_id,
        data_inicial: formataData(state.data_inicial),
        data_final: formataData(state.data_final),
      });
      console.log(data.data);
      var titulo = [];
      var dados = [];
      for (let i = 0; i < data.data.length; i++) {
        const element = data.data[i];
        titulo.push(element.dia_da_semana);
        dados.push(element.porcentagem);
      }

      new Chart(document.querySelector("#chartDias"), {
        type: "pie",
        data: {
          labels: titulo,
          datasets: [
            {
              data: dados,
              backgroundColor: "rgb(3, 74, 166)",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
          },

          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    }

    async function qtdClientesAtivos() {
      try {
        const qtdClientesAtivos = await services.clientes.qtdClientesAtivos({
          token: token,
          empresa_id: empresa_id,
        });
        console.log(qtdClientesAtivos);
        state.qtdClientesAtivos = qtdClientesAtivos.data;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function qtdClientesAusentes() {
      try {
        const qtdClientesAusentes = await services.clientes.qtdClientesAusentes(
          {
            token: token,
            empresa_id: empresa_id,
          }
        );
        state.qtdClientesAusentes = qtdClientesAusentes.data;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function qtdClientesSemMatriculas() {
      try {
        const qtdClientesSemMatricula =
          await services.clientes.qtdClientesSemMatriculas({
            token: token,
            empresa_id: empresa_id,
          });
        state.qtdClientesSemMatricula = qtdClientesSemMatricula.data;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function personaisAtivos() {
      try {
        const personaisAtivos = await services.colaboradores.personaisAtivos({
          token: token,
          empresa_id: empresa_id,
        });
        state.personaisAtivos = personaisAtivos.data;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function contasReceberVencidas() {
      state.loadContasVencidas = true;
      try {
        const data = await services.contaReceber.listaAtrasadas({
          token: token,
          empresa_id: empresa_id,
        });
        console.log(data.data);
        state.pendentes = data.data;
        state.loadContasVencidas = false;
      } catch (error) {
        handleErrors(error);
        state.loadContasVencidas = false;
      }
    }

    async function listarUltimasPresencas() {
      try {
        const listaUltimasPresencas =
          await services.clientes.listarUltimasPresencas({
            token: token,
            empresa_id: empresa_id,
          });
        state.ultimos_visitantes = listaUltimasPresencas;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function listarMaioresPresencas() {
      try {
        const listaMaioresPresencas =
          await services.clientes.listarMaioresPresencas({
            token: token,
            empresa_id: empresa_id,
          });
        state.maiores_visitantes = listaMaioresPresencas;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function entradasPorta() {
      try {
        const presencas = await services.home.presencas({
          qntd: 10,
          token: token,
          empresa_id: empresa_id,
        });
        state.presencas = presencas.data;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function dadosPrincipais() {
      try {
        const dadosPrincipais = await services.home.dadosPrincipais({
          data_inicial: state.data_inicial,
          data_final: state.data_final,
          token: token,
          empresa_id: empresa_id,
        });
        state.dados_busca = dadosPrincipais.data;
      } catch (error) {
        handleErrors(error);
      }

      /*
      try {
        const dadosPrincipais = await services.home.dadosPrincipais({
            data_inicial: state.data_inicial,
            data_final: state.data_final,
            token: token,
            empresa_id: empresa_id,
          });
        state.dados_principais = dadosPrincipais.data;
      } catch (error) {
        handleErrors(error);
      }*/
    }

    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }

    onErrorCaptured(handleErrors);

    onMounted(() => {
      entradasPorta(),
        qtdClientesAtivos(),
        qtdClientesAusentes(),
        qtdClientesSemMatriculas(),
        personaisAtivos(),
        listarUltimasPresencas(),
        listarMaioresPresencas();
      dadosPrincipais();
      contasReceberVencidas();
      montaChartHorarioMovimento();
      montaChartDiaSemana();
    });
    return {
      state,
      dadosPrincipais,
      entradasPorta,
      pt,
      Modal,
      modalClienteAusente,
      modalClienteSemMatricula,
      modalMatriculasNoPeriodo,
      modalPersonais,
      foto,
      data_atual,
    };
  },
  /*
  data() {
    return {
      salesChartID: "salesChart",
      ordersChartID: "ordersChart",
      bigLineChart: {
        allData: [
          [10, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 25, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
      },
    };

    /*
    return {
      salesChartID: "salesChart",
      ordersChartID: "ordersChart",
      bigLineChart: {
        allData: [
          [10, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 25, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
      },
    };
  },
  methods: {
    initBigChart(index) {
      chart.destroy();
      chart = new Chart(
        document.getElementById(this.salesChartID).getContext("2d"),
        {
          type: "line",
          data: {
            labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            datasets: [
              {
                label: "Performance",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#5e72e4",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.bigLineChart.allData[index],
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              yAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
              xAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 10,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
            },
            layout: {
              padding: 0,
            },
          },
        }
      );
      this.bigLineChart.activeIndex = index;
    },
  },
  mounted() {
    chart = new Chart(
      document.getElementById(this.salesChartID).getContext("2d"),
      {
        type: "line",
        data: {
          labels: [
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
          ],
          datasets: [
            {
              label: "Performance",
              tension: 0.4,
              borderWidth: 4,
              borderColor: "#034AA6",
              pointRadius: 0,
              backgroundColor: "#CDDBED", // "transparent",
              data: this.bigLineChart.allData[1],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
          scales: {
            yAxes: [
              {
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: "rgba(29,140,248,0.0)",
                  zeroLineColor: "transparent",
                },
                ticks: {
                  padding: 0,
                  fontColor: "#8898aa",
                  fontSize: 13,
                  fontFamily: "Open Sans",
                },
              },
            ],
            xAxes: [
              {
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: "rgba(29,140,248,0.0)",
                  zeroLineColor: "transparent",
                },
                ticks: {
                  padding: 10,
                  fontColor: "#8898aa",
                  fontSize: 13,
                  fontFamily: "Open Sans",
                },
              },
            ],
          },
          layout: {
            padding: 0,
          },
        },
      }
    );
    //ordersChart.createChart(this.ordersChartID);
  },*/
};
</script>
<style scoped>
a {
  cursor: pointer;
}
.btn_buscar {
  width: 100%;
}
.tabela_ausentes th,
.tabela_ausentes td {
  padding: 0.4rem;
}

.tabela_sem_matriculas th,
.tabela_sem_matriculas td {
  padding: 0.4rem;
}

.info_body {
  margin-left: 12pt;
  margin-top: 5px;
}

.qtd_body {
  font-size: 20pt;
  line-height: 1;
}
label {
  font-size: 12pt;
}
body * {
  font-size: 12pt;
}
.card-flex {
  display: flex;
}
.avaliacao {
  color: #000;
  font-weight: 600;
  font-size: 14pt;
}
.card_dir {
  margin-left: 12px;
}
.bg-default {
  background-color: #fff !important;
}
.arow {
  margin-right: 5px;
  font-size: 0.7rem !important;
  color: #034aa6;
  font-weight: 700;
}
.text1 {
  color: #034aa6;
  font-size: 7pt !important;
  font-weight: bold;
}
.num {
  margin-top: -15px;
  font-size: 12pt;
  color: var(--color-text-2, #a6a6a6);
  font-weight: 600;
}

svg {
  color: #aede00;
  width: 24px;
  height: 24px;
}
.quad {
  gap: 10px;
  width: 50px;
  height: 50px;
  padding: 5px 14px;
  background: #010d1f;
  border-radius: 12px;
  color: #aede00;
}
.sistema {
  color: #015acf;
}
.sifit {
  color: #aede00;
}
.h1_title {
  color: #fff;
  font-size: 30pt;
}

.btn_busca {
  margin-top: 32px;
  background-color: #aede00 !important;
  border-color: #aede00 solid 1px !important;
  color: rgb(0, 0, 0) !important;
  border-radius: 15px !important;
  border: solid 1px #aede00;
  padding: 7px;
  width: 90px;
}

.btn_ver_mais {
  background-color: rgb(3, 74, 166);
  border: 1px solid rgb(3, 74, 166);
  border-radius: 15px !important;
  padding: 7px;
  width: 90px;
  color: #fff;
  font-size: 9pt;
}

.div_grafico {
  margin-top: 20px;
}

.topo {
  background-color: #fff;
  padding-top: 25px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color-stroke, #e7e7e7);
}
.bv {
  font-weight: bold;
  font-size: 18pt;
  color: #162b48;
  margin-bottom: 0;
}
.data {
  color: var(--color-text-2, #a6a6a6);
  font-size: 12px;
}
label {
  color: var(--color-text-2, #a6a6a6);
  font-size: 14px;
}
.div_datas {
  margin-top: 0px;
}

input {
  border: 1px solid var(--color-stroke, #e7e7e7);
  width: 100%;
  border-radius: 4px;
  padding: 5px;
}
button {
  border: none;
  color: #fff;
  margin-top: 28px;
  padding: 7px 16px;
  border-radius: 4px;
  background: var(--color-primary, #034aa6);
}
.div_pessoa {
  display: flex;
  text-align: right;
  justify-content: flex-end;
}
.div_imagem {
  margin-top: 10px;
  margin-right: 12px;
}
.div_imagem img {
  border-radius: 50%;
  width: 40px;
}
.nome_usuario {
  color: var(--color-azul-2, #162b48);
  font-size: 12pt;
  font-weight: 600;
  margin-top: 20px;
}
</style>
