<template>
  <topo :titulo="'Fichas de Treino'"></topo>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Total a pagar"
            type="gradient-red"
            sub-title="4.535,20"
            icon="ni ni-active-40"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="text-success mr-2"
                ><i class="fa fa-arrow-up"></i> 3.48%</span
              >
              <span class="text-nowrap">Já pago</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Valor restante"
            type="gradient-orange"
            sub-title="2.356"
            icon="ni ni-chart-pie-35"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="text-success mr-2"
                ></span
              >
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Valor em atraso"
            type="gradient-green"
            sub-title="924"
            icon="ni ni-money-coins"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="text-danger mr-2"></span
              >
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Quantidade contas atrasadas"
            type="gradient-info"
            sub-title="8"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="text-success mr-2"
                ><i class="fa fa-arrow-up"></i> 54.8%</span
              >
              <span class="text-nowrap">Desde o último mês</span>
            </template>
          </stats-card>
        </div>
      </div>
    </base-header>

        <div class="container-fluid mt--7">
      <div class="card shadow p-3" :class="type === 'dark' ? 'bg-default' : ''" s>
        <div class="row align-items-center">
          <div class="col">
            <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
              Local Contas
            </h3>
          </div>
          <div class="col text-right mb-2">
            <base-button type="primary" id="btn_new_product" @click="abrirModal">Adicionar Local</base-button>
          </div>
        </div>
        
          <div class="row">
            <div class="col">
              <div class="table-responsive">
                <table
                  class="table tablesorter table align-items-center table-flush"
                >
                  <thead class="thead-light">
                    <th>Código</th>
                    <th>Nome</th>
                    <th></th>
                  </thead>
                  <tbody class="list">
                    <corpo-local-conta
                      v-for="local in state.locais"
                      :key="local.id"
                      :local="local"
                      @deletar="deletar"
                      @modalVisualizar="modalVisualizar"
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
    <Modal
      :open="state.status_modal"
      @close="state.status_modal = !state.status_modal"
    >
      <h1>Despesas Fixas</h1>
      <div class="row">
        <div class="col-md-2">
          <div class="form-group">
            <label>Código</label>
            <input
              type="text"
              class="form-control input_modal"
              v-model="state.id"
              readonly
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label>Nome</label>
            <input
              type="text"
              class="form-control input_modal"
              v-model="state.nome"
            />
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-group">
            <label>Status</label>
            <select class="form-control" v-model="state.status">
              <option value="ativo">Ativo</option>
              <option value="inativo">Inativo</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label>Valor</label>
            <input type="text" class="form-control" v-model="state.valor"/> 
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Recorrência</label>
            <select class="form-control" v-model="state.recorrencia">
              <option value="1 dia">1 Dia</option>
              <option value="7 dias">7 Dias</option>
              <option value="1 mês">1 Mês</option>
              <option value="3 meses">3 Meses</option>
              <option value="6 meses">6 Meses</option>
              <option value="1 ano">1 Ano</option>
            </select>
          </div>
        </div>
      </div>
      <button
        class="btn btn_salvar my-4"
        id="submit-button"
        type="submit"
        @click="salvarDespesa()"
      >
        Salvar
      </button>
      <button
        class="btn btn_cancelar my-4"
        id="submit-button"
        type="button"
        @click="fecharModal()"
      >
        Cancelar
      </button>
    </Modal>
</template>
<script>
  import { onMounted, reactive, onErrorCaptured  } from 'vue'
  import CorpoLocalConta from '../components/CorpoTabelaLocalConta'
  import services from '../services'
  import Modal from "../components/Modal/Modal.vue";
  import topo from "../components/topo";
  import { useStorage } from "vue3-storage";
export default {
  name: "tables",
    setup () {
    const storage = useStorage();
    var token =  storage.getStorageSync('token')
    var empresa_id = storage.getStorageSync('empresa_id')
    const state = reactive({
      isLoading: false,
      locais: [],
      currentClientType: '',
      hasError: false,
      id:"",
      nome: "",
      valor: "",
      recorrencia: "",
      status:""
    })
    onErrorCaptured(handleErrors)

    onMounted(() => {
      fetchLocais()
    })
    function handleErrors (error) {
      console.log(error)
      state.hasError = !!error
    }
    async function abrirModal() {
      state.status_modal = true;
    }

      function fecharModal() {
        state.id = "";
        state.nome = "";
        state.valor = "";
        state.recorrencia = "";
        state.status = "ativo";
        state.status_modal = false;
    }
      
    async function modalVisualizar(id) {
      try {
        state.isLoading = true;
        let data = await services.despesasFixa.getById({
          empresa_id,
          id,
          token,
        });
        state.status_modal = true;
        let dados = data.data;
        state.id = dados.id;
        state.nome = dados.nome;
        state.valor = dados.valor;
        state.recorrencia = dados.recorrencia;
        state.status = dados.status;
      } catch (error) {
        console.log(error);
      }
    }
    async function deletar(id) {
      if (confirm("Deseja deletar o Despesa?")) {
        await services.despesasFixa.deletar({
          token,
          empresa_id,
          id
        }).then((data) => {
          if (data.data.verifica_erro) {
            alert("Erro ao deletar Despesa")
          } else {
            fetchLocais();
          }
        })
          .catch(() => {
            alert("Erro ao deletar Despesa")
          });
      }
    }

      
    async function salvarDespesa() {
      if (state.id != null && state.id != undefined && state.id != "") {
        await services.despesasFixa.update({
          empresa_id,
          token,
          id: state.id,
          nome: state.nome,
          valor: state.valor,
          caixa: state.caixa,
          recorrencia: state.recorrencia,
          status: state.status
        }).then((data) => {
          if (data.data.verifica_erro) {
            alert("Erro ao alterar Despesa")
          } else {
            fetchLocais();
            fecharModal();
          }
        }).catch(() => {
          alert("Erro ao alterar Despesa")
        });

      } else {
        await services.despesasFixa.insert({
          empresa_id,
          token,
          nome: state.nome,
          valor: state.valor,
          caixa: state.caixa,
          recorrencia: state.recorrencia,
          status: state.status
        }).then((data) => {
          if (data.data.verifica_erro) {
            alert("Erro ao deletar Despesa")
          } else {
            fetchLocais();
            fecharModal();
          }
        }).catch(() => {
          alert("Erro ao deletar Despesa")
        });
      }
    }


    async function fetchLocais () {
      try {
        state.isLoading = true
        const { data } = await services.localContas.getAll({
          token: token,
          empresa_id: empresa_id
        }
        )
      //  console.log(data)
        state.locais = data
        state.isLoading = false
        
      } catch (error) {
        handleErrors(error)
      }
    }
    return {
      state,
      CorpoLocalConta,
      abrirModal,
      fecharModal,
      salvarDespesa,
      modalVisualizar,
      Modal,
      deletar,
      topo
    }
  }
}

</script>

<style scoped>
.local{
  width: 100%;
  height: 100px;
  border: #034AA6 solid 1px;
  border-radius: 5px;
  margin-bottom: 10px;
  padding:15px;
}
.nome_local{
  font-weight:bold;
}
.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}
.btn_salvar:hover {
  transform: none;
  color: #fff;
}
.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}
.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}
textarea{
  resize: none;
}
</style>