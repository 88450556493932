<template>
  <div>
    <topo :titulo="'Cadastrar Matrícula'"></topo>
    <div class="container-fluid mt-3">
      <div>
        <div class="row">
          <div class="col-md-12 col-lg-5">
            <label>Cadastramento Realizado Por:</label>
            <div class="row">
              <div class="col-3 col-sm-3">
                <input type="text" class="form-control" readonly v-model="state.cadastrante_id" />
              </div>
              <div class="col-9 col-sm-9">
                <input type="text" class="form-control" readonly v-model="state.nome_cadastrante" />
              </div>
            </div>
          </div>
          <div class="col-sm-4 col-md-4 col-lg-3">
            <div class="form-group">
              <label>Data</label>
              <input type="text" class="form-control" v-model="state.data" readonly />
            </div>
          </div>

          <div class="col-sm-4 col-md-4 col-lg-2">
            <div class="form-group">
              <label>Status</label>
              <select class="form-control" v-model="state.status">
                <option value="ativo">Ativo</option>
                <option value="inativo">Inativo</option>
                <option value="finalizado">Finalizado</option>
              </select>
            </div>
          </div>
          <div class="col-sm-4 col-lg-2">
            <div class="form-group">
              <label>Renovada</label>
              <select class="form-control" v-model="state.renovada">
                <option value="sim">Sim</option>
                <option value="nao">Não</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-2 col-md-3 col-lg-2">
            <div class="form-group">
              <label>Código:</label>
              <input type="text" class="form-control" v-model="state.cliente_id.value" readonly />
            </div>
          </div>
          <div class="col-10 col-sm-8 col-md-7 col-lg-9">
            <div class="form-group">
              <label>Nome</label>
              <input type="text" class="form-control" v-model="state.nome_cliente" readonly />
            </div>
          </div>
          <div class="col-2 col-md-1">
            <button class="btn btn_lupa" type="submit" style="margin-top: 30px" @click="modalBuscarPessoa()">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
                viewBox="0 0 16 16">
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </button>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label>Modalidade</label>
              <select class="form-control" v-model="state.plano_id.value" ref="selectModalidade"
                @change="carregarPlano($event.target.value)">
                <select-plano v-for="plano in state.planos" :key="plano.id" :plano="plano" />
              </select>
              <span v-if="!!state.plano_id.errorMessage" class="block font-medium text-danger">
                {{ state.plano_id.errorMessage }}

              </span>
            </div>
          </div>

          <div class="col-sm-6 col-lg-3">
            <div class="form-group">
              <label>Data Inicial</label>
              <Datepicker v-model="state.data_inicial" :locale="pt" inputFormat="dd/MM/yyyy" style="
                    border-color: #b8b8b8;
                    color: #505050;
                    border-radius: 5px;
                    border: #b8b8b8 solid 1px;
                    transition: 0.5s;
                    width: 100%;
                    padding: 7px;
                  " />
            </div>
          </div>
          <div class="col-sm-6 col-lg-3" v-if="state.mostrar_data_final">
            <div class="form-group">
              <label>Data Final </label>
              <input type="text" class="form-control" v-model="state.data_final.value" v-mask-date.br
                ref="inputDtFinal" />
              <span v-if="!!state.data_final.errorMessage" class="block font-medium text-danger">
                {{ state.data_final.errorMessage }}

              </span>
            </div>
          </div>

          <div class="col-sm-4 col-lg-3">
            <div class="form-group">
              <label>Pag. Via Sistema</label>
              <select class="form-control" v-model="state.pagamento_via_sistema"
                @change="escolherPagamentoSistema($event.target.value)">
                <option value="sim">Sim</option>
                <option value="nao">Não</option>
              </select>
            </div>
          </div>
          <div class="col-sm-4 col-lg-4">
            <div class="form-group">
              <label>Tipo Pagamento</label>
              <select class="form-control" v-model="state.tipo_pagamento.value" ref="inputTipoPagamento">
                <select-tipo-pagamento v-for="tipo_pagamento in state.tipos_pagamento" :key="tipo_pagamento.id"
                  :tipo_pagamento="tipo_pagamento" />
              </select>
              <span v-if="!!state.tipo_pagamento.errorMessage" class="block font-medium text-danger">
                {{ state.tipo_pagamento.errorMessage }}

              </span>
            </div>
          </div>
          <div class="col-sm-4 col-lg-3">
            <div class="form-group">
              <label>Taxa Matrícula</label>
              <input type="text" class="form-control" v-model="state.taxa_matricula" v-mask="'00'" />
            </div>
          </div>
          <div class="col-sm-4 col-lg-2">
            <div class="form-group">
              <label>Dia Vencimento</label>
              <input type="text" class="form-control" v-model="state.dia_venc_pag" v-mask="'00'" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4 col-lg-3">
            <div class="form-group">
              <label>Valor Parcela</label>
              <input type="text" class="form-control" v-model="state.valor_parcela.value" v-mask-decimal.br="2" />
            </div>
          </div>

          <div class="col-sm-4 col-lg-3">
            <div class="form-group">
              <label>Desconto</label>
              <input type="text" class="form-control" v-model="state.percentual_desconto.value" v-mask-decimal.br="2" />
            </div>
          </div>
          <div class="col-sm-4 col-lg-3">
            <div class="form-group">
              <label>Valor Total</label>
              <input type="text" class="form-control" v-model="state.valor_total.value" v-mask-decimal.br="2" />
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Observação</label>
              <textarea class="area_obs" v-model="state.observacao"></textarea>
            </div>
          </div>
          <!--<<div class="col-md-4">
              <div class="form-group">
                <label>Cópias</label>
                DIV CÓPIAS
              </div>
            </div>-->
          <div class="col-md-4" v-if="state.status == 'recebido'">
            <label>Funcionário Recebedor</label>
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <input type="text" class="form-control" readonly v-model="state.recebedor_id" />
                </div>
              </div>
              <div class="col-sm-9">
                <div class="form-group">
                  <input type="text" class="form-control" readonly v-model="state.nome_recebedor" />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Local Recebimento</label>
              <input type="text" class="form-control" readonly v-model="state.local_recebimento" />
            </div>
            <div class="row">
              <div class="col-sm-9">
                <div class="form-group">
                  <label>Bandeira</label>
                  <input type="text" class="form-control" readonly v-model="state.nome_bandeira" />
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>Parcelas</label>
                  <select class="form-control" v-model="state.parcelas">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Perc. Juros</label>
                  <input type="text" class="form-control" readonly v-model="state.perc_juros" />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Taxa Adicional</label>
                  <input type="text" class="form-control" readonly v-model="state.taxa_adicional" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <button class="btn btn_salvar my-4" id="submit-button" type="submit" @click="handleSubmit()">
          Salvar
        </button>
        <button class="btn btn_cancelar my-4" id="submit-button" type="button" @click="cancel()">
          Cancelar
        </button>
      </div>
    </div>
  </div>
  <Modal :open="state.modal_pesquisar_pessoa" @close="state.modal_pesquisar_pessoa = !state.modal_pesquisar_pessoa"
    :width="'800px'">
    <div style="padding: 20px">
      <div class="row">
        <div class="col-sm-3">
          <label for="tipo_pesquisa">Tipo de Pesquisa</label>
          <select id="tipo_pesquisa" class="form-control">
            <option>Nome</option>
            <option>Código</option>
          </select>
        </div>
        <div class="col-sm-8">
          <label>Pesquisa</label>
          <input type="text" class="form-control" v-model="state.nome" />
        </div>
        <div class="col-sm-1" style="padding-left: 0">
          <button class="btn btn_lupa" type="button" style="margin-top: 32px" @click="fetchClientes">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
              viewBox="0 0 16 16">
              <path
                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
          </button>
        </div>
      </div>

      <div class="row div_s" style="margin-top: 15px">
        <div class="col">
          <div class="table-responsive">
            <loading-pesquisa v-if="state.pesquisaLoading" />
            <table v-else class="table tablesorter table align-items-center table-flush">
              <thead class="thead-light">
                <th>Código</th>
                <th>Nome</th>
                <th></th>
              </thead>
              <tbody class="list">
                <corpo-busca-cliente @buscarCliente="buscarCliente" v-for="cliente in state.clientes" :key="cliente.id"
                  :cliente="cliente" />
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div>
        <button type="button" class="btn btn_fechar" @click="fecharModal('pesquisa_pessoa')">
          Fechar
        </button>
      </div>
    </div>
  </Modal>

  <Modal :open="state.modal_escolher_cartao" @close="state.modal_escolher_cartao = !state.modal_escolher_cartao"
    :width="'400px'">
    <div style="padding: 20px">
      <h4>Escolher Bandeiras</h4>
      <div class="row">
        <div class="col-sm-12">
          <label>Bandeira</label>
          <select class="form-control" v-model="state.bandeira" id="bandeira">
            <select-bandeiras v-for="bandeira in state.bandeiras" :key="bandeira.id" :bandeira="bandeira" />
          </select>
        </div>
      </div>

      <div class="row botoes_modal">
        <div class="col-sm-6">
          <button type="button" class="btn btn-danger largura_total" @click="fecharModal('cartao')">
            Fechar
          </button>
        </div>
        <div class="col-sm-6">
          <button type="button" class="btn btn-primary largura_total" @click="escolherCartao()">
            Escolher
          </button>
        </div>
      </div>
    </div>
  </Modal>


  <Modal :open="state.modal_parcelas" @close="state.modal_parcelas = !state.modal_parcelas" :width="'600px'">
    <div style="padding: 20px">
      <h4>Quantidade Parcelas</h4>
      <div class="row" v-for="n_parcela in state.array_parcelas.length" :key="n_parcela">
        <div class="col-sm-3">
          <label>Número</label>
          <input type="text" class="form-control" v-model="state.array_parcelas[n_parcela -1].numero" readonly />
        </div>
        <div class="col-sm-4">
          <label>Data</label>
          <input type="text" class="form-control" v-model="state.array_parcelas[n_parcela -1].data" />
        </div>
        <div class="col-sm-5">
          <label>Valor</label>
          <input type="text" class="form-control" v-model="state.array_parcelas[n_parcela - 1].valor" />
        </div>
      </div>

      <div class="row botoes_modal">

        <div class="col-sm-6">
          <button type="button" class="btn btn-primary largura_total" @click="salvarParcelas()">
            Escolher
          </button>
        </div>
      </div>
    </div>
  </Modal>


</template>
<script>
import { pt } from "date-fns/locale";
import services from "../services";
import Datepicker from "vue3-datepicker";
import { reactive, onMounted, ref } from "vue";
import SelectPlano from "../components/PlanosSelect.vue";
import SelectTipoPagamento from "../components/TipoPagamentoSelect.vue";
import SelectBandeiras from "../components/BandeirasSelect";
import Modal from "../components/Modal/Modal.vue";
import CorpoBuscaCliente from "../components/CorpoBuscaClientes";
import { useRouter } from "vue-router";
import { useStorage } from "vue3-storage";
import topo from "../components/topo";
import LoadingPesquisa from '../components/CorpoTabelaAvaliacao/Loading'
export default {
  components: {
    topo,
    LoadingPesquisa
  },
  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");
    var nome_pessoa = storage.getStorageSync("nome_pessoa");
    var colaborador_id = storage.getStorageSync("colaborador_id");
    const router = useRouter();

    const inputTipoPagamento = ref(null);
    const selectModalidade = ref(null);
    const inputDtFinal = ref(null);



    
    


    onMounted(() => {
      fetchPlanos();
      fetchTiposPagamento();
      state.nome_cadastrante = nome_pessoa;
      state.cadastrante_id = colaborador_id;

      var data = new Date();
      var dia = String(data.getDate()).padStart(2, "0");
      var mes = String(data.getMonth() + 1).padStart(2, "0");
      var ano = data.getFullYear();
      var dataAtual = dia + "/" + mes + "/" + ano;
      state.data_inicial = new Date();//, dataAtual;
      state.data = dataAtual;
    });

    async function fetchPlanos() {
      try {
        state.isLoading = true;
        const { data } = await services.planos.getAtivos({
          token: token,
          empresa_id: empresa_id,
        });
        state.planos = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      } 
    }

    async function fetchTiposPagamento() {
      try {
        state.isLoading = true;
        const { data } = await services.tipoPagamento.listar({
          token: token,
          empresa_id: empresa_id,
        });
        state.tipos_pagamento = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function fetchTiposPagamentoGateway() {
      try {
        state.isLoading = true;
        const { data } = await services.tipoPagamento.listarGateway({
          token: token,
          empresa_id: empresa_id,
        });
        state.tipos_pagamento = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function fetchBandeiras() {
      try {
        state.isLoading = true;
        const { data } = await services.bandeira.listar({
          token: token,
          empresa_id: empresa_id,
        });
        state.bandeiras = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function carregarPlano(plano_id) {
      try {
        state.isLoading = true;
        const { data } = await services.planos.obterPorId({
          token: token,
          empresa_id: empresa_id,
          id: plano_id,
        });

        state.plano_id.value = plano_id;
        state.valor_parcela.value = data.valor;
        state.valor_parcela_desconto.value = data.valor;
        state.qtd_parcelas = data.qtd_parcelas;
        state.nome_plano = data.nome_plano;
        var valor_total = 0;

        
        state.duracao = data.duracao;



        if (state.plano_id != 0) {
          valor_total = data.valor;
          state.valor_total.value = valor_total;
          state.valor_total_desconto = valor_total;
        } else {
          valor_total = data.valor_msq * data.qtd_parcelas;
          state.valor_total.value = valor_total.toFixed(2);
          state.valor_total_desconto.value = valor_total.toFixed(2);
        }

       // state.valor.value = data.valor;

        if (data.duracao == 0) {
          state.mostrar_data_final = false;
        } else {
          state.mostrar_data_final = true;

          //Calcular data final
          //Pegar data Atual para somar
          
          var currentDateFormatedUS = FormataStringDataUS(state.data_inicial);

          let dateObj = new Date(currentDateFormatedUS);

          //Capturar Quantidade de meses
          var meses = data.duracao;
          //Parse Int dos meses
          meses = parseInt(meses);

          console.log(dateObj);

          //Adicionar meses 
          dateObj.setMonth(dateObj.getMonth() + meses);
          state.data_final.value = dateObj.toLocaleDateString();
//          state.data_final = dateObj.toLocaleDateString();

      //    var teste = dateObj.setMonth(dateObj.getMonth() + meses);
      //    console.log(teste.toLocaleDateString());


      //   parcela.data = currentDate.toLocaleDateString();


          //  state.data_final = dateObj.setMonth(dateObj.getMonth() + meses);

       //   console.log(currentDate);
       //   console.log(state.data_inicial);
/*
          state.data_final = currentDate.toLocaleDateString();*/
        }

        state.percentual_desconto.value = "0";
      } catch (error) {
        handleErrors(error);
        fetchTiposPagamentoGateway();
      }
    }
    function cancel() {
      router.push({ name: "matriculas" });
    }

    async function carregarTipoPagamento(tipo_pagamento_id) {
      try {
        state.isLoading = true;
        const { data } = await services.tipoPagamento.obterPorId({
          token: token,
          empresa_id: empresa_id,
          id: tipo_pagamento_id,
        });

        if (data.escolher_bandeira == "sim") {
          fetchBandeiras();
          state.modal_escolher_cartao = true;
        }
      } catch (error) {
        handleErrors(error);
        fetchTiposPagamentoGateway();
      }
    }

    async function escolherPagamentoSistema(pagamento_sistema) {
      if (pagamento_sistema == "sim") {
        fetchTiposPagamentoGateway();
      } else {
        fetchTiposPagamento();
      }
    }

    async function escolherCartao() {
      state.bandeira = document.querySelector("#bandeira").value;
      //   state.nome_bandeira =
      document.querySelector("#bandeira").selectedOptions[0].text;
      state.modal_escolher_cartao = false;

      const { data } = await services.bandeira.obterPorId({
        empresa_id: empresa_id,
        token: token,
        id: state.bandeira,
      });

      state.taxa_adicional = data.taxa_adicional;
      console.log(data);
    }

    async function fetchClientes() {
      try {
        state.pesquisaLoading = true;
        const { data } = await services.clientes.clientesPorNome({
          empresa_id: empresa_id,
          token: token,
          nome: state.nome,
        });
        console.log(data);
        //  console.log(data)
        state.clientes = data;
        
        //   state.pagination = data.pagination
        state.pesquisaLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    function modalBuscarPessoa() {
      state.modal_pesquisar_pessoa = true;
    }

    function fecharModal(modal) {
      if (modal == "cartao") {
        state.modal_escolher_cartao = false;
      } else if (modal == "pesquisa_pessoa") {
        state.modal_pesquisar_pessoa = false;
      } else if (modal == "parcelas") {
        state.modal_parcelas = false;
      }
    }

    async function salvarParcelas() {
      let erro = false;
      for (let i = 0; i < state.array_parcelas.length; i++) {
        console.log(state.array_parcelas[i].valor)
        await services.contaReceber.save({
          token,
          empresa_id,
          funcionario_cadastrante_id: state.cadastrante_id,
          cliente_id: state.cliente_id.value,
        //  local_conta_id: ,
          nome: state.nome_plano,
          valor_receber: state.array_parcelas[i].valor,
          data_vencimento: state.array_parcelas[i].data,
          status:"pendente"
        }).catch(() => {
          erro = true;
          return;
        });
      }
      if(!erro) {
        router.push({ name: "matriculas" });
      }
    }

    /*
    function alterarStatus(status) {
      if (status == "pendente") {
        state.status = "pendente";
      } else if (status == "cancelado") {
        state.status = "cancelado";
      } else {
        state.status = "recebido";
      }
    }
*/
    function buscarCliente(id_cliente, nome_cliente) {
      state.cliente_id.value = id_cliente;
      state.nome_cliente = nome_cliente;
      state.modal_pesquisar_pessoa = false;
    }


    async function handleSubmit() {
      try {
        if (state.cliente_id.value.length == 0) {
          alert("Selecione o cliente!");
          return;
        }



        if (state.plano_id.value == null || state.plano_id.value == "") {
          state.plano_id.errorMessage = "Selecione o Plano";
          selectModalidade.value.focus();
          return;
        }


        if (state.duracao != null && state.duracao != undefined  && state.duracao != 0) {
          if (state.data_final.value.length == 0) {
            state.data_final.errorMessage = "Preencha a data final";
            inputDtFinal.value.focus();
            return;
        //    
          }
        }

        if (state.tipo_pagamento.value == null || state.tipo_pagamento.value == "") {
          state.tipo_pagamento.errorMessage = "Selecione o Tipo de Pagamento";
          inputTipoPagamento.value.focus();
          return;
        }

        for (let i = 0; i < state.planos.length; i++) {
          const element = state.planos[i];
          if (element.id == state.plano_id) {
            //se Duracao == 0
            if (element.duracao == "0") {
              if (state.dia_venc_pag.length == 0) {
                alert("O Dia de Vencimento deve ser preenchido.");
                return;
              }
            }
          }
        }



        var dia_data_inicial = state.data_inicial.getDate();
        

        if (dia_data_inicial < 10) {
          dia_data_inicial = "0" + dia_data_inicial;
        }

        var mes_data_inicial = state.data_inicial.getMonth();

        mes_data_inicial = mes_data_inicial + 1;
        if (mes_data_inicial < 10) {
          mes_data_inicial = "0" + mes_data_inicial;
        }

        var data_inicial =
          dia_data_inicial.toString() +
          "/" +
          mes_data_inicial.toString() +
          "/" +
          state.data_inicial.getFullYear().toString();

        if (!state.id.value) {
          const { data } = await services.matriculas.save({
            empresa_id: empresa_id,
            tipo_pagamento: state.tipo_pagamento.value,
            via_gateway: state.via_gateway,
            plano_id: state.plano_id.value,
            cliente_id: state.cliente_id.value,
            usuario_id: state.cadastrante_id,
            observacao: state.observacao,
            data_inicial: data_inicial,
            //  id_personal: state.id_personal
            id_personal: 0,
            data_final: state.data_final.value,
            duracao: state.duracao,
            tipo_pagamento_id: state.tipo_pagamento.value,
            valor_mensal: state.valor_parcela.value,
            valor_total: state.valor_total.value,
            status: state.status,
            dia_venc_pag: state.dia_venc_pag,
            renovada: state.renovada,
            percentual_desconto: state.percentual_desconto.value,
            valor_desconto_mensal: state.valor_desconto_mensal,
            valor_desconto_total: state.valor_desconto_total,
            valor_mensal_com_desconto: state.valor_parcela.value,
            valor_total_com_desconto: state.valor_total.value,
            pagamento_via_gateway: state.pagamento_via_sistema,
            taxa_matricula: state.taxa_matricula,
            token
          });
          if (data.verifica_erro == false) {
            //Verifica se é parcelado
            if (state.qtd_parcelas > 0) {
              //var currentDate;
              var parcela;
              //    var data;
              var currentDateFormatedUS;
              let dateObj;
              let dia_atual;
              var array_parcelas = [];
              for (let i = 0; i < state.qtd_parcelas; i++) {
                parcela = {};
                parcela.valor = state.valor_parcela.value;
                parcela.numero = i + 1;
                currentDateFormatedUS = FormataStringDataUS(state.data_inicial);
                console.log(currentDateFormatedUS);
                dateObj = new Date(currentDateFormatedUS);
                dateObj.setDate(dateObj.getDate() + 1);

                dia_atual = dateObj.getDate(); //String().padStart(2, '0');;
                console.log(dia_atual);
                //  console.log(dateObj.setMonth(dateObj.getMonth() + i));
                //Adicionar meses 
                //     console.log(dateObj);
                dateObj.setMonth(dateObj.getMonth() + i);

                parcela.data = dateObj.toLocaleDateString();
                //        parcela.data = ;
                //Calcular data final
                //Pegar data Atual para somar
                //  currentDate = state.data_inicial // FormataStringData(state.data_inicial);
                //    console.log(state.data_inicial);
                //Capturar Quantidade de meses
                //var meses = data.duracao;
                //Parse Int dos meses
                //      var a = parseInt(meses);
                //Adicionar meses 
                //   currentDate.setMonth(state.data_inicial.getMonth() + i);
                //   parcela.data = currentDate.toLocaleDateString();
                array_parcelas[i] = parcela;
              }
              state.array_parcelas = array_parcelas
              state.modal_parcelas = true;
            } else {
              router.push({ name: "matriculas" });
            }
            
          } else {
            alert("Erro ao gerar Matrícula");
              return;              
            }


        //  } else {
       //     alert("Erro ao cadastrar matrícula!");
       //   }
        } else {
          const { data } = await services.clientes.save({
            id: state.id.value,
            status: state.status.value,
            sempre_liberado: state.sempre_liberado.value,
            nome: state.nome.value,
            data_nascimento: state.data_nascimento.value,
            sexo: state.sexo.value,
            tipo_sangue: state.tipo_sangue.value,
            telefone: state.telefone.value,
            celular: state.celular.value,
            tel_emergencia: state.tel_emergencia.value,
            email: state.email.value,
            cpf: state.cpf.value,
            rg: state.rg.value,
            pais: state.pais.value,
            estado: state.estado.value,
            cidade: state.cidade.value,
            cep: state.cep.value,
            endereco: state.endereco.value,
            numero: state.numero.value,
            bairro: state.bairro.value,
            complemento: state.complemento.value,
            observacao: state.observacao.value
          });
          console.log(data);
          if (!data) {
            //       window.localStorage.setItem('token', data.token)
            router.push({ name: "matriculas" });
            state.isLoading = false;
            return;
          }
          /*
          if (errors.status === 400) {
            //  toast.error('Ocorreu um erro ao criar a conta')
          }*/
        }

        state.isLoading = false;
      } catch (error) {
        console.log(error);
        state.isLoading = false;
        state.hasErrors = !!error;
        //  toast.error('Ocorreu um erro ao criar a conta')
      }
    }
    function FormataStringDataUS(data) {
      
      var dia_data_inicial = data.getDate();
      if (dia_data_inicial < 10) {
        dia_data_inicial = "0" + dia_data_inicial;
      }
      var mes_data_inicial = data.getMonth();
      mes_data_inicial = mes_data_inicial + 1;
      if (mes_data_inicial < 10) {
        mes_data_inicial = "0" + mes_data_inicial;
      }

      return data.getFullYear().toString()+"-"+ mes_data_inicial.toString()+"-"+dia_data_inicial.toString();
    }

    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }


    const state = reactive({
      planos: [],
      tipos_pagamento: [],
      clientes: [],
      mostrar_data_final: true,
      modal: false,
      modal_escolher_cartao: false,
      pesquisaLoading:false,
      pagamento_via_sistema: "nao",
      cadastrante_id: 0,
      parcelas: 0,
      array_parcelas:[],
      id: 0,
      data_inicial: new Date(),
      nome_cadastrante: "",
      data: "",
      status: "ativo",
      renovada: "nao",
      codigo: "",
      valor_recebido: "",
      dia_venc_pag: "",
      observacao: "",
      recebedor_id: "",
      nome_recebedor: "",
      local_recebimento: "",
      cartao_id: "",
      bandeira: "",
      nome_bandeira: "",
      taxa_adicional: 0,
      qtd_parcelas: 0,
      modal_parcelas: false,
      taxa_matricula: "",
      cliente_id: {
        value: null,
        errorMessage: null,
      },
      plano_id: {
        value: null,
        errorMessage: null,
      },
      tipo_pagamento: {
        value: null,
        errorMessage: null,
      },
      nome_cliente: "",
      valor_parcela: {
        value: null,
        errorMessage: null,
      },
      valor_parcela_desconto: {
        value: null,
        errorMessage: null,
      },
      data_final: {
        value: null,
        errorMessage: null,
      },
      valor_total: {
        value: null,
        errorMessage: null,
      },
      valor_total_desconto: {
        value: null,
        errorMessage: null,
      },
      percentual_desconto: {
        value: null,
        errorMessage: null,
      },
    });
    state.cliente_id.value = "";
    return {
      pt,
      state,
      SelectPlano,
      SelectTipoPagamento,
      SelectBandeiras,
      //   alterarStatus,
      escolherCartao,
      Modal,
      modalBuscarPessoa,
      fecharModal,
      fetchClientes,
      CorpoBuscaCliente,
      buscarCliente,
      carregarTipoPagamento,
      carregarPlano,
      escolherPagamentoSistema,
      handleSubmit,
      cancel,
      Datepicker,
      salvarParcelas,

      selectModalidade,
      inputDtFinal,
      inputTipoPagamento
    };
  },
};
</script>
<style scoped>
.form-group {
  margin-bottom: 0.5rem;
}
.vue-modal-inner {
  max-width: 400px !important;
}
.botoes_modal {
  margin-top: 10px;
}
.largura_total {
  width: 100%;
}
.btn_lupa {
  background-color: #aede00;
  padding: 10px;
  border-color: #aede00 solid 1px !important;
  color: rgb(0, 0, 0) !important;
  border-radius: 10px !important;
}
.btn_lupa:hover {
  transform: none;
}
.btn_fechar {
  color: #fff;
  background-color: #788596;
}
.btn_fechar:hover {
  color: #fff;
  background-color: #788596;
  transform: none;
}
input {
  border-color: #b8b8b8;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
}
input:focus {
  border-color: #252525;
}
select {
  border-color: #b8b8b8;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
}
select:focus {
  border-color: #252525;
}
.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}
.btn_salvar:hover {
  transform: none;
  color: #fff;
}
.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}
.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}
.div_s {
  height: 350px;
  overflow-y: scroll;
}
input {
  border-color: #b8b8b8;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
}
input:focus {
  border-color: #252525;
}
select {
  border-color: #b8b8b8;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
}
select:focus {
  border-color: #252525;
}
.area_obs {
  width: 100%;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
  outline: none;
  height: 100px;
  border: 1px solid #b8b8b8;
  resize: none;
}
.area_obs:focus {
  border-color: #252525;
  outline: none;
}
</style>