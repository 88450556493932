<template>
  <topo :titulo="'Exercícios'"></topo>
  <div>
    <div class="container-fluid mt-3">
      <div class="card shadow p-3" :class="type === 'dark' ? 'bg-default' : ''">
        <div class="row align-items-center">
          <div class="col">
            <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
              Exercícios
            </h3>
          </div>
        </div>
        <div class="row">
          <div
            class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4"
            v-for="grupo in state.grupos"
            :key="grupo.id"
          >
            <div style="display: flex; justify-content: space-between">
              <h4>{{ grupo.nome }}</h4>
              <button
                class="btnAdd"
                type="button"
                @click="abrirModalExercicio(grupo.id)"
              >
                <i class="bi bi-plus"></i>
              </button>
            </div>
            <div class="row">
              <div class="col">
                <div class="table-responsive">
                  <table
                    class="table tablesorter table align-items-center table-flush"
                  >
                    <thead class="thead-light">
                      <th>Nome</th>
                      <th></th>
                    </thead>
                    <tbody class="list">
                      <corpo-exercicio
                        v-for="exercicio in grupo.exercicios"
                        :key="exercicio.id"
                        :exercicio="exercicio"
                        @deletar="deletar"
                        @modalVisualizar="modalVisualizar"
                      />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    :open="state.status_modal_exercicio"
    @close="state.status_modal_exercicio = !state.status_modal_exercicio"
    :width="'800px'"
  >
    <h1>Exercício</h1>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label>Código</label>
          <input
            type="text"
            class="form-control input_modal"
            v-model="state.id"
            readonly
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Nome</label>
          <input
            type="text"
            class="form-control input_modal"
            v-model="state.nome"
          />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group">
          <label>Tipo Membro</label>
          <select class="form-control" v-model="state.tipo_membro">
            <option value="INFERIORES">Inferiores</option>
            <option value="SUPERIORES">Superiores</option>
            <option value="AEROBIO">Aeróbio</option>
          </select>
        </div>
      </div>
    </div>
    <button
      class="btn btn_salvar my-4"
      id="submit-button"
      type="submit"
      @click="salvarGrupo()"
    >
      Salvar
    </button>
    <button
      class="btn btn_cancelar my-4"
      id="submit-button"
      type="button"
      @click="fecharModal()"
    >
      Cancelar
    </button>
  </Modal>
  <Modal
    :open="state.status_modal"
    @close="state.status_modal = !state.status_modal"
    :width="'800px'"
  >
    <h1>Exercício</h1>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label>Código</label>
          <input
            type="text"
            class="form-control input_modal"
            v-model="state.id"
            readonly
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Nome</label>
          <input
            type="text"
            class="form-control input_modal"
            v-model="state.nome"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label style="font-weight: bold">Imagem</label>
        <div class="inputFile">
          <span class="arquivo">Selecionar foto</span>
          <input type="file" class="form-control" @change="adicionarImagem" />
          <span v-if="state.erro_imagem" class="text-danger"
            >Este campo é obrigatório</span
          >
        </div>
        <div v-if="state.imagem.url != undefined">
          <img :src="state.imagem.url" style="width: 100px" />
          <button
            type="button"
            class="btn_deletar"
            @click="removerImagem(imagem.id)"
          >
            X
          </button>
        </div>
      </div>
      <div class="col-md-6">
        <label style="font-weight: bold">Vídeo</label>
        <div class="inputFile">
          <span class="arquivo">Selecionar arquivo</span>
          <input type="file" class="form-control" @change="adicionarVideo" />
          <span v-if="state.erro_imagem" class="text-danger"
            >Este campo é obrigatório</span
          >
        </div>
        <div v-if="state.video.url != undefined">
          <img src="/img/video.jpg" style="width: 100px" />
          <button
            type="button"
            class="btn_deletar"
            @click="removerImagem(video.id)"
          >
            X
          </button>
        </div>
      </div>
    </div>

    <button
      class="btn btn_salvar my-4"
      id="submit-button"
      type="submit"
      @click="salvarExercicio()"
    >
      Salvar
    </button>
    <button
      class="btn btn_cancelar my-4"
      id="submit-button"
      type="button"
      @click="fecharModal()"
    >
      Cancelar
    </button>
  </Modal>
</template>
<script>
import { onMounted, reactive, onErrorCaptured } from "vue";
import CorpoGrupo from "../components/CorpoTabelaGrupo";
import CorpoExercicio from "../components/CorpoTabelaExercicio";
import services from "../services";
import Modal from "../components/Modal/Modal.vue";
import topo from "../components/topo";
import { useStorage } from "vue3-storage";
export default {
  name: "tables",
  components: {
    topo,
  },
  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");
    const state = reactive({
      isLoading: false,
      grupos: [],
      currentClientType: "",
      hasError: false,
      id: "",
      nome: "",
      tipo_membro: "",
      status_modal_exercicio: false,
      imagem: {},
      video: {},
    });
    onErrorCaptured(handleErrors);

    onMounted(() => {
      fetchGrupos();
    });
    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }
    async function abrirModalExercicio(musculo_id) {
      console.log(musculo_id);
      state.musculo_atual_id = musculo_id;
      state.status_modal = true;
    }

    function fecharModal() {
      state.id = "";
      state.nome = "";
      state.tipo_membro = "";
      state.imagem = {};
      state.video = {};
      state.status_modal = false;
    }

    async function adicionarImagem(event) {
      const element = event.target.files[0];
      var objImagem = new Object();
      objImagem.file = element;
      objImagem.url = URL.createObjectURL(element);
      state.imagem = objImagem;
    }

    async function adicionarVideo(event) {
      const element = event.target.files[0];
      var objVideo = new Object();
      objVideo.file = element;
      objVideo.url = URL.createObjectURL(element);
      state.video = objVideo;
    }

    async function modalVisualizar(id) {
      try {
        state.isLoading = true;
        let data = await services.gruposMusculares.obterPorId({
          empresa_id,
          id,
          token,
        });
        state.status_modal = true;
        let dados = data.data;
        state.id = dados.id;
        state.nome = dados.nome;
        state.tipo_membro = dados.tipo_membro;
      } catch (error) {
        console.log(error);
      }
    }
    async function deletar(id) {
      if (confirm("Deseja deletar o Exercício?")) {
        await services.exercicio
          .deletar({
            token,
            empresa_id,
            id,
          })
          .then((data) => {
            if (data.data.verifica_erro) {
              alert("Erro ao deletar Exercício");
            } else {
              fetchGrupos();
            }
          })
          .catch(() => {
            alert("Erro ao deletar Exercício");
          });
      }
    }

    async function salvarExercicio() {
      var formExercicio = new FormData();
      formExercicio.append("nome", state.nome);
      formExercicio.append("arquivo", state.imagem.file);
      formExercicio.append("video", state.video.file);
      formExercicio.append("grupo_muscular_id", state.musculo_atual_id);
      let retorno = await services.exercicio.save({
        empresa_id,
        token,
        formExercicio,
      });

      /* 
      let retorno = await services.exercicio.save({
        empresa_id,
        token,
        nome: state.nome,
        tipo_membro: state.tipo_membro
      });
*/
      if (retorno.data.verifica_erro == false) {
        fetchGrupos();
        state.status_modal = false;
        state.nome = "";
      } else {
        alert("Erro ao salvar plano");
      }
    }

    async function salvarGrupo() {
      if (state.id != null && state.id != undefined && state.id != "") {
        let retorno = await services.gruposMusculares.update({
          empresa_id,
          token,
          id: state.id,
          nome: state.nome,
          tipo_membro: state.tipo_membro,
        });
        console.log(retorno.data.verifica_erro);
        if (retorno.data.verifica_erro == false) {
          fetchGrupos();
          state.status_modal = false;
        } else {
          alert("Erro ao salvar plano");
        }
      } else {
        let retorno = await services.gruposMusculares.save({
          empresa_id,
          token,
          nome: state.nome,
          tipo_membro: state.tipo_membro,
        });

        if (retorno.data.verifica_erro == false) {
          fetchGrupos();
          state.status_modal = false;
        } else {
          alert("Erro ao salvar plano");
        }
      }
    }

    async function fetchGrupos() {
      try {
        state.isLoading = true;
        const { data } = await services.gruposMusculares.listar({
          token: token,
          empresa_id: empresa_id,
        });
        //  console.log(data)
        state.grupos = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    return {
      state,
      CorpoGrupo,
      //  abrirModal,
      fecharModal,
      salvarGrupo,
      modalVisualizar,
      Modal,
      deletar,
      CorpoExercicio,
      abrirModalExercicio,
      adicionarImagem,
      adicionarVideo,
      salvarExercicio,
    };
  },
};
</script>

<style scoped>
.grupo {
  width: 100%;
  height: 100px;
  border: #034aa6 solid 1px;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
}
.nome_grupo {
  font-weight: bold;
}
.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}
.btn_salvar:hover {
  transform: none;
  color: #fff;
}
.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}
.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}
textarea {
  resize: none;
}
.i_quad {
  background: #034aa6;
  padding: 10px;
  border-radius: 100px;
  font-size: 16pt;
  color: #fff;
}
.title1 {
  color: #000;
  margin-top: 10px;
  font-size: 11pt;
  line-height: 1;
}
.num {
  margin-top: -15px;
}
.btnAdd {
  width: 40px;
  height: 40px;
  color: #fff;
  background: transparent;
  border: none;
  margin-bottom: 10px;
  padding: 0;
}
.btnAdd i {
  color: #034aa6;
  font-size: 26pt;
  font-weight: bold;
  line-height: 0;
}
</style>