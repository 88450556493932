<template>
  <topo :titulo="'Cadastrar Colaborador'"></topo>
  <div>
    <div class="container-fluid mt-3">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <stats-card>
            <nav aria-label="breadcrumb" class="mb-3">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/dashboard">Home</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/colaboradores" class="primary"
                    >colaboradores
                  </router-link>
                </li>
                <li
                  class="breadcrumb-item active"
                  aria-current="page"
                  v-if="state.idioma == 'PT'"
                >
                  Adicionar
                </li>
                <li class="breadcrumb-item active" aria-current="page" v-else>
                  Para Agregar
                </li>
              </ol>
            </nav>
            <form @submit.prevent="handleSubmit">
              <div class="row">
                <div class="col-md-4 d-lg-none d-xl-block">
                  <div class="checks">
                    <div class="title_check">
                      <spam class="span_login">Login</spam>
                    </div>
                    <div style="clear: both"></div>
                    <div>
                      <input
                        class="chekcbox"
                        type="radio"
                        value="administrador"
                        v-model="state.tipo_login"
                        id="radio_admin"
                      />
                      <label for="radio_admin" class="lbl_check"> Admin</label>
                      <input
                        class="chekcbox"
                        type="radio"
                        value="normal"
                        v-model="state.tipo_login"
                        id="radio_normal"
                      />
                      <label for="radio_normal" class="lbl_check">
                        Normal</label
                      >
                      <input
                        class="chekcbox"
                        type="radio"
                        value="nao"
                        v-model="state.tipo_login"
                        id="radio_nao"
                      />
                      <label for="radio_nao" class="lbl_check">
                        Sem login</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-6 d-none d-lg-block d-xl-none">
                  <div class="checks">
                    <div class="title_check">
                      <spam class="span_login">Login</spam>
                    </div>
                    <div style="clear: both"></div>
                    <div>
                      <input
                        class="chekcbox"
                        type="radio"
                        value="administrador"
                        v-model="state.tipo_login"
                        id="radio_admin"
                      />
                      <label for="radio_admin"> Admin</label>
                      <input
                        class="chekcbox"
                        type="radio"
                        value="normal"
                        v-model="state.tipo_login"
                        id="radio_normal"
                      />
                      <label for="radio_normal"> Normal</label>
                      <input
                        class="chekcbox"
                        type="radio"
                        value="nao"
                        v-model="state.tipo_login"
                        id="radio_nao"
                      />
                      <label for="radio_nao"> Sem login</label>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="checks">
                    <div class="title_check">
                      <spam class="span_login">Avaliador</spam>
                    </div>
                    <div style="clear: both"></div>
                    <div>
                      <input
                        class="chekcbox"
                        type="radio"
                        value="sim"
                        v-model="state.avaliador"
                        id="radio_sim"
                      />
                      <label for="radio_sim" class="lbl_check"> Sim</label>
                      <input
                        class="chekcbox"
                        type="radio"
                        value="nao"
                        v-model="state.avaliador"
                        id="raadio_nao"
                      />
                      <label for="raadio_nao" class="lbl_check"> Não</label>
                    </div>
                  </div>
                </div>
              </div>
              <h2 v-if="state.idioma == 'PT'">Dados Principais</h2>
              <h2 v-else>Datos principales</h2>
              <div class="row">
                <div class="col-sm-3 col-md-2">
                  <div class="form-group">
                    <label>Código</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.id.value"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-sm-9 col-lg-6">
                  <div class="form-group">
                    <label v-if="state.idioma == 'PT'">Nome</label>
                    <label v-else>Nombre</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.nome.value"
                    />
                    <span
                      v-if="!!state.nome.errorMessage"
                      class="block font-medium text-brand-danger"
                    >
                      {{ state.nome.errorMessage }}
                    </span>
                  </div>
                </div>

                <div class="col-sm-4 col-md-4">
                  <div class="form-group">
                    <label v-if="state.idioma == 'PT'">Status</label>
                    <label v-else>Status</label>
                    <select
                      class="form-control"
                      v-model="state.status.value"
                      v-if="state.idioma == 'PT'"
                    >
                      <option value="ativo">Ativo</option>
                      <option value="inativo">Inativo</option>
                    </select>

                    <select
                      class="form-control"
                      v-model="state.status.value"
                      v-else
                    >
                      <option value="ativo">Ativo</option>
                      <option value="inativo">Inativo</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4 col-lg-4">
                  <div class="form-group">
                    <label v-if="state.idioma == 'PT'"
                      >Data de Nascimento</label
                    >
                    <label v-else>Fecha de nacimiento</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.data_nascimento.value"
                      v-mask-date.br
                    />
                    <span
                      v-if="!!state.data_nascimento.errorMessage"
                      class="block font-medium text-brand-danger"
                    >
                      {{ state.data_nascimento.errorMessage }}
                    </span>
                  </div>
                </div>
                <div class="col-sm-4 col-lg-4">
                  <div class="form-group">
                    <label>Sexo</label>
                    <select
                      v-if="state.idioma == 'PT'"
                      class="form-control"
                      v-model="state.sexo.value"
                    >
                      <option value="masculino">Masculino</option>
                      <option value="feminino">Feminino</option>
                    </select>

                    <select
                      v-else
                      class="form-control"
                      v-model="state.sexo.value"
                    >
                      <option value="masculino">Masculino</option>
                      <option value="feminino">Femenino</option>
                    </select>

                    <span
                      v-if="!!state.sexo.errorMessage"
                      class="block font-medium text-brand-danger"
                    >
                      {{ state.sexo.errorMessage }}
                    </span>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label v-if="state.idioma == 'PT'">Tipo Sanguíneo</label>
                    <label v-else>Tipo de sangre</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.tipo_sangue.value"
                    />
                    <span
                      v-if="!!state.tipo_sangue.errorMessage"
                      class="block font-medium text-brand-danger"
                    >
                      {{ state.tipo_sangue.errorMessage }}
                    </span>
                  </div>
                </div>
              </div>
              <h2>Documentos</h2>
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>CPF</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.cpf.value"
                      v-mask-cpf
                    />
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>RG</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.rg.value"
                    />
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>CREF</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.cref.value"
                    />
                  </div>
                </div>
              </div>
              <div v-if="state.tipo_login != 'nao'">
                <h2 v-if="state.idioma == 'PT'">Cadastro de Login</h2>
                <h2 v-else>Iniciar sesión Registro</h2>

                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Login</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="state.login.value"
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label v-if="state.idioma == 'PT'">Senha</label>
                      <label v-else>Contraseña</label>

                      <input
                        type="password"
                        class="form-control"
                        v-model="state.senha.value"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <h2 v-if="state.idioma == 'PT'">Dados para Contato</h2>
              <h2 v-else>Detalles de contacto</h2>

              <div class="row">
                <div class="col-sm-6 col-md-3">
                  <div class="form-group">
                    <label v-if="state.idioma == 'PT'"
                      >Telefone Principal</label
                    >
                    <label v-else>Teléfono principal</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.telefone.value"
                      v-mask="'(00)00000-0000'"
                    />
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="form-group">
                    <label v-if="state.idioma == 'PT'">Celular/WhatsApp</label>
                    <label v-else>Teléfono móvil/WhatsApp</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.celular.value"
                      v-mask="'(00)00000-0000'"
                    />
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="form-group">
                    <label v-if="state.idioma == 'PT'"
                      >Contato de Emergência</label
                    >
                    <label v-else>Contacto de emergencia</label>

                    <input
                      type="text"
                      class="form-control"
                      v-model="state.tel_emergencia.value"
                    />
                  </div>
                </div>

                <div class="col-lg-3">
                  <div class="form-group">
                    <label>E-mail</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.email.value"
                    />
                    <span
                      v-if="!!state.email.errorMessage"
                      class="block font-medium text-brand-danger"
                    >
                      {{ state.email.errorMessage }}
                    </span>
                  </div>
                </div>
              </div>
              <h2 v-if="state.idioma == 'PT'">Endereço</h2>
              <h2 v-else>Dirección</h2>
              <div class="row">
                <div class="col-sm-6 col-md-4">
                  <div class="form-group">
                    <label>País</label>
                    <select class="form-control" v-model="state.pais.value">
                      <select-paises
                        v-for="pais in state.paises"
                        :key="pais.id"
                        :pais="pais"
                      />
                    </select>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="form-group">
                    <label v-if="state.idioma == 'PT'">Estado</label>
                    <label v-else>Departamento</label>
                    <select
                      class="form-control"
                      v-model="state.estado"
                      @change="fetchCidades($event.target.value)"
                    >
                      <select-estados
                        v-for="estado in state.estados"
                        :key="estado.id"
                        :estado="estado"
                      />
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label v-if="state.idioma == 'PT'">Cidade</label>
                    <label v-else>Ciudad</label>
                    <select class="form-control" v-model="state.cidade">
                      <option
                        v-for="cidade in state.cidades"
                        :key="cidade.id"
                        :value="cidade.id"
                      >
                        {{ cidade.nome }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-8">
                  <div class="form-group">
                    <label v-if="state.idioma == 'PT'">Endereço</label>
                    <label v-else>Dirección</label>

                    <input
                      type="text"
                      class="form-control"
                      v-model="state.endereco.value"
                    />
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>Número</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.numero.value"
                    />
                  </div>
                </div>
                <div class="col-sm-5 col-md-3">
                  <div class="form-group">
                    <label v-if="state.idioma == 'PT'">Bairro</label>
                    <label v-else>Vecindario</label>

                    <input
                      type="text"
                      class="form-control"
                      v-model="state.bairro.value"
                    />
                  </div>
                </div>
                <div class="col-sm-7 col-md-3">
                  <div class="form-group">
                    <label v-if="state.idioma == 'PT'">Complemento</label>
                    <label v-else>Complementar</label>

                    <input
                      type="text"
                      class="form-control"
                      v-model="state.complemento.value"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label v-if="state.idioma == 'PT'">CEP</label>
                    <label v-else>Código postal</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.cep.value"
                      v-mask-cep
                    />
                  </div>
                </div>
              </div>
              <h2>Biometria</h2>
              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Código</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.id_leitor"
                    />
                  </div>
                </div>
              </div>
              <h2 v-if="state.idioma == 'PT'">Obeservação</h2>
              <h2 v-else>Observación</h2>

              <div class="row">
                <div class="col-md-12">
                  <div>
                    <textarea class="area_obs"></textarea>
                  </div>
                </div>
              </div>
              <div class="div_bottom">
                <button
                  v-if="state.idioma == 'PT'"
                  class="btn btn_salvar"
                  id="submit-button"
                  :disabled="state.isLoading"
                  type="submit"
                >
                  Salvar
                </button>

                <button
                  v-else
                  class="btn btn_salvar"
                  id="submit-button"
                  :disabled="state.isLoading"
                  type="submit"
                >
                  Ahorrar
                </button>

                <button
                  class="btn btn_cancelar"
                  id="submit-button"
                  type="button"
                  @click="cancel()"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </stats-card>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { onMounted, reactive } from "vue";
import services from "../services";
import { useField } from "vee-validate";
import { validateEmptyAndLength3, validateEmpty } from "../utils/validators";
import SelectPaises from "../components/PaisesSelect.vue";
import SelectEstados from "../components/EstadosSelect.vue";
import { useRouter } from "vue-router";
import { useStorage } from "vue3-storage";
import topo from "../components/topo";

export default {
  components: { SelectPaises, SelectEstados, topo },
  setup(_, { emit }) {
    const router = useRouter();
    const storage = useStorage();

    var empresa_id = storage.getStorageSync("empresa_id");
    var token = storage.getStorageSync("token");

    var cidade_id = storage.getStorageSync("cidade_id");
    var estado_id = storage.getStorageSync("estado_id");
    var pais_id = storage.getStorageSync("pais_id");

    console.log(token);
    console.log(empresa_id);

    function cancel() {
      router.push({ name: "colaboradores" });
    }

    const { value: idValue, errorMessage: idErrorMessage } = useField(
      "id",
      validateEmptyAndLength3
    );

    const { value: statusValue, errorMessage: statusErrorMessage } = useField(
      "status",
      validateEmptyAndLength3
    );

    const {
      value: sempreLiberadoValue,
      errorMessage: sempreLiberadoErrorMessage,
    } = useField("sempre_liberado", validateEmptyAndLength3);

    const { value: nomeValue, errorMessage: nomeErrorMessage } = useField(
      "nome",
      validateEmptyAndLength3
    );

    const {
      value: dataNascimentoValue,
      errorMessage: dataNascimentoErrorMessage,
    } = useField("data_nascimento", validateEmpty);

    const { value: sexoValue, errorMessage: sexoErrorMessage } = useField(
      "sexo",
      validateEmpty
    );

    const { value: tipoSangueValue, errorMessage: tipoSangueErrorMessage } =
      useField("tipo_sangue", validateEmpty);

    const { value: telefoneValue, errorMessage: telefoneErrorMessage } =
      useField("telefone", validateEmptyAndLength3);

    const { value: celularValue, errorMessage: celularErrorMessage } = useField(
      "celular",
      validateEmpty
    );

    const {
      value: telEmergenciaValue,
      errorMessage: telEmergenciaErrorMessage,
    } = useField("tel_emergencia", validateEmpty);

    const { value: emailValue, errorMessage: emailErrorMessage } = useField(
      "email",
      validateEmpty
    );

    const { value: cpfValue, errorMessage: cpfErrorMessage } = useField(
      "cpf",
      validateEmptyAndLength3
    );

    const { value: rgValue, errorMessage: rgErrorMessage } = useField(
      "rg",
      validateEmptyAndLength3
    );

    const { value: paisValue, errorMessage: paisErrorMessage } = useField(
      "pais",
      validateEmptyAndLength3
    );

    const { value: cepValue, errorMessage: cepErrorMessage } = useField(
      "cep",
      validateEmptyAndLength3
    );

    const { value: enderecoValue, errorMessage: enderecoErrorMessage } =
      useField("endereco", validateEmptyAndLength3);

    const { value: numeroValue, errorMessage: numeroErrorMessage } = useField(
      "numero",
      validateEmptyAndLength3
    );

    const { value: bairroValue, errorMessage: bairroErrorMessage } = useField(
      "bairro",
      validateEmptyAndLength3
    );

    const { value: complementoValue, errorMessage: complementoErrorMessage } =
      useField("complemento", validateEmptyAndLength3);

    const { value: observacaoValue, errorMessage: observacaoErrorMessage } =
      useField("observacao", validateEmptyAndLength3);

    const { value: loginValue, errorMessage: loginErrorMessage } = useField(
      "login",
      validateEmptyAndLength3
    );
    const { value: senhaValue, errorMessage: senhaErrorMessage } = useField(
      "senha",
      validateEmptyAndLength3
    );
    const { value: crefValue, errorMessage: crefErrorMessage } = useField(
      "cref",
      validateEmptyAndLength3
    );

    const state = reactive({
      paises: [],

      hasErrors: false,
      isLoading: false,
      tipo_login: "normal",
      avaliador: "nao",
      idioma: "PT",
      id: {
        value: idValue,
        errorMessage: idErrorMessage,
      },
      status: {
        value: statusValue,
        errorMessage: statusErrorMessage,
      },
      sempre_liberado: {
        value: sempreLiberadoValue,
        errorMessage: sempreLiberadoErrorMessage,
      },
      nome: {
        value: nomeValue,
        errorMessage: nomeErrorMessage,
      },
      data_nascimento: {
        value: dataNascimentoValue,
        errorMessage: dataNascimentoErrorMessage,
      },
      sexo: {
        value: sexoValue,
        errorMessage: sexoErrorMessage,
      },
      tipo_sangue: {
        value: tipoSangueValue,
        errorMessage: tipoSangueErrorMessage,
      },
      telefone: {
        value: telefoneValue,
        errorMessage: telefoneErrorMessage,
      },
      celular: {
        value: celularValue,
        errorMessage: celularErrorMessage,
      },
      tel_emergencia: {
        value: telEmergenciaValue,
        errorMessage: telEmergenciaErrorMessage,
      },
      email: {
        value: emailValue,
        errorMessage: emailErrorMessage,
      },
      cpf: {
        value: cpfValue,
        errorMessage: cpfErrorMessage,
      },
      rg: {
        value: rgValue,
        errorMessage: rgErrorMessage,
      },
      pais: {
        value: paisValue,
        errorMessage: paisErrorMessage,
      },
      estado: 0,
      cidade: 0,
      cep: {
        value: cepValue,
        errorMessage: cepErrorMessage,
      },
      endereco: {
        value: enderecoValue,
        errorMessage: enderecoErrorMessage,
      },
      numero: {
        value: numeroValue,
        errorMessage: numeroErrorMessage,
      },
      bairro: {
        value: bairroValue,
        errorMessage: bairroErrorMessage,
      },
      complemento: {
        value: complementoValue,
        errorMessage: complementoErrorMessage,
      },
      observacao: {
        value: observacaoValue,
        errorMessage: observacaoErrorMessage,
      },

      login: {
        value: loginValue,
        errorMessage: loginErrorMessage,
      },
      senha: {
        value: senhaValue,
        errorMessage: senhaErrorMessage,
      },
      cref: {
        value: crefValue,
        errorMessage: crefErrorMessage,
      },
    });
    async function fetchColaborador() {
      //  try {
      state.isLoading = true;
      const { data } = await services.colaboradores.obterPorId({
        type: state.currentClientType,
        token: token,
        empresa_id: empresa_id,
        id: router.currentRoute._value.params.id,
      });
      fetchCidades(data.estado_id);
      state.id.value = data.id;
      state.rg.value = data.rg;
      state.cpf.value = data.cpf;
      state.cidade = data.cidade_id;
      state.nome.value = data.nome;
      state.telefone.value = data.telefone;
      state.celular.value = data.celular;
      state.tel_emergencia.value = data.tel_emergencia;
      state.data_nascimento.value = data.data_nascimento;
      state.status.value = data.status;
      state.email.value = data.email;
      state.estado = data.estado_id;
      state.endereco.value = data.endereco;
      state.numero.value = data.numero;
      state.bairro.value = data.bairro;
      state.observacao.value = data.observacao;
      state.complemento.value = data.complemento;
      state.cep.value = data.cep;
      state.pais.value = data.pais;
      state.sexo.value = data.sexo;
      state.tipo_sangue.value = data.tipo_sangue;
      state.tipo_login = data.tipo_login;
      state.cref.value = data.cref;
      state.login.value = data.login;
      state.avaliador = data.avaliador;
      state.id_leitor = data.id_leitor;

      return;
      /*
       */
      //  console.log(data)
      //   state.pagination = data.pagination
      //   } catch (error) {
      //    console.log(error)
      //  }
    }

    onMounted(() => {
      console.log();
      if (router.currentRoute._value.params.id != undefined) {
        fetchColaborador();
      } else {
        state.estado = estado_id;
        fetchCidades(estado_id);
        state.cidade = cidade_id;
        state.pais.value = pais_id;
      }
      fetchPaises();
      fetchEstados();
    });

    async function fetchPaises() {
      try {
        state.isLoading = true;
        const { data } = await services.paises.getAll({
          type: state.currentFeedbackType,
        });
        console.log(data);
        //  console.log(data)
        state.paises = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        console.log(error);
        //  handleErrors(error);
      }
    }

    async function fetchEstados() {
      try {
        state.isLoading = true;
        const { data } = await services.estados.getAll({
          type: state.currentFeedbackType,
        });
        console.log(data);
        //  console.log(data)
        state.estados = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        console.log(error);
        //  handleErrors(error);
      }
    }
    async function fetchCidades(estado_id) {
      try {
        state.isLoading = true;
        const { data } = await services.cidades.getAll(estado_id);
        console.log(data);
        state.cidades = data;
        state.isLoading = false;
      } catch (error) {
        console.log(error);
        //    handleErrors(error);
      }
    }

    async function handleSubmit() {
      try {
        state.isLoading = true;

        if (!state.id.value) {
          var resposta = await services.colaboradores.save({
            token: token,
            status: state.status.value,
            empresa_id: empresa_id,
            nome: state.nome.value,
            data_nascimento: state.data_nascimento.value,
            sexo: state.sexo.value,
            tipo_sangue: state.tipo_sangue.value,
            telefone: state.telefone.value,
            celular: state.celular.value,
            tel_emergencia: state.tel_emergencia.value,
            email: state.email.value,
            cpf: state.cpf.value,
            rg: state.rg.value,
            pais: state.pais.value,
            estado: state.estado,
            cidade_id: state.cidade,
            cep: state.cep.value,
            endereco: state.endereco.value,
            numero: state.numero.value,
            bairro: state.bairro.value,
            complemento: state.complemento.value,
            observacao: state.observacao.value,
            cref: state.observacao.value,
            login: state.login.value,
            senha: state.senha.value,
            tipo_login: state.tipo_login,
            avaliador: state.avaliador,
          });

          if (!resposta.verifica_erro) {
            router.push({ name: "colaboradores" });
            state.isLoading = false;
            return;
          } else {
            alert("Erro ao cadastrar Colaborador");
          }
        } else {
          const { errors } = await services.colaboradores.update({
            id: state.id.value,
            status: state.status.value,
            empresa_id: empresa_id,
            nome: state.nome.value,
            data_nascimento: state.data_nascimento.value,
            sexo: state.sexo.value,
            tipo_sangue: state.tipo_sangue.value,
            telefone: state.telefone.value,
            celular: state.celular.value,
            tel_emergencia: state.tel_emergencia.value,
            email: state.email.value,
            cpf: state.cpf.value,
            rg: state.rg.value,
            pais: state.pais.value,
            estado: state.estado,
            cidade_id: state.cidade,
            cep: state.cep.value,
            endereco: state.endereco.value,
            numero: state.numero.value,
            bairro: state.bairro.value,
            complemento: state.complemento.value,
            observacao: state.observacao.value,
            cref: state.observacao.value,
            login: state.login.value,
            senha: state.senha.value,
            tipo_login: state.tipo_login,
            avaliador: state.avaliador,
          });
          console.log(errors);
          if (!errors) {
            //       window.localStorage.setItem('token', data.token)
            router.push({ name: "colaboradores" });
            state.isLoading = false;
            return;
          }

          if (errors.status === 400) {
            //  toast.error('Ocorreu um erro ao criar a conta')
          }
        }

        state.isLoading = false;
      } catch (error) {
        console.log(error);
        state.isLoading = false;
        state.hasErrors = !!error;
        //  toast.error('Ocorreu um erro ao criar a conta')
      }
    }
    return {
      state,
      // handleCategory,
      handleSubmit,
      fetchCidades,
      emit,
      cancel,
    };
  },
};
</script>
  <style scoped>
.form-group {
  margin-bottom: 0.5rem;
}
h2 {
  color: #252525;
  font-weight: 600;
}
textarea {
  resize: none;
}
.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}
.btn_salvar:hover {
  transform: none;
  color: #fff;
}
.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}
.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}
input {
  border-color: #b8b8b8;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
}
input:focus {
  border-color: #252525;
}
select {
  border-color: #b8b8b8;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
}
select:focus {
  border-color: #252525;
}
textarea {
  resize: none;
}
h2 {
  margin-top: 35px;
}
.area_obs {
  width: 100%;
  border-width: 1px;
  color: #505050;
  border-radius: 15px;
  transition: 0.5s;
  outline: none;
  height: 100px;
  border: 1px solid #b8b8b8;
  resize: none;
}
.area_obs:focus {
  border-color: #252525;
  outline: none;
}
.checks {
  color: #000;
  border-radius: 10px;
  outline: none;
  border: 1px solid #b8b8b8;
  resize: none;
  text-align: center;
  padding-bottom: 5px;
  display: inline-block;
  padding: padding-righ;
  padding-right: 10px;
}
.chekcbox {
  margin-left: 10px;
}
.lbl_check {
  margin-left: 5px;
}
.title_check {
  margin-top: -14px;
  float: left;
  margin-left: 15px;
}
.span_login {
  background-color: #fff;
}
.div_bottom {
  text-align: right;
  margin-top: 10px;
}
</style>