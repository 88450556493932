<template>
  <topo :titulo="'Fornecedores'"></topo>
  <div>
    <!--
    <base-header type="gradient-success" class="pt-5 pt-md-8">
      
      <div class="row">
        <div class="col-xl-3 col-lg-6" v-if="cadastroForn">
          <card class="card-stats">
            <div class="quad">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-person-fill persona"
                viewBox="0 0 16 16"
              >
                <path
                  d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                />
              </svg>
            </div>
            <p class="title1">Fornecedores Cadastrados</p>
            <div class="num">{{}}</div>
            <div class="conteudo">
            </div>
          </card>
        </div>
        <div class="col-xl-3 col-lg-6" v-if="ativoForn">
          <card class="card-stats">
            <div class="quad">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-person-fill-check persona"
                viewBox="0 0 16 16"
              >
                <path
                  d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514ZM11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
                <path
                  d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z"
                />
              </svg>
            </div>
            <p class="title2">Fornecedores Ativos</p>
            <div class="num">{{}}</div>
            <div class="conteudo">
                <span class="arow"><i class="fa fa-arrow-up"></i> 12.18%</span> 
            </div>
          </card>
        </div>
      </div>
    </base-header>
    -->
    <div class="container-fluid mt-5">
      <div
        class="card shadow p-3"
        :class="type === 'dark' ? 'bg-default' : ''"
        s
      >
        <div class="row">
          <div class="col-2">
            <input
              type="text"
              class="form-control"
              placeholder="Código"
              v-model="state.codigo"
            />
          </div>
          <div class="col-5">
            <input
              type="text"
              class="form-control"
              v-model="state.nome_busca"
              placeholder="Nome"
            />
          </div>
          <div class="col-2 div_btn_buscar">
            <base-button
              type="button"
              class="btn_busca"
              @click="buscarPorNome()"
              >Buscar</base-button
            >
          </div>
          <div class="col-3 div_add_fornecedor">
            <router-link to="/adicionar-fornecedor" class="primary">
              <button
                type="button"
                id="btn_new_product"
                class="btn_add_fornecedor"
              >
                Adicionar Forncedor
              </button>
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="table-responsive">
              <table
                class="table tablesorter table align-items-center table-flush"
              >
                <thead class="thead-light">
                  <th>Código</th>
                  <th>Nome</th>
                  <th></th>
                  <th>Status</th>
                  <th></th>
                </thead>
                <tbody class="list">
                  <CorpoTabelaFonecedor
                    v-for="fornecedor in state.fornecedores"
                    :key="fornecedor.id"
                    :fornecedor="fornecedor"
                    @deletarFornecedor="deletarFornecedor"
                    @abrirModal="abrirModal"
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
          <nav aria-label="">
            <ul class="pagination">
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Previous</span>
                </a>
              </li>

              <li
                class="page-item"
                v-for="pagina in state.qtd_paginacao"
                :key="pagina"
                :class="{ active: pagina == state.pagina_selecionada }"
              >
                <a class="page-link" @click="pagina">{{ pagina }}</a>
              </li>

              <li class="page-item">
                <a class="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Next</span>
                </a>
              </li>
              <li>
                <select class="form-control" v-model="state.qtd_por_pagina">
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <Modal :open="state.isOpen" @close="state.isOpen = !state.isOpen">
    <h1>Visualizar Fornecedor</h1>
    <h2>Dados Principais</h2>
    <button
      type="primary"
      id="btn_new_product"
      class="btn_edit"
      style="float: right"
      @click="editarModo"
      v-if="state.modo == 'visualizar'"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-pencil-square"
        viewBox="0 0 16 16"
      >
        <path
          d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
        />
        <path
          fill-rule="evenodd"
          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
        />
      </svg>
    </button>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label>Código</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_id"
              readonly
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_id }}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Nome</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_nome"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_nome }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <label>Status</label>
        <div v-if="state.modo == 'editar'">
          <div class="form-group">
            <select v-model="state.visualizar_status" class="form-control">
              <option value="ativo">Ativo</option>
              <option value="inativo">Inativo</option>
            </select>
          </div>
        </div>
        <div v-if="state.modo == 'visualizar'">
          <p>{{ state.visualizar_status }}</p>
        </div>
      </div>
      <div class="col-lg-4" v-if="state.tipo_empresa == 'fisica'">
        <div class="form-group">
          <label>Data de Nascimento</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_dataNascimento"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_dataNascimento }}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4" v-if="state.tipo_empresa == 'fisica'">
        <div class="form-group">
          <label>Sexo</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_sexo"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_sexo }}</p>
          </div>
        </div>
      </div>
    </div>
    <h2>Documentos</h2>
    <div class="row">
      <div class="col-md-4" v-if="state.tipo_empresa == 'juridica'">
        <div class="form-group">
          <label>CNPJ</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_cnpj"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_cnpj }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-4" v-if="state.tipo_empresa == 'fisica'">
        <div class="form-group">
          <label>RG</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_rg"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_rg }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-4" v-if="state.tipo_empresa == 'fisica'">
        <div class="form-group">
          <label>CPF</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_cpf"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_cpf }}</p>
          </div>
        </div>
      </div>
    </div>
    <h2>Dados para Contato</h2>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label>Telefone Principal</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_telefonePrincipal"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_telefonePrincipal }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>Telefone Alternativo</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_telefoneAlternativo"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_telefoneAlternativo }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>Celular/WhatsApp</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_zap"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_zap }}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="form-group">
          <label>E-mail</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_email"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_email }}</p>
          </div>
        </div>
      </div>
    </div>
    <h2>Endereço</h2>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label>País</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_pais"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_pais }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>Estado</label>
          <div v-if="state.modo == 'editar'">
            <select
              class="form-control"
              v-model="state.visualizar_estado_id"
              @change="fetchCidades($event.target.value)"
            >
              <select-estados
                v-for="estado in state.estados"
                :key="estado.id"
                :estado="estado"
              />
            </select>
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_estado }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>Cidade</label>
          <div v-if="state.modo == 'editar'">
            <select class="form-control" v-model="state.visualizar_cidade_id">
              <option
                v-for="cidade in state.cidades"
                :key="cidade.id"
                :value="cidade.id"
              >
                {{ cidade.nome }}
              </option>
            </select>
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_cidade }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>Endereço</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_endereco"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_endereco }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>Número</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_num"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_num }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>Bairro</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_bairro"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_bairro }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>Complemento</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_complemento"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_complemento }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>CEP</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_cep"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_cep }}</p>
          </div>
        </div>
      </div>
    </div>
    <h2>Obeservação</h2>
    <div class="row">
      <div class="col-md-12">
        <div v-if="state.modo == 'editar'">
          <textarea class="area_obs" v-model="state.vizualizar_obs"></textarea>
        </div>
        <div v-if="state.modo == 'visualizar'">
          <p>{{ state.vizualizar_obs }}</p>
        </div>
      </div>
    </div>
    <button
      class="btn btn_salvar"
      id="submit-button"
      type="button"
      @click="atualizarForncedor"
      v-if="state.modo == 'editar'"
    >
      Salvar
    </button>
    <button
      class="btn btn_cancelar my-4"
      id="submit-button"
      type="button"
      @click="fecharModal"
      v-if="state.modo == 'visualizar'"
    >
      Fechar
    </button>
  </Modal>
</template>
<script>
import CorpoTabelaFonecedor from "../components/CorpoTabelaFonecedor";
import { onMounted, reactive } from "vue";
import services from "../services";
import { useStorage } from "vue3-storage";
import Modal from "../components/Modal/Modal.vue";
import SelectEstados from "../components/EstadosSelect.vue";
import topo from "../components/topo";
export default {
  components: {
    topo,
  },
  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");

    const state = reactive({
      //card-stats
      cadastroForn: false,
      ativoForn: false,

      modo: "visualizar",
      estado: 0,
      isLoading: false,
      contas: [],
      codigo: "",
      currentClientType: "",
      hasError: false,
    });
    onMounted(() => {
      buscarTodos();
      fetchEstados();
    });

    function editarModo() {
      if (state.modo == "visualizar") {
        state.modo = "editar";
      } else {
        state.modo = "visualizar";
      }
    }
    async function buscarTodos() {
      try {
        state.isLoading = true;
        const { data } = await services.fornecedores.getAll({
          token: token,
          empresa_id: empresa_id,
        });
        console.log(data);
        //  console.log(data)
        state.fornecedores = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    }

    async function deletarFornecedor(id) {
      if (confirm("Deseja deletar este fornecedor?")) {
        try {
          state.isLoading = true;
          const { data } = await services.fornecedores.deletarFornecedor({
            token: token,
            empresa_id: empresa_id,
            id,
          });
          if (data.verifica_erro == false) {
            buscarTodos();
          } else {
            alert(
              "Erro ao deletar fornecedor. Verifique se existem contas atreladas a este fornecedor!"
            );
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
    async function fetchCidades(estado_id) {
      try {
        state.isLoading = true;
        const { data } = await services.cidades.getAll(estado_id);
        console.log(data);
        state.cidades = data;
        state.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    }
    async function abrirModal(id) {
      console.log("aqui");
      try {
        state.isOpen = true;
        const { data } = await services.fornecedores.obterPorId({
          token: token,
          empresa_id: empresa_id,
          id: id,
        });
        state.visualizar_id = data.id;
        state.visualizar_nome = data.nome;
        (state.visualizar_status = data.status),
          (state.visualizar_dataNascimento = data.data_nascimento);
        state.visualizar_sexo = data.sexo;
        state.visualizar_cnpj = data.cnpj;
        state.visualizar_rg = data.rg;
        state.visualizar_cpf = data.cpf;
        state.visualizar_zap = data.telefone;
        state.visualizar_email = data.email;
        state.visualizar_cidade = data.nome_cidade;
        state.visualizar_estado = data.nome_estado;
        state.visualizar_estado_id = data.estado_id;
        fetchCidades(data.estado_id);
        state.visualizar_cidade_id = data.cidade_id;
        state.visualizar_endereco = data.endereco;
        state.visualizar_num = data.numero;
        state.visualizar_bairro = data.bairro;
        state.visualizar_complemento = data.complemento;
        state.visualizar_cep = data.cpf;
        state.visualizar_obs = data.observacao;
        state.tipo_empresa = data.tipo_pessoa;
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    }
    function fecharModal() {
      state.modo = "visualizar";
      state.isOpen = false;
    }
    async function atualizarForncedor() {
      console.log(state.nome);
      try {
        const { data } = await services.fornecedores.alterarModal({
          token: token,
          empresa_id: empresa_id,
          id: state.visualizar_id,
          nome: state.visualizar_nome,
          cpf: state.visualizar_cpf,
          data_nascimento: state.visualizar_dataNascimento,
          sexo: state.visualizar_sexo,
          endereco: state.visualizar_endereco,
          numero: state.visualizar_num,
          bairro: state.visualizar_bairro,
          complemento: state.visualizar_complemento,
          cep: state.visualizar_cep,
          estado: state.visualizar_estado,
          cidade: state.visualizar_cidade,
          telefone: state.visualizar_telefone,
          celular: state.visualizar_zap,
          tel_emergencia: state.visualizar_tel_emergencia,
          email: state.visualizar_email,
          status: state.visualizar_status,
          tipo_pessoa: state.visualizar_tipo_pessoa,
          rg: state.visualizar_rg,
          sempre_aberto: state.visualizar_sempre_aberto,
          cnpj: state.visualizar_cnpj,
          nome_cidade: state.visualizar_nome_cidade,
          nome_estado: state.visualizar_nome_estado,
          observacao: state.visualizar_obs,
        });
        console.log(data);
        //state.isOpen = false;
        state.modo = "visualizar";
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchEstados() {
      try {
        state.isLoading = true;
        const { data } = await services.estados.getAll({
          type: state.currentFeedbackType,
        });
        console.log(data);
        //  console.log(data)
        state.estados = data;
        //   state.pagination = data.pagination

        state.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    }

    async function buscarPorNome() {
      if (state.codigo.length > 0) {
        try {
          const { data } = await services.fornecedores.obterPorId({
            token: token,
            empresa_id: empresa_id,
            id: state.codigo,
          });
          var array = [];
          array.push(data);
          state.fornecedores = array;
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          state.isLoading = true;
          const { data } = await services.fornecedores.fornecedoresPorNome({
            type: state.currentClientType,
            token: token,
            empresa_id: empresa_id,
            nome: state.nome_busca,
          });
          console.log(data);
          //  console.log(data)
          state.fornecedores = data;
          state.isLoading = false;
        } catch (error) {
          console.log(error);
        }
      }
    }
    return {
      state,
      CorpoTabelaFonecedor,
      deletarFornecedor,
      Modal,
      abrirModal,
      fecharModal,
      buscarPorNome,
      editarModo,
      atualizarForncedor,
      SelectEstados,
      fetchCidades,
    };
  },
};
</script>
<style scoped>
.persona {
  color: #aede00;
  width: 24px;
  height: 24px;
}
.quad {
  padding: 13px;
  gap: 10px;
  width: 50px;
  height: 50px;
  background: #010d1f;
  border-radius: 12px;
}
.title2 {
  color: #000;
  margin-top: 10px;
}
.title1 {
  color: #000;
  margin-top: 10px;
  font-size: 11pt;
  line-height: 1;
}
.arow {
  margin-right: 5px;
  font-size: 0.7rem !important;
  color: #034aa6;
  font-weight: 700;
}
.text1 {
  color: #034aa6;
  font-size: 7pt !important;
  font-weight: bold;
}
.num {
  margin-top: -15px;
}
.card .table th {
  padding: 15px !important;
}
.btn_busca {
  background-color: #aede00 !important;
  border-color: #aede00 solid 1px !important;
  color: rgb(0, 0, 0) !important;
  border-radius: 15px !important;
  border-color: #aede00 !important;
}
.btn_busca:hover {
  transform: none;
}
.div_btn_buscar {
  margin-left: -20px;
}
.btn_add_fornecedor:hover {
  transform: none;
}
.div_add_fornecedor {
  text-align: right;
  margin-left: 20px;
}
input {
  border-color: #b8b8b8;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
}
input:focus {
  border-color: #252525;
}
.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}
.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}
.area_obs {
  width: 100%;
  border-width: 2px;
  color: #505050;
  border-radius: 15px;
  transition: 0.5s;
  outline: none;
  height: 100px;
  border: 2px solid #b8b8b8;
  resize: none;
}
.area_obs:focus {
  border-color: #252525;
  outline: none;
}
.space_btn {
  text-align: right;
}
.btn_edit {
  cursor: pointer;
  border: none;
  background: transparent;
}
.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}
.btn_salvar:hover {
  transform: none;
  color: #fff;
}
label {
  color: #000;
  font-weight: 700;
}
select {
  border-color: #b8b8b8;
  border-width: 2px;
  color: #505050;
  border-radius: 15px;
  transition: 0.5s;
}
select:focus {
  border-color: #252525;
}
.btn_add_fornecedor {
  background-color: rgb(3, 74, 166);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(3, 74, 166);
  border-radius: 10px;
  font-size: 10pt;
  padding: 12px;
  font-weight: 700;
  width: 70%;
  margin-bottom: 15px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .btn_add_fornecedor {
    font-size: 9pt;
    width: 100%;
  }
}
</style>
