<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      
    </base-header>

        <div class="container-fluid mt--7">
      <div class="card shadow p-3" :class="type === 'dark' ? 'bg-default' : ''" s>
        <div class="row align-items-center">
          <div class="col">
            <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
              Categorias de Conta a Pagar
            </h3>
          </div>
          <div class="col text-right mb-2">
            <base-button type="primary" id="btn_new_product" @click="abrirModal">Adicionar Categoria</base-button>
          </div>
        </div>
        
          <div class="row">
            <div class="col">
              <div class="table-responsive">
                <table
                  class="table tablesorter table align-items-center table-flush"
                >
                  <thead class="thead-light">
                    <th>Código</th>
                    <th>Nome</th>
                    <th></th>
                  </thead>
                  <tbody class="list">
                    <corpo-grupo
                      v-for="grupo in state.grupos"
                      :key="grupo.id"
                      :grupo="grupo"
                      @deletar="deletar"
                      @modalVisualizar="modalVisualizar"
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
    <Modal
      :open="state.status_modal"
      @close="state.status_modal = !state.status_modal"
    >
      <h1>Categorias de Conta a Pagar</h1>
      <div class="row">
        <div class="col-md-2">
          <div class="form-group">
            <label>Código</label>
            <input
              type="text"
              class="form-control input_modal"
              v-model="state.id"
              readonly
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label>Nome</label>
            <input
              type="text"
              class="form-control input_modal"
              v-model="state.nome"
            />
          </div>
        </div>
      </div>
      <button
        class="btn btn_salvar my-4"
        id="submit-button"
        type="submit"
        @click="salvar()"
      >
        Salvar
      </button>
      <button
        class="btn btn_cancelar my-4"
        id="submit-button"
        type="button"
        @click="fecharModal()"
      >
        Cancelar
      </button>
    </Modal>
</template>
<script>
  import { onMounted, reactive, onErrorCaptured  } from 'vue'
  import CorpoGrupo from '../components/CorpoTabelaGrupo'
  import services from '../services'
  import Modal from "../components/Modal/Modal.vue";

  import { useStorage } from "vue3-storage";
export default {
  name: "tables",
    setup () {
    const storage = useStorage();
    var token =  storage.getStorageSync('token')
    var empresa_id = storage.getStorageSync('empresa_id')
    const state = reactive({
      isLoading: false,
      grupos: [],
      currentClientType: '',
      hasError: false,
      id:"",
      nome: "",
      mostrar_home: "",
      tipo_caixa: "interno",
      descricao:""
    })
    onErrorCaptured(handleErrors)

    onMounted(() => {
      fetchCategorias()
    })
    function handleErrors (error) {
      console.log(error)
      state.hasError = !!error
    }
    async function abrirModal() {
      state.status_modal = true;
    }

      function fecharModal() {
        state.id = "";
        state.nome = "";
        state.status_modal = false;
    }
      
    async function modalVisualizar(id) {
      try {
        state.isLoading = true;
        let data = await services.catContaPagar.getById({
          empresa_id,
          id,
          token,
        });
        state.status_modal = true;
        let dados = data.data;
        state.id = dados.id;
        state.nome = dados.nome;
        state.mostrar_home = dados.mostrar_home;
        state.descricao = dados.descricao;
      } catch (error) {
        console.log(error);
      }
    }
    async function deletar(id) {
      if (confirm("Deseja deletar o Grupo?")) {
        await services.catContaPagar.deletar({
          token,
          empresa_id,
          id
        }).then((data) => {
          if (data.data.verifica_erro) {
            alert("Erro ao deletar Grupo")
          } else {
            fetchCategorias();
          }
        })
          .catch(() => {
            alert("Erro ao deletar Grupo")
          });
      }
    }

      
    async function salvar() {
      if (state.id != null && state.id != undefined && state.id != "") {
        let retorno = await services.catContaPagar.update({
          empresa_id,
          token,
          id: state.id,
          nome: state.nome
        });

        if (retorno.data.verifica_erro == false) {
          fetchCategorias();
          state.status_modal = false;
        } else {
          alert("Erro ao salvar categoria");
        }
      } else {
        let retorno = await services.catContaPagar.insert({
          empresa_id,
          token,
          nome: state.nome
        });

        if (retorno.data.verifica_erro == false) {
          fetchCategorias();
          state.status_modal = false;
        } else {
          alert("Erro ao salvar categoria");
        }
      }
    }


    async function fetchCategorias () {
      try {
        state.isLoading = true
        const { data } = await services.catContaPagar.getAll({
          token: token,
          empresa_id: empresa_id
        }
        )
      //  console.log(data)
        state.grupos = data
        state.isLoading = false
        
      } catch (error) {
        handleErrors(error)
      }
    }
    return {
      state,
      CorpoGrupo,
      abrirModal,
      fecharModal,
      salvar,
      modalVisualizar,
      Modal,
      deletar
    }
  }
}

</script>

<style scoped>
.grupo{
  width: 100%;
  height: 100px;
  border: #034AA6 solid 1px;
  border-radius: 5px;
  margin-bottom: 10px;
  padding:15px;
}
.nome_grupo{
  font-weight:bold;
}
.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}
.btn_salvar:hover {
  transform: none;
  color: #fff;
}
.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}
.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}
textarea{
  resize: none;
}
</style>