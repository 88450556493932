<template>
  <nav class="navbar navbar-expand-lg navbar-light d-block d-md-none nav_cel">
    <router-link to="/dashboard">
      <img
        :src="logo"
        alt="Página Principal"
        class="navbar-brand-img logo_cel"
      />
    </router-link>
    <button
      class="navbar-toggler"
      style="float: right"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="nav-link" href="/dashboard"
            >Home <span class="sr-only">(current)</span></a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/avaliacoes">Avaliações</a>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            role="button"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            Cadastros
          </a>
          <div class="dropdown-menu">
            <a class="dropdown-item" href="/clientes">Clientes</a>
            <a class="dropdown-item" href="/colaboradores">Colaboradores</a>
            <!--  <div class="dropdown-divider"></div>-->
            <a class="dropdown-item" href="#">Fornecedores</a>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            role="button"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            Financeiro
          </a>
          <div class="dropdown-menu">
            <a class="dropdown-item" href="/contas-receber">Contas a Receber</a>
            <a class="dropdown-item" href="/contas-pagar">Contas a Pagar</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="/caixas">Caixa</a>

            <a class="dropdown-item" href="/recorrencias">Recorrências</a>
            <a class="dropdown-item" href="/banco-recorrente"
              >Banco Recorrente</a
            >
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/matriculas">Matrículas</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/aplicativo">Aplicativo</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/fichas">Fichas</a>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            role="button"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            Mais
          </a>
          <div class="dropdown-menu">
            <a class="dropdown-item" href="/fichas-treino-modelo"
              >Modelos de Fichas</a
            >

            <a
              class="dropdown-item"
              href="/grupos-musculares"
              v-if="state.permissao_grupos_musculares"
              >Grupos Musculares
            </a>
            <span class="dropdown-item" href="/grupos-musculares" v-else>
              Grupos Musculares
            </span>

            <a class="dropdown-item" href="/exercicios">Exercícios</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="/grupos">Grupos</a>
            <a class="dropdown-item" href="/planos">Planos</a>
            <a class="dropdown-item" href="/tipos-pagamento"
              >Tipos de Pagamento</a
            >
            <a class="dropdown-item" href="/despesas-fixas">Despesas Físicas</a>

            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="/configuracoes">Configuração</a>
            <a class="dropdown-item" href="/recorrencias">Sair</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>

  <nav
    class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white d-none d-md-block"
    id="sidenav-main"
  >
    <div class="container-fluid">
      <!--Toggler-->
      <navbar-toggle-button @click="showSidebar">
        <span class="navbar-toggler-icon"></span>
      </navbar-toggle-button>
      <router-link
        class="navbar-brand"
        to="/dashboard"
        style="width: 100% !important"
      >
        <img :src="logo" class="navbar-brand-img" alt="Página Principal" />
      </router-link>

      <slot name="mobile-right">
        <ul class="nav align-items-center d-md-none">
          <base-dropdown class="nav-item" position="right">
            <template v-slot:title>
              <a
                class="nav-link nav-link-icon"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="ni ni-bell-55"></i>
              </a>
            </template>

            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">Something else here</a>
          </base-dropdown>
          <base-dropdown class="nav-item" position="right">
            <template v-slot:title>
              <a class="nav-link" href="#" role="button">
                <div class="media align-items-center">
                  <span class="avatar avatar-sm rounded-circle">
                    <img
                      alt="Image placeholder"
                      src="img/theme/team-1-800x800.jpg"
                    />
                  </span>
                </div>
              </a>
            </template>

            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome!</h6>
            </div>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-single-02"></i>
              <span>My profile</span>
            </router-link>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-settings-gear-65"></i>
              <span>Settings</span>
            </router-link>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-calendar-grid-58"></i>
              <span>Activity</span>
            </router-link>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-support-16"></i>
              <span>Support</span>
            </router-link>
            <div class="dropdown-divider"></div>
            <a href="#!" class="dropdown-item">
              <i class="ni ni-user-run"></i>
              <span>Logout</span>
            </a>
          </base-dropdown>
        </ul>
      </slot>
      <slot></slot>
      <div
        v-show="$sidebar.showSidebar"
        class="navbar-collapse collapse show"
        id="sidenav-collapse-main"
      >
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img :src="logo" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <navbar-toggle-button
                @click="closeSidebar"
              ></navbar-toggle-button>
            </div>
          </div>
        </div>

        <ul class="navbar-nav">
          <slot name="links"> </slot>
        </ul>
        <!--Divider-->
        <hr class="my-3" />
        <!--Heading-->
        <!--Navigation-->

        <!--<ul class="navbar-nav mb-md-3">
          <li class="nav-item">
            <a class="nav-link" href="/grupos">
              <i class="ni ni-ui-04"></i> Planos / Grupos
            </a>
          </li> -->
        <!--
          
        </ul>-->
      </div>
      <!--
      <div class="div_space">
        <a href="/fichas-treino-modelo" class="link_menu">
          <i class="ni ni-ui-04"></i>
          <div class="botao">Fichas Treino Modelo</div>
        </a>
      </div>-->
      <div class="div_space">
        <a
          href="/grupos-musculares"
          class="link_menu"
          v-if="state.permissao_grupos_musculares"
        >
          <i class="ni ni-ui-04"></i>
          <div class="botao">Grupos Musculares</div>
        </a>

        <span href="/grupos-musculares" class="link_menu disabled" v-else>
          <i class="ni ni-ui-04"></i>
          <div class="botao">Grupos Musculares</div>
        </span>
      </div>

      <div class="div_space">
        <a
          href="/exercicios"
          class="link_menu"
          v-if="state.permissao_exercicio"
        >
          <i class="ni ni-ui-04"></i>
          <div class="botao">Exercícios</div>
        </a>

        <span class="disabled link_menu" v-else>
          <i class="ni ni-ui-04"></i>
          <div class="botao">Exercícios</div>
        </span>
      </div>
      <div class="div_space">
        <a href="/objetivos-treino" class="link_menu">
          <i class="ni ni-ui-04"></i>
          <div class="botao">Objetivos Treino</div>
        </a>
      </div>
      <div class="div_space">
        <a href="/grupos" class="link_menu" v-if="state.permissao_grupo">
          <i class="ni ni-ui-04"></i>
          <div class="botao">Grupos</div>
        </a>
        <span class="disabled link_menu" v-else>
          <i class="ni ni-ui-04"></i>
          <div class="botao">Grupos</div>
        </span>
      </div>

      <div class="div_space">
        <a href="/modelos-contrato" class="link_menu">
          <i class="ni ni-ui-04"></i>
          <div class="botao">Modelos Contrato</div>
        </a>
      </div>

      <div class="div_space">
        <a href="/planos" class="link_menu" v-if="state.permissao_plano">
          <i class="ni ni-ui-04"></i>
          <div class="botao">Planos</div>
        </a>
        <span class="disabled link_menu" v-else>
          <i class="ni ni-ui-04"></i>
          <div class="botao">Planos</div>
        </span>
      </div>

      <div class="div_space">
        <a
          href="/tipos-pagamento"
          class="link_menu"
          v-if="state.permissao_tipo_pagamento"
        >
          <i class="ni ni-ui-04"></i>
          <div class="botao">Tipos de Pagamento</div>
        </a>
        <span class="link_menu disabled" v-else>
          <i class="ni ni-ui-04"></i>
          <div class="botao">Tipos de Pagamento</div>
        </span>
      </div>

      <div class="div_space">
        <a href="/funcoes" class="link_menu" v-if="state.permissao_funcoes">
          <i class="ni ni-ui-04"></i>
          <div class="botao">Funções</div>
        </a>
        <span class="link_menu disabled" v-else>
          <i class="ni ni-ui-04"></i>
          <div class="botao">Funções</div>
        </span>
      </div>

      <div class="div_space">
        <a
          href="/despesas-fixas"
          class="link_menu"
          v-if="state.permissao_despesas_fixas"
        >
          <i class="ni ni-ui-04"></i>
          <div class="botao">Despesas Fixas</div>
        </a>
        <span class="link_menu disabled" v-else>
          <i class="ni ni-ui-04"></i>
          <div class="botao">Despesas Fixas</div>
        </span>
      </div>

      <div class="div_space">
        <a href="/enquetes" style="display: flex">
          <i class="ni ni-ui-04" style="color: #a6a6a6"></i>
          <div style="color: #a6a6a6; margin-top: -3px; margin-left: 10px">
            Enquetes
          </div>
        </a>
      </div>

      <div class="line"></div>
      <div class="div_space">
        <a href="/configuracoes" style="display: flex">
          <i class="ni ni-settings-gear-65" style="color: #a6a6a6"></i>
          <div style="color: #a6a6a6; margin-top: -3px; margin-left: 10px">
            Configurações
          </div>
        </a>
      </div>
      <div style="margin-top: 10px">
        <a @click="logout()" style="display: flex; cursor: pointer">
          <img
            src="/img/icons/move_item.png"
            style="width: 18px; height: 18px"
          />
          <div style="color: #a6a6a6; margin-top: -3px; margin-left: 10px">
            Sair
          </div>
        </a>
      </div>
    </div>
  </nav>
</template>
<script>
import NavbarToggleButton from "@/components/NavbarToggleButton";
import { useStorage } from "vue3-storage";
import { useRouter } from "vue-router";
import { onMounted, reactive } from "vue";

const state = reactive({});

export default {
  name: "sidebar",
  components: {
    NavbarToggleButton,
  },
  setup() {
    const storage = useStorage();
    const router = useRouter();
    const permissoes = storage.getStorageSync("permissoes");
    function logout() {
      var conf = confirm("Deseja realmente sair?");
      if (conf) {
        storage.clearStorageSync();
        router.push({ name: "login" });
      }
    }

    onMounted(() => {
      const permissao_grupos_musculares = permissoes.filter(
        (permissao) => permissao.id == 18
      );

      if (permissao_grupos_musculares.length > 0) {
        state.permissao_grupos_musculares = true;
      } else {
        state.permissao_grupos_musculares = false;
      }

      const permissao_exercicio = permissoes.filter(
        (permissao) => permissao.id == 19
      );

      if (permissao_exercicio.length > 0) {
        state.permissao_exercicio = true;
      } else {
        state.permissao_exercicio = false;
      }

      const permissao_grupo = permissoes.filter(
        (permissao) => permissao.id == 13
      );

      if (permissao_grupo.length > 0) {
        state.permissao_grupo = true;
      } else {
        state.permissao_grupo = false;
      }

      const permissao_plano = permissoes.filter(
        (permissao) => permissao.id == 14
      );

      if (permissao_plano.length > 0) {
        state.permissao_plano = true;
      } else {
        state.permissao_plano = false;
      }

      const permissao_tipo_pagamento = permissoes.filter(
        (permissao) => permissao.id == 7
      );

      if (permissao_tipo_pagamento.length > 0) {
        state.permissao_tipo_pagamento = true;
      } else {
        state.permissao_tipo_pagamento = false;
      }

      const permissao_funcoes = permissoes.filter(
        (permissao) => permissao.id == 7
      );

      if (permissao_funcoes.length > 0) {
        state.permissao_funcoes = true;
      } else {
        state.permissao_funcoes = false;
      }

      const permissao_despesas_fixas = permissoes.filter(
        (permissao) => permissao.id == 69
      );

      if (permissao_despesas_fixas.length > 0) {
        state.permissao_despesas_fixas = true;
      } else {
        state.permissao_despesas_fixas = false;
      }
    });
    return {
      logout,
      state,
    };
  },
  props: {
    logo: {
      type: String,
      default: "/img/logo_sifit.png",
      description: "Sistema Sifit",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether sidebar should autoclose on mobile when clicking an item",
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    closeSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true);
    },
  },
  beforeUnmount() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style scoped>
.disabled {
  color: #c9c9c9 !important;
}
.link_menu {
  display: flex;
  color: #a6a6a6;
}

.link_menu i {
  display: flex;
}

.botao {
  margin-top: -3px;
  margin-left: 10px;
}

.nav_cel {
  background-color: #fff;
}
#sidenav-main {
  border-right: 1.5px solid var(--color-stroke, #e7e7e7);
  background: var(--color-white, #fff);
}
.div_space {
  margin-top: 15px;
}
.line {
  height: 1px;
  width: 100%;
  background: #e5e5e5;
  margin-top: 25px;
  margin-bottom: 15px;
}
.logo_cel {
  width: 180px;
  display: inline;
}
</style>