export default httpClient => ({
  porColaborador: async ({ token, empresa_id, colaborador_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/funcoes-colaborador/' + empresa_id, {
      token: token,
      colaborador_id,
    }, { headers });
    return { data: response.data }
  },
  getAll: async ({ token, empresa_id }) => {

    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/funcoes/' + empresa_id, { headers });
    return { data: response.data }
  },
  qtdFuncao: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }

    const response = await httpClient.post('//' + empresa_id, {
      token: token,
    }, { headers });
    return { data: response.data }
  },
  save: async ({ token, empresa_id, nome, tipo_funcionario, avaliador, personal }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/funcoes/criar/' + empresa_id, {
      token,
      nome,
      tipo_funcionario,
      avaliador,
      personal
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
});